import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import './input-multiselect.component.css';

const InputMultiSelect = ({ label, name, values, options, onChange, disabled = false }) => {
    const styleSelect = {
        width: '100%',
    };

    return (
        <div className='o-input-multiselect'>
            {label && <label className='o-input-multiselect-label'>{label}</label>}
            <MultiSelect
                name={name}
                id={name}
                value={values}
                options={options}
                onChange={(e) => onChange(e)}
                filter={true}
                style={styleSelect}
                disabled={disabled}
                display="chip"
            />
        </div>
    );
};

export default InputMultiSelect;
