import React, { Component } from 'react';
import i18next from 'i18next';
import Config from '../config';
import InputPassword from '../shared/components/form/input-password.component';
import InputSelect from '../shared/components/form/input-select.component';
import InputText from '../shared/components/form/input-text.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import StaticText from '../shared/components/form/static-text.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { connect } from 'react-redux';
import { fetchPut } from '../store/actions/user.action';

import './account-page.component.css';

class ConnectedAccountPage extends Component {
    constructor(props) {
        super(props);

        const locales = Config.locales.LIST.map(item => {
            return {
                value: item.value,
                label: i18next.t(item.label),
            };
        });

        this.state = {
            account: { ...this.props.store.user.account },
            locales,
            dirty: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleChange(event) {
        const account = this.state.account;
        const { name, value } = event.target;
        account[name] = value;
        this.setState({ account });
    }

    handleOnClick(event) {
        event.preventDefault();
        this.setState({ dirty: true });

        const { user } = this.props.store;
        const { account } = this.state;

        if (
            user.account.email !== account.email ||
            user.account.firstname !== account.firstname ||
            user.account.lastname !== account.lastname ||
            user.account.locale !== account.locale ||
            (account.password && account.password === account.confirmation)
        ) {
            this.props.fetchPut(account);
        }
    }

    render() {
        const { account, dirty } = this.state;
        const { store: { user } } = this.props;

        return (
            <Page title={i18next.t('app_page_account')} loading={user.loading}>
                <Panel title={i18next.t('app_general_information')} toggleable={false}>
                    <div className='p-grid'>
                        <div className='p-col-8'>
                            <div>
                                <StaticText
                                    name='email'
                                    label={i18next.t('app_email')}
                                    value={account.email}
                                />
                            </div>
                            <div className='p-grid'>
                                <div className='p-col'>
                                    <InputText
                                        name='lastname'
                                        label={i18next.t('app_lastname')}
                                        value={account.lastname}
                                        onChange={this.handleChange}
                                        onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                                    />
                                </div>
                                <div className='p-col'>
                                    <InputText
                                        name='firstname'
                                        label={i18next.t('app_firstname')}
                                        value={account.firstname}
                                        onChange={this.handleChange}
                                        onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                                    />
                                </div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-col'>
                                    <InputSelect
                                        name='locale'
                                        label={i18next.t('app_language')}
                                        options={this.state.locales}
                                        value={account.locale}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className='p-col'></div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-col'>
                                    <InputPassword
                                        name='password'
                                        label={i18next.t('app_password')}
                                        value={account.password}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className='p-col'></div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-col'>
                                    <InputPassword
                                        name='confirmation'
                                        label={i18next.t('app_password_confirmation')}
                                        value={account.confirmation}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className='p-col'></div>
                            </div>
                        </div>
                        <div className='p-col-4 o-account-page-picture'>
                            <img
                                alt='avatar'
                                src={
                                    account.picture
                                        ? `${account.picture}?h=150&w=150`
                                        : `//unavatar.now.sh/${account.email}?fallback=//ui-avatars.com/api/?name=${encodeURIComponent(account.firstname + ' ' + account.lastname)}`
                                }
                            />
                        </div>
                    </div>
                    <div className='p-grid'>
                        <div className='p-col-8'></div>
                        <div className='p-col-4'>
                            <SubmitButton
                                label={i18next.t('app_submit')}
                                onClick={this.handleOnClick}
                                disabled={user.loading}
                            />
                        </div>
                    </div>
                </Panel>
            </Page>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return { store: { user } };
};

const mapDispatchToProps = {
    fetchPut,
};

const AccountPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedAccountPage);

export default AccountPage;
