import { initialState, type } from '../actions/tag.action';

const tag = (state = initialState, action) => {
    switch (action.type) {
        case type.TAG_FETCH_PENDING:
            return { ...state, loading: action.loading };

        case type.TAG_FETCH_GET_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                countAll: action.countAll,
                tags: action.tags,
            };

        default:
            return state;
    }
};

export default tag;
