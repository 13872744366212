import React, { Component } from 'react';
import i18next from 'i18next';
import Dialog from '../shared/components/dialog.component';
import InputText from '../shared/components/form/input-text.component';
import Page from '../shared/components/page.component';
import Paginator from '../shared/components/paginator.component';
import { historyHelper as history } from '../core/helpers/history.helper';
import { connect } from 'react-redux';
import { initPage } from '../store/actions/app.action';
import { fetchGetArtists } from '../store/actions/artist.action';
import { fetchGetStakeholders, fetchPatchRemoveStakeholder, setFilter, setStakeholders } from '../store/actions/stakeholder.action';
import SubmitButton from '../shared/components/form/submit-button.component';
import Config from '../config';
import './stakeholders-page.component.css';
// import displayDate from '../shared/components/display-date';

class ConnectedStakeholdersPage extends Component {
    constructor(props) {
        super(props);
        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
        this.handleOnPageChange = this.handleOnPageChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRemoveOK = this.handleRemoveOK.bind(this);

        this.state = {
            artists: [],
            removeDialogVisible: false,
            removeStakeholder: null,
        };
    }

    async componentDidMount() {
        this.props.initPage('/stakeholders');

        const { filter } = this.props.store.stakeholder;
        this.props.fetchGetStakeholders(filter);
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.ADD:
                history.push('/stakeholders/new');
                break;

            case Config.action.MODIFY:
                history.push(`/stakeholders/modify/${data.id}`);
                break;

            default:
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { filter } = this.props.store.stakeholder;

        if (value !== 'all') {
            filter[name] = value;
        } else {
            delete filter[name];
        }

        this.props.setFilter(filter);
        this.props.fetchGetStakeholders(filter);
    }

    handleOnKeyPress(event) {
        if (event.key === 'Enter') {
            const { name, value } = event.target;
            if (value.length >= 3) {
                this.handleChange(event);
            } else {
                const { filter } = this.props.store.stakeholder;
                if (value) {
                    filter[name] = value;
                } else {
                    if (filter[name] && filter[name].length > 0) {
                        delete filter[name];
                        this.handleChange(event);
                    }
                }
                this.props.setFilter(filter);
            }
        }
    }

    handleOnPageChange(first, rows) {
        const { filter } = this.props.store.stakeholder;
        filter.limit = rows;
        filter.offset = first;
        this.props.setFilter(filter);
        this.props.fetchGetStakeholders(filter);
    }

    handleRemove(stakeholder) {
        this.setState({ removeDialogVisible: true, removeStakeholder: stakeholder });
    }

    handleRemoveOK() {
        const { removeStakeholder } = this.state;
        const { stakeholders } = this.props.store.stakeholder;
        const index = stakeholders.findIndex(e => e.id === removeStakeholder.id);
        stakeholders.splice(index, 1);
        this.props.setStakeholders(stakeholders);
        this.props.fetchPatchRemoveStakeholder(removeStakeholder.id);
        this.setState({ removeDialogVisible: false, removeStakeholder: null });
    }

    render() {
        const { removeDialogVisible, removeStakeholder } = this.state;

        const {
            store: { stakeholder: { stakeholders, countAll, filter, loading } },
        } = this.props;

        return (
            <Page title={i18next.t('app_page_stakeholders')} loading={loading}>
                <div className='p-grid'>
                    <div className='p-col-3'>
                        <InputText
                            name='find'
                            label={i18next.t('app_find')}
                            onKeyPress={this.handleOnKeyPress}
                            placeholder={i18next.t('app_find_min_char')}
                        />
                    </div>
                    <div className='p-col-9 o-new-button'>
                        <SubmitButton
                            label={i18next.t('app_stakeholder_new')}
                            onClick={() => history.push('/stakeholders/new')}
                        />
                    </div>
                </div>
                <div className='p-grid'>
                    <div className='o-table p-col-12'>
                        <table>
                            <thead>
                                <tr>
                                    <th>{i18next.t('app_stakeholder_name')}</th>
                                    <th>{i18next.t('app_stakeholder_type')}</th>
                                    <th>{i18next.t('app_stakeholder_default_share')}</th>
                                    {/* <th>{i18next.t('app_stakeholder_begins_at')}</th> */}
                                    {/* <th>{i18next.t('app_stakeholder_finish_at')}</th> */}
                                    <th>{i18next.t('app_actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stakeholders.length === 0 && <tr><td colSpan={6}>{i18next.t('app_stakeholder_no_stakeholder')}</td></tr>}
                                {stakeholders.map(stakeholder => {
                                    return (
                                        <tr key={stakeholder.id}>
                                            <td>{stakeholder.name}</td>
                                            <td>{stakeholder.type ? i18next.t(`app_stakeholder_type_${stakeholder.type}`) : ''}</td>
                                            <td>{stakeholder.share}</td>
                                            {/* <td>{displayDate(stakeholder.beginsAt)}</td> */}
                                            {/* <td>{displayDate(stakeholder.finishAt)}</td> */}
                                            <td>
                                                <div className='o-table-actions o-table-actions-alt'>
                                                    <i className="fas fa-edit"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => history.push(`/stakeholders/modify/${stakeholder.id}`)}></i>
                                                    <i className="fas fa-trash-alt"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => this.handleRemove(stakeholder)}></i>
                                                </div>
                                            </td>
                                        </tr>);
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='p-grid o-stakeholders-page-footer'>
                    <Paginator
                        countAll={countAll}
                        offset={filter.offset}
                        rows={filter.limit}
                        onPageChange={this.handleOnPageChange}
                    ></Paginator>
                </div>
                <Dialog
                    visible={removeDialogVisible}
                    onCancel={() => this.setState({ removeDialogVisible: false, removeStakeholder: null })}
                    onHide={() => this.setState({ removeDialogVisible: false, removeStakeholder: null })}
                    onOk={() => this.handleRemoveOK()}
                >
                    <p>{i18next.t('app_dialog_confirm_stakeholder_remove')}</p>
                    <p><b>{removeStakeholder ? removeStakeholder.name : ''}</b></p>
                </Dialog>
            </Page>
        );
    }
}

const mapStateToProps = ({ stakeholder }) => {
    return { store: { stakeholder } };
};

const mapDispatchToProps = {
    initPage,
    fetchGetStakeholders,
    fetchGetArtists,
    fetchPatchRemoveStakeholder,
    setFilter,
    setStakeholders,
};

const StakeholdersPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedStakeholdersPage);

export default StakeholdersPage;
