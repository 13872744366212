import React from 'react';
import i18next from 'i18next';
import Dialog from '../../shared/components/dialog.component';
import InputMultiSelectInline from '../../shared/components/form/input-multiselect-inline.component';
import InputSwitch from '../../shared/components/form/input-switch.component';
import './dialog-on-air.component.css';

const DialogOnAir = ({ disabled, errors, notify, orientation, post, onCancel, onHide, onNotify, onOrientation, onOk, visible }) => {
    const orientations = [
        { label: i18next.t('app_orientation_landscape'), value: 'landscape' },
        { label: i18next.t('app_orientation_portrait'), value: 'portrait' },
    ];

    return (
        <Dialog
            visible={visible}
            onCancel={onCancel}
            onHide={onHide}
            onOk={onOk}
            disabled={disabled}
        >
            <div className="o-dialog-on-air">
                {(post.media && post.media.live && !post.media.live.onAir && !post.media.live.firstOnAir) && (
                    <p className='o-dialog-on-air-warning'>{i18next.t('app_dialog_on_air_not_first')}</p>
                )}
                {(post.media && post.media.live && !post.media.live.onAir) && (
                    <div>
                        <h1>{i18next.t('app_dialog_on_air_1')}</h1>
                        <p>{i18next.t('app_dialog_on_air_2')}</p>
                        <p>{i18next.t('app_dialog_on_air_3')}</p>
                        <p>{i18next.t('app_dialog_on_air_4')}</p>
                    </div>
                )}
                {(post.media && post.media.live && post.media.live.onAir) && (
                    <div>
                        <h1>{i18next.t('app_dialog_on_air_stop')}</h1>
                    </div>
                )}
                {disabled && (
                    <p className='o-dialog-on-air-spinner'><i className='pi pi-spin pi-spinner' style={{ fontSize: '4em' }}></i></p>
                )}
                {errors.length > 0 && (
                    <h2>{i18next.t('app_error')}</h2>
                )}
                {errors.map((error, index) => {
                    return <p key={index}>{i18next.t(error)}</p>;
                })}

                {(post.media && post.media.live && !post.media.live.onAir) && (
                    <div>
                        <InputMultiSelectInline
                            multiple={false}
                            name='locales'
                            options={orientations}
                            onChange={onOrientation}
                            value={orientation}
                        />
                        <InputSwitch
                            name='notify'
                            label={i18next.t('app_post_send_notification')}
                            onChange={onNotify}
                            checked={notify}
                        />
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default DialogOnAir;
