import { initialState, type } from '../actions/user.action';

const user = (state = initialState, action) => {
    switch (action.type) {
        case type.USER_FETCH_PENDING:
            return { ...state, loading: action.loading };
        case type.USER_FETCH_GET_TECH_ACCESS_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                techPassword: action.techPassword,
            };
        case type.USER_FETCH_GET_USERS_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                countAll: action.countAll,
                users: action.users,
                zones: action.zones,
            };
        case type.USER_LOGIN_SUCCESS:
            return { ...state, loading: action.loading, account: action.user };
        case type.USER_LOGOUT:
            return { ...state, user: initialState.user };
        case type.USER_POST_RECOVERY_SUCCESS:
            return { ...state, loading: action.loading };
        case type.USER_PUT_SUCCESS:
            return { ...state, loading: action.loading, account: action.user };
        case type.USER_PUT_RECOVERY_SUCCESS:
            return { ...state, loading: action.loading, account: action.user };
        case type.USER_RESET_TECH_ACCESS:
            return {
                ...state,
                techPassword: action.techPassword,
            };
        case type.USER_SET_FILTER:
            return { ...state, filter: action.filter };
        case type.USER_SET_GDPR:
            return { ...state, account: { ...state.account, gdpr: true } };
        case type.USER_SET_NEW_PASSWORD:
            return { ...state, newPassword: action.newPassword };
        case type.USER_VERIFY_SUCCESS:
            return { ...state, loading: action.loading, account: action.user };
        default:
            return state;
    }
};

export default user;
