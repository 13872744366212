import React, { Component } from 'react';
import i18next from 'i18next';
import ArtistDetail from './components/artist-detail.component';
import ArtistPostList from './artist-post-list.component';
import Config from '../config';
import Dialog from '../shared/components/dialog.component';
import ImageUpload from '../shared/components/form/image-upload.component';
// import InputSwitch from '../shared/components/form/input-switch.component';
import InputText from '../shared/components/form/input-text.component';
import slugify from 'slugify';
import InputCompletionText from '../shared/components/form/input-completion-text.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { initPage } from '../store/actions/app.action';
import {
    fetchGetArtist,
    fetchGetArtistStakeholders,
    fetchPutArtist,
    resetArtist,
    setArtist,
    setPicture,
    setBanner,
} from '../store/actions/artist.action';
import { fetchGetPosts, setFilter } from '../store/actions/post.action';
import { fetchGetTags } from '../store/actions/tag.action';
import './artist-modify-page.component.css';
// import ArtistStakeholderList from './artist-stakeholder-list.component';

class ConnectedArtistModifyPage extends Component {
    constructor(props) {
        super(props);

        this.locales = Config.locales.LIST.map(item => {
            return {
                label: i18next.t(item.label),
                value: item.value,
            };
        });

        this.state = {
            dirty: false,
            localeValues: [],
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleAddTag = this.handleAddTag.bind(this);
        this.handleBannerOnChange = this.handleBannerOnChange.bind(this);
        this.handleBannerOnClick = this.handleBannerOnClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeAbout = this.handleChangeAbout.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleDialogSubmit = this.handleDialogSubmit.bind(this);
        this.handleDialogCancel = this.handleDialogCancel.bind(this);
        this.handleOnPageChange = this.handleOnPageChange.bind(this);
        this.handlePictureOnChange = this.handlePictureOnChange.bind(this);
        this.handlePictureOnClick = this.handlePictureOnClick.bind(this);
        this.handleRemoveTag = this.handleRemoveTag.bind(this);
        this.handleSearchTags = this.handleSearchTags.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        this.props.initPage(`/artists/modify/${id}`);
        await this.props.fetchGetArtist(id);

        const { filter } = this.props.store.post;
        await this.props.fetchGetPosts({ artist: id, ...filter });

        await this.props.fetchGetArtistStakeholders(id);
    }

    componentWillUnmount() {
        this.props.resetArtist();
    }

    handleAction(action, data) {
        const { id } = this.props.store.artist.artist;
        switch (action) {
            case Config.action.BACK:
                history.push('/');
                break;
            case Config.action.CHAT:
                history.push(`/artists/chat/${id}`);
                break;
            default:
        }
    }

    handleAddTag(tag) {
        const artist = { ...this.props.store.artist.artist };
        const tags = artist.tags;
        if (tag.id === null) {
            const slugged = slugify(tag.name, {
                lower: true,
                strict: true,
                remove: /[*+~.()'"!:@]/g,
            });
            tags.push({ name: slugged });
        } else {
            tags.push(tag);
        }
        artist.tags = tags;
        this.props.setArtist(artist);
        this.setState({ showTags: false, dirty: true });
    }

    handleBannerOnChange(files) {
        const file = files[0];
        if (file) {
            this.props.setBanner(file);
        }
        this.setState({ dirty: true });
    }

    handleBannerOnClick() {
        this.props.setBanner(null);
        this.setState({ dirty: true });
    }

    handleChange(event) {
        const { name, value } = event.target;
        const artist = { ...this.props.store.artist.artist };
        artist[name] = value;
        this.props.setArtist(artist);
        this.setState({ dirty: true });
    }

    handleChangeAbout(about) {
        const artist = { ...this.props.store.artist.artist };
        artist.about = about;
        this.props.setArtist(artist);
        this.setState({ dirty: true });
    }

    handleChangeLocales(event) {
        let localeValues = this.state.localeValues;
        const value = event.value;
        const eventLength = value.length;

        if (localeValues.length > eventLength && eventLength > 0) {
            const removedLocale = localeValues.filter(l => !value.includes(l))[0];
            this.setState({
                isDialogVisible: true,
                dialogLocaleToRemove: removedLocale,
                dialogNextLocaleValues: value,
            });
        }

        if (eventLength > 0 && localeValues.length <= eventLength) {
            localeValues = value;
            this.setState({ localeValues });
        }
    }

    handleDialogCancel() {
        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            dialogNextLocaleValues: [],
        });
    }

    handleDialogSubmit() {
        const about = { ...this.props.store.artist.artist.about };
        delete about[this.state.dialogLocaleToRemove];
        this.handleChangeAbout(about);

        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            localeValues: this.state.dialogNextLocaleValues,
            dialogNextLocaleValues: [],
        });
    }

    handleOnPageChange(first, rows) {
        const { id } = this.props.match.params;
        const { filter } = this.props.store.post;
        filter.limit = rows;
        filter.offset = first;
        this.props.setFilter(filter);
        this.props.fetchGetPosts({ artist: id, ...filter });
    }

    handlePictureOnChange(files) {
        const file = files[0];
        if (file) {
            this.props.setPicture(file);
        }
        this.setState({ dirty: true });
    }

    handlePictureOnClick() {
        this.props.setPicture(null);
        this.setState({ dirty: true });
    }

    handleRemoveTag(index) {
        const artist = { ...this.props.store.artist.artist };
        const tags = artist.tags;
        tags.splice(index, 1);
        artist.tags = tags;
        this.props.setArtist(artist);
        this.setState({ dirty: true });
    }

    handleSearchTags(query) {
        this.setState({ showTags: true });
        this.props.fetchGetTags(query);
    }

    handleSubmit() {
        const { artist, pictureFile, bannerFile } = this.props.store.artist;
        if (artist.name.trim().replace(/  +/g, ' ').length > 0) {
            this.props.fetchPutArtist(artist, pictureFile, bannerFile, () => {
                this.setState({ dirty: false });
            });
        }
        this.setState({ submit: true });
    }

    render() {
        const { dirty } = this.state;
        const {
            store: {
                artist: { artist, loading, banner, bannerFile, picture, pictureFile }, // , stakeholders },
                post: { posts, filter, countAll },
                tag: { tags },
            },
        } = this.props;

        let bannerPath = banner;
        if (bannerPath && !bannerPath.includes('blob:')) {
            bannerPath = `${bannerPath}?method=resize&h=250`;
        }

        let picturePath = picture;
        if (picturePath && !picturePath.includes('blob:')) {
            picturePath = `${picturePath}?method=resize&h=250`;
        }

        const aboutKeys = typeof artist.about === 'object' ? Object.keys(artist.about) : ['fr'];
        if (this.state.localeValues.length === 0 && aboutKeys.length !== 0) {
            this.setState({ localeValues: aboutKeys });
        }

        return (
            <Page
                title={artist.name}
                onClick={() => this.handleAction(Config.action.BACK, null)}
                loading={loading}
            >
                <Panel title={i18next.t('app_general_information')} toggleable={true} collapsed={true}>
                    <div className='o-artist-modify'>
                        <div className='p-grid'>
                            <div className='p-col-8'>
                                <InputText
                                    name='name'
                                    label={i18next.t('app_artist_name')}
                                    value={artist.name}
                                    onChange={this.handleChange}
                                    onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                                />
                                {this.state.dirty && !artist.name && (
                                    <div className='error'>*{i18next.t('app_error_artist_mandatory_name')}</div>
                                )}
                                <InputCompletionText
                                    name='tags'
                                    label={i18next.t('app_artist_tags')}
                                    onSearch={this.handleSearchTags}
                                    onCancel={() => this.setState({ showTags: false })}
                                    onAdd={this.handleAddTag}
                                    onRemove={this.handleRemoveTag}
                                    options={this.state.showTags ? tags.map(tag => { return { id: tag, name: tag }; }) : []}
                                    values={artist.tags}
                                />
                                {/*
                                <InputSwitch
                                    name='promote'
                                    label={i18next.t('app_artist_promote')}
                                    checked={artist.promote}
                                    onChange={this.handleChange}
                                />
                                */}
                                <InputMultiSelectInline
                                    label={i18next.t('app_artist_about')}
                                    name='locales'
                                    options={this.locales}
                                    onChange={this.handleChangeLocales}
                                    value={this.state.localeValues}
                                />

                                {this.state.localeValues.map(locale => {
                                    const id = `artist_about_${locale}`;
                                    return (
                                        <ArtistDetail
                                            key={id}
                                            lang={locale}
                                            about={artist.about}
                                            onChange={this.handleChangeAbout}
                                        />
                                    );
                                })}

                            </div>
                            <div className='p-col-4'>
                                <div className='o-artist-modify-picture'>
                                    <ImageUpload
                                        name='artist-picture'
                                        label={i18next.t('app_artist_image_picture')}
                                        onClick={this.handlePictureOnClick}
                                        onChange={this.handlePictureOnChange}
                                        image={picturePath}
                                        ratio={1}
                                    />
                                </div>
                                {this.state.dirty && !pictureFile && !picture && (
                                    <div className='error'>*{i18next.t('app_error_artist_mandatory_picture')}</div>
                                )}
                                <ImageUpload
                                    name='artist-banner'
                                    label={i18next.t('app_artist_image_banner')}
                                    onClick={this.handleBannerOnClick}
                                    onChange={this.handleBannerOnChange}
                                    image={bannerPath}
                                />
                                {this.state.dirty && !bannerFile && !banner && (
                                    <div className='error'>*{i18next.t('app_error_artist_mandatory_banner')}</div>
                                )}
                            </div>
                        </div>
                        <div className='p-grid'>
                            <div className='p-col-8'></div>
                            <div className='p-col-4'>
                                <SubmitButton
                                    label={i18next.t('app_submit')}
                                    onClick={() => this.handleSubmit()}
                                    disabled={artist.loading || !this.state.dirty}
                                />
                            </div>
                        </div>
                    </div>
                    <Dialog
                        visible={this.state.isDialogVisible}
                        onHide={() => this.handleDialogCancel()}
                        onCancel={() => this.handleDialogCancel()}
                        onSubmit={() => this.handleDialogSubmit()}
                        text={i18next.t('app_dialog_delete_locale')}
                    />
                </Panel>

                <div className="o-artist-modify-action">
                    <div className='p-grid'>
                        <div className='p-offset-10 p-col-2'>
                            <SubmitButton
                                kind="secondary"
                                label={i18next.t('app_artist_chat')}
                                onClick={() => this.handleAction(Config.action.CHAT, null)}
                            />
                        </div>
                    </div>
                </div>

                <ArtistPostList
                    artist={artist}
                    countAll={countAll}
                    filter={filter}
                    onPageChange={this.handleOnPageChange}
                    posts={posts}
                />
                {/* <ArtistStakeholderList artist={artist} stakeholders={stakeholders} /> */}
            </Page>
        );
    }
}

const mapStateToProps = ({ app, artist, post, tag }) => {
    return { store: { app, artist, post, tag } };
};

const mapDispatchToProps = {
    fetchGetTags,
    fetchGetArtist,
    fetchGetArtistStakeholders,
    fetchGetPosts,
    fetchPutArtist,
    initPage,
    resetArtist,
    setArtist,
    setFilter,
    setPicture,
    setBanner,
};

const ArtistModifyPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedArtistModifyPage);

export default ArtistModifyPage;
