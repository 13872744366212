import React from 'react';
import displayDate from '../../shared/components/display-date';
import i18next from 'i18next';
import moment from 'moment';
import ButtonIcon from '../../shared/components/form/button-icon.component';
import Config from '../../config';
import './table.component.css';

const Table = ({ locale, onClick, styles, users }) => {
    const dynamicBodies = users.map((data, i) => {
        moment.locale(locale);

        const active = data => {
            if (!data.deletedAt && data.active) {
                return <i className='fas fa-check' />;
            } else if (!data.deletedAt && !data.active) {
                return <i className='fas fa-moon' />;
            } else if (data.deletedAt) {
                return <i className='fas fa-times' />;
            }

            return null;
        };

        return (
            <tr key={data.id}>
                <td></td>
                <td className='left'>{data.lastname}</td>
                <td className='left'>{data.firstname}</td>
                <td className='left'>{data.email}</td>
                <td>{active(data)}</td>
                <td>{displayDate(data.lastConnection)}</td>
                <td>{i18next.t(`app_locale_${data.locale}`)}</td>
                <td>{data.device}</td>
                <td>
                    <div className='o-table-actions'>
                        <ButtonIcon
                            styles={styles}
                            icon='fas fa-eye'
                            kind='secondary'
                            onClick={() => onClick(Config.action.TECH, data)}
                        />
                    </div>
                </td>
            </tr>
        );
    });

    return (
        <div className='o-table o-users-table'>
            <table>
                <thead>
                    <tr>
                        <th className='min1'></th>
                        <th className='min5 left'>
                            {i18next.t('app_user_lastname')}
                        </th>
                        <th className='min5 left'>
                            {i18next.t('app_user_firstname')}
                        </th>
                        <th className='min5 left'>
                            {i18next.t('app_user_email')}
                        </th>
                        <th className='min2'>
                            {i18next.t('app_user_active')}
                        </th>
                        <th className='min5'>
                            {i18next.t('app_user_last_connection')}
                        </th>
                        <th className='min3'>
                            {i18next.t('app_user_language')}
                        </th>
                        <th className='min3'>
                            {i18next.t('app_user_device')}
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{dynamicBodies}</tbody>
            </table>
        </div>
    );
};

export default Table;
