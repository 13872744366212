import { initialState, type } from '../actions/shop.action';

const section = (state = initialState, action) => {
    switch (action.type) {
        case type.SHOP_FETCH_PENDING:
            return { ...state, loading: action.loading };

        case type.SHOP_FETCH_GET_SUCCESS:
            return {
                ...state,
                countAll: action.countAll,
                items: action.items,
            };

        case type.SHOP_FETCH_GET_ONE_SUCCESS:
            return {
                ...state,
                item: action.item,
                picture: action.picture,
            };

        case type.SHOP_FETCH_POST_SUCCESS:
        case type.SHOP_FETCH_PUT_SUCCESS:
            return {
                ...state,
                item: action.item,
                picture: action.picture,
                pictureFile: null,
            };

        case type.SHOP_RESET_ITEM:
            return {
                ...state,
                item: { ...initialState.item },
                picture: null,
                pictureFile: null,
            };

        case type.SHOP_SET_ITEM:
            return {
                ...state,
                item: action.item,
            };

        case type.SHOP_SET_ITEMS:
            return {
                ...state,
                items: action.items,
            };

        case type.SHOP_SET_PICTURE:
            return {
                ...state,
                picture: action.picture ? URL.createObjectURL(action.picture) : null,
                pictureFile: action.picture,
            };

        default:
            return state;
    }
};

export default section;
