import React from 'react';
import i18next from 'i18next';
import './button-rounded.component.css';

const ButtonRounded = ({ className, disabled, label, onClick }) => {
    return (
        <div className='o-button-rounded'>
            <button
                type='button'
                className={className}
                onClick={onClick.bind(this)}
                disabled={disabled || false}
            >
                {i18next.t(label || 'app_save')}
            </button>
        </div>
    );
};

export default ButtonRounded;
