import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import ButtonIcon from '../../shared/components/form/button-icon.component';
import InputCompletionText from '../../shared/components/form/input-completion-text.component';
import InputNumber from '../../shared/components/form/input-number.component';

import './add-stakeholder.component.css';

const AddStakeholder = ({ onAdd, onCancel, onChange, onSearch, options, participationRate, values, stakeholders }) => {
    const [rate, setRate] = useState(0);

    useEffect(() => {
        calcRate(participationRate, stakeholders);
    });

    const calcRate = (participationRate, stakeholders) => {
        let participation = parseFloat(participationRate);
        stakeholders.forEach(element => {
            participation = parseFloat(participation) + parseFloat(element.share);
        });

        setRate(participation);
    };

    const onChangeShare = ({ target: { value } }, stakeholder) => {
        const newStakeholders = stakeholders;
        const index = newStakeholders.findIndex(e => e.id === stakeholder.id);
        newStakeholders[index].share = value;
        onChange(newStakeholders);
        calcRate(participationRate, stakeholders);
    };

    const onRemoveStakeholder = (stakeholder) => {
        const newStakeholders = stakeholders;
        const index = newStakeholders.findIndex(e => e.id === stakeholder.id);
        newStakeholders.splice(index, 1);
        onChange(newStakeholders);
    };

    return (
        <div className='o-add-stakeholder'>
            <div className={`o-add-stakeholder-actual ${rate > 100 ? 'o-max' : ''}`}>{`${i18next.t('app_add_stakeholder_actual_shares')} ${rate} %`}</div>
            <div className='o-add-stakeholder-title'>{i18next.t('app_add_stakeholder_title')}</div>
            <InputCompletionText
                editable={false}
                name='find'
                onAdd={onAdd}
                onCancel={onCancel}
                onSearch={onSearch}
                options={options}
                placeholder={i18next.t('app_add_stakeholder_find')}
                values={values}
            />
            {stakeholders.map((stakeholder, i) => {
                return (
                    <div className='o-add-stakeholder-item' key={i}>
                        <div className='o-add-stakeholder-item-name'>{stakeholder.name}</div>
                        <div className='o-add-stakeholder-item-share'>
                            <InputNumber name='share'
                                min={0}
                                max={100}
                                onChange={(e) => onChangeShare(e, stakeholder)}
                                step="0.1"
                                pattern="^\d+(\,\d{0,1})?$"
                                value={stakeholder.share} />
                        </div>
                        <div className='o-add-stakeholder-item-pc'>
                            <span>%</span>
                        </div>
                        <div className='o-add-stakeholder-item-action'>
                            <ButtonIcon icon='fas fa-times' onClick={() => onRemoveStakeholder(stakeholder)} />
                        </div>
                    </div>
                );
            })}
            {stakeholders.length > 0 && <div className='o-add-stakeholder-share-info'>{i18next.t('app_add_stakeholder_share_info')}</div>}
        </div>
    );
};

export default AddStakeholder;
