import { historyHelper as history } from '../../core/helpers/history.helper';
import { httpService as http } from '../../core/services/http.service';
import { fetchPostPicture, fetchPutPicture } from './app.action';
import { showError, showSuccess } from './message.action';

export const type = {
    SHOP_FETCH_PENDING: 'SHOP_FETCH_PENDING',
    SHOP_FETCH_GET_SUCCESS: 'SHOP_FETCH_GET_SUCCESS',
    SHOP_FETCH_GET_ONE_SUCCESS: 'SHOP_FETCH_GET_ONE_SUCCESS',
    SHOP_FETCH_POST_SUCCESS: 'SHOP_FETCH_POST_SUCCESS',
    SHOP_FETCH_POST_ITEM_ARTIST_SUCCESS: 'SHOP_FETCH_POST_ITEM_ARTIST_SUCCESS',
    SHOP_FETCH_PUT_SUCCESS: 'SHOP_FETCH_PUT_SUCCESS',
    SHOP_RESET_ITEM: 'SHOP_RESET_ITEM',
    SHOP_SET_ITEM: 'SHOP_SET_ITEM',
    SHOP_SET_ITEMS: 'SHOP_SET_ITEMS',
    SHOP_SET_PICTURE: 'SHOP_SET_PICTURE',
};

export const initialState = {
    countAll: 0,
    item: {
        label: '',
        type: 'sticker',
        value: 0,
        active: false,
        artists: [],
    },
    items: [],
    filter: {
        limit: 30,
    },
    loading: false,
};

export const fetchPending = loading => {
    return { type: type.SHOP_FETCH_PENDING, loading };
};

const fetchGetItemSuccess = ({ item, picture }) => {
    return {
        type: type.SHOP_FETCH_GET_ONE_SUCCESS,
        item: { ...item, artists: item.artists || [] },
        picture,
    };
};

export const fetchGetItem = (id, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/shop/items/${id}`).then(
            response => {
                dispatch(fetchPending(false));
                if (!response.ok) {
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetItemSuccess(response.data));
                    if (callback) {
                        callback(response.data);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchGetItemsSuccess = ({ items, countAll }) => {
    return {
        type: type.SHOP_FETCH_GET_SUCCESS,
        items,
        countAll,
    };
};

export const fetchGetItems = (options = {}, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        let query = '';
        if (Object.keys(options).length > 0) {
            query += options.artist ? `&artist=${options.artist}` : '';
            query += options.type ? `&type=${options.type}` : '';
            query += options.find ? `&find=${options.find}` : '';
            query += options.limit ? `&limit=${options.limit}` : '';
            query += options.offset ? `&offset=${options.offset}` : '';
            query = `?${query.substring(1)}`;
        }

        http.get(`/v1/bo/shop/items${query}`).then(
            response => {
                dispatch(fetchPending(false));
                if (!response.ok) {
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetItemsSuccess(response.data));
                    if (callback) {
                        callback(response.data.items);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPatchRemoveItem = id => {
    return async dispatch => {
        dispatch(fetchPending(true));
        const data = {};

        http.patch(`/v1/bo/shop/items/${id}/remove`, data).then(
            response => {
                dispatch(fetchPending(false));
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    dispatch(showSuccess({ summary: 'app_shop_item_removed' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchPostItemSuccess = ({ item, picture }) => {
    return {
        type: type.SHOP_FETCH_POST_SUCCESS,
        item: { ...item, artists: item.artists || [] },
        picture,
    };
};

export const fetchPostItem = (item, pictureFile) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        const { picture } = await fetchPostPicture(pictureFile, 'sticker');

        if (picture) {
            const data = {
                ...item,
                picture: picture,
            };

            http.post('/v1/bo/shop/items', data).then(
                response => {
                    dispatch(fetchPending(false));
                    if (!response.ok) {
                        dispatch(showError(response.error));
                    } else {
                        dispatch(fetchPostItemSuccess(response.data));
                        dispatch(showSuccess({ summary: 'app_shop_item_created' }));
                        history.push(`/shop/modify/${response.data.item.id}`);
                    }
                },
                error => {
                    dispatch(fetchPending(false));
                    dispatch(showError(error.error));
                }
            );
        } else {
            dispatch(fetchPending(false));
            dispatch(showError({ error: { summary: 'app_picture_not_uploaded' } }));
        }
    };
};

const fetchPutItemSuccess = ({ item, picture }) => {
    return {
        type: type.SHOP_FETCH_PUT_SUCCESS,
        item: { ...item, artists: item.artists || [] },
        picture,
    };
};

export const fetchPutItem = (item, pictureFile, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        let pictureName = item.picture;
        let picture = '';

        if (pictureFile) {
            let result = {};
            if (item.picture && item.picture !== '') {
                result = await fetchPutPicture(item.picture, pictureFile, 'sticker');
            } else {
                result = await fetchPostPicture(pictureFile, 'sticker');
            }

            pictureName = result.picture;
            picture = result.pictureURL;
        }

        if (pictureName) {
            const data = {
                ...item,
                picture: pictureName,
            };

            http.put(`/v1/bo/shop/items/${item.id}`, data).then(
                response => {
                    dispatch(fetchPending(false));
                    if (!response.ok) {
                        dispatch(showError(response.error));
                    } else {
                        dispatch(fetchPutItemSuccess(response.data, picture));
                        if (callback) {
                            callback(response.data);
                        }
                        dispatch(showSuccess({ summary: 'app_shop_item_updated' }));
                    }
                },
                error => {
                    dispatch(fetchPending(false));
                    dispatch(showError(error.error));
                }
            );
        } else {
            dispatch(fetchPending(false));
            dispatch(showError({ error: { summary: 'app_picture_not_uploaded' } }));
        }
    };
};

export const fetchPostItemArtist = (item, artist) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        const data = {
            artistId: artist,
        };

        http.post(`/v1/bo/shop/items/${item.id}/artists`, data).then(
            response => {
                dispatch(fetchPending(false));
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostItemSuccess(response.data));
                    dispatch(showSuccess({ summary: 'app_shop_item_artist_added' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchDeleteItemArtist = (item, artist) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.del(`/v1/bo/shop/items/${item.id}/artists/${artist}`, {}).then(
            response => {
                dispatch(fetchPending(false));
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostItemSuccess(response.data));
                    dispatch(showSuccess({ summary: 'app_shop_item_artist_removed' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const resetItem = () => {
    return {
        type: type.SHOP_RESET_ITEM,
    };
};

export const setItem = item => {
    return { type: type.SHOP_SET_ITEM, item };
};

export const setItems = items => {
    return { type: type.SHOP_SET_ITEMS, items };
};

export const setPicture = picture => {
    return { type: type.SHOP_SET_PICTURE, picture };
};
