import React, { Component } from 'react';
import i18next from 'i18next';
import SectionTable from './components/section-table.component';
import Dialog from '../shared/components/dialog.component';
import Page from '../shared/components/page.component';
import { historyHelper as history } from '../core/helpers/history.helper';
import { connect } from 'react-redux';
import { initPage } from '../store/actions/app.action';
import {
    fetchGetSections,
    fetchPatchRemoveSection,
    fetchPostReorder,
    setSections,
} from '../store/actions/section.action';
import SubmitButton from '../shared/components/form/submit-button.component';
import Config from '../config';
import './sections-page.component.css';
// import displayDate from '../shared/components/display-date';

class ConnectedSectionsPage extends Component {
    constructor(props) {
        super(props);
        this.handleAction = this.handleAction.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        // this.handleRemoveOK = this.handleRemoveOK.bind(this);
        this.handleRowReorder = this.handleRowReorder.bind(this);

        this.state = {
            removeDialogVisible: false,
            removeSection: null,
        };
    }

    async componentDidMount() {
        this.props.initPage('/sections');
        this.props.fetchGetSections();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.ADD:
                history.push('/sections/new');
                break;

            case Config.action.MODIFY:
                history.push(`/sections/modify/${data.id}`);
                break;

            default:
        }
    }

    handleRemove(section) {
        this.setState({ removeDialogVisible: true, removeSection: section });
    }

    handleRemoveOK() {
        const { removeSection } = this.state;
        const { sections } = this.props.store.section;
        const index = sections.findIndex(e => e.id === removeSection.id);
        sections.splice(index, 1);
        this.props.setSections(sections);
        this.props.fetchPatchRemoveSection(removeSection.id);
        this.setState({ removeDialogVisible: false, removeSection: null });
    }

    handleRowReorder(sections) {
        this.props.fetchPostReorder(sections);
    }

    render() {
        const { removeDialogVisible, removeSection } = this.state;

        const {
            store: { section: { sections }, loading },
        } = this.props;

        return (
            <Page title={i18next.t('app_page_sections')} loading={loading}>
                <div className='p-grid'>
                    <div className='p-col-3'>
                    </div>
                    <div className='p-col-9 o-new-button'>
                        <SubmitButton
                            label={i18next.t('app_sections_new')}
                            onClick={() => history.push('/sections/new')}
                        />
                    </div>
                </div>
                <div className='p-grid'>
                    <div className='p-col-12'>
                        <SectionTable
                            sections={sections}
                            onRemove={this.handleRemove}
                            onRowReorder={this.handleRowReorder}
                        />
                    </div>
                </div>
                <Dialog
                    visible={removeDialogVisible}
                    onCancel={() => this.setState({ removeDialogVisible: false, removeSection: null })}
                    onHide={() => this.setState({ removeDialogVisible: false, removeSection: null })}
                    onOk={() => this.handleRemoveOK()}
                >
                    <p>{i18next.t('app_dialog_confirm_section_remove')}</p>
                    <p><b>{removeSection ? removeSection.title : ''}</b></p>
                </Dialog>
            </Page>
        );
    }
}

const mapStateToProps = ({ section }) => {
    return { store: { section } };
};

const mapDispatchToProps = {
    fetchGetSections,
    fetchPatchRemoveSection,
    fetchPostReorder,
    initPage,
    setSections,
};

const SectionsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedSectionsPage);

export default SectionsPage;
