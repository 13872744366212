import React from 'react';
import i18next from 'i18next';
import InputEmail from '../../shared/components/form/input-email.component';
import Submit from '../../core/components/form/submit.component';

const ForgotForm = ({
    onChange,
    onSubmit,
    submitted,
    value: { username },
}) => (
    <div>
        <h4>{i18next.t('app_reset_password', 'Réinitialisation du mot de passe')}</h4>
        <InputEmail
            name='username'
            placeholder='app_username'
            value={username}
            onChange={onChange}
        />
        <Submit label={i18next.t('app_send_me_new_password', 'M\'envoyer un nouveau mot de passe')} disabled={submitted} onClick={onSubmit} />
    </div>
);

export default ForgotForm;
