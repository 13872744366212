import React from 'react';
import i18next from 'i18next';
import './input-select.component.css';

const InputSelect = ({
    all,
    disabled,
    kind,
    label,
    name,
    none,
    onChange,
    options,
    placeholder,
    value,
}) => {
    const styleInputSelect = {
        inline: {
            flexDirection: 'row',
            alignItems: 'baseline',
        },
    };

    const styleSelect = {
        inline: {
            marginLeft: '.5rem',
        },
    };

    const dynamicOptions = options => {
        const htmlOptions =
            options &&
            options.map((val, i) => {
                return (
                    <option key={i} value={val.value}>
                        {val.label}
                    </option>
                );
            });

        return htmlOptions;
    };

    return (
        <div className='o-input-select' style={styleInputSelect[kind]}>
            {label && <label>{label}</label>}
            <select
                disabled={disabled === true ? 'disabled' : ''}
                name={name}
                onChange={onChange}
                placeholder={placeholder || ''}
                style={styleSelect[kind]}
                value={value}
            >
                {all && (
                    <option key='all' value='all' selected>{i18next.t('app_all')}</option>
                )}'
                {none && <option key='none' value='none'></option>}
                {dynamicOptions(options)}
            </select>
        </div>
    );
};

export default InputSelect;
