import React from 'react';
import { SelectButton } from 'primereact/selectbutton';
import './input-multiselect-inline.component.css';

const InputMultiSelectInline = ({ label, name, multiple = true, value, options, onChange, disabled = false }) => {
    return (
        <div className='o-input-multiselect-inline'>
            {label && (
                <label className='o-input-multiselect-inline-label'>{label}</label>
            )}
            <SelectButton
                name={name}
                multiple={multiple}
                value={value}
                options={options}
                onChange={onChange.bind(this)}
                disabled={disabled}
            />
        </div>
    );
};

export default InputMultiSelectInline;
