import React from 'react';
import Radium from 'radium';

import './input-password.component.css';

const InputPassword = ({ label, name, onChange, placeholder, value }) => {
    return (
        <div className='o-input-select'>
            {label && <label>{label}</label>}
            <input
                name={name}
                onChange={onChange}
                placeholder={placeholder || ''}
                type='password'
                value={value}
            />
        </div>
    );
};

export default Radium(InputPassword);
