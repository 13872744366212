import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import AddArtist from './components/add-artist.component';
import ArtistBox from '../shared/components/artist-box.component';
import ArtistNewBox from '../shared/components/artist-new-box.component';
import Dialog from '../shared/components/dialog.component';
import InputText from '../shared/components/form/input-text.component';
import InputNumber from '../shared/components/form/input-number.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { fetchGetArtists } from '../store/actions/artist.action';
import { resetStakeholder, setStakeholder, fetchPostStakeholder } from '../store/actions/stakeholder.action';
import Config from '../config';
import './stakeholder-create-page.component.css';

class ConnectedStakeholderCreatePage extends Component {
    constructor(props) {
        super(props);

        this.types = Config.stakeholder.TYPES.map(type => {
            return {
                label: i18next.t(type.label),
                value: type.value,
            };
        });

        this.state = {
            addArtistDialog: false,
            dirty: false,
            showArtists: false,
            stakeholderArtists: [],
            submit: false,
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleArtistAdd = this.handleArtistAdd.bind(this);
        this.handleArtistChange = this.handleArtistChange.bind(this);
        this.handleArtistSearch = this.handleArtistSearch.bind(this);
        this.handleArtistSubmit = this.handleArtistSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeShare = this.handleChangeShare.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        this.props.resetStakeholder();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    }

    handleArtistAdd(artist) {
        const { findArtists, stakeholderArtists } = this.state;
        const index = findArtists.findIndex(e => e.id === artist.id);
        stakeholderArtists.push({ ...findArtists[index], share: 0 });
        this.setState({ showArtists: false, findArtists: [], stakeholderArtists });
    }

    handleArtistChange(artists) {
        this.setState({ stakeholderArtists: artists });
    }

    async handleArtistSearch(query) {
        const { stakeholder } = this.props.store.stakeholder;

        await this.props.fetchGetArtists({ find: query }, artists => {
            if (!artists) {
                this.setState({ showArtists: false, findArtists: [] });
                return;
            }

            if (!stakeholder.artists) {
                this.setState({ showArtists: true, findArtists: artists });
                return;
            }

            const findArtists = artists.filter(artist => {
                const index = stakeholder.artists.findIndex(e => e.id === artist.id);
                return index < 0;
            });

            this.setState({ showArtists: true, findArtists });
        });
    }

    handleArtistSubmit() {
        const stakeholderArtists = this.state.stakeholderArtists;
        const stakeholder = { ...this.props.store.stakeholder.stakeholder, artists: stakeholderArtists };
        this.props.setStakeholder(stakeholder);
        this.setState({
            addArtistDialog: false,
            showArtists: false,
            findArtists: [],
            stakeholderArtists: [],
        });
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.changeValue(name, value);
    }

    handleChangeShare(event) {
        const { name, value } = event.target;
        this.changeValue(name, Number(value));
    }

    changeValue(name, value) {
        const stakeholder = { ...this.props.store.stakeholder.stakeholder };
        stakeholder[name] = value;
        this.props.setStakeholder(stakeholder);
        this.setState({ dirty: true });
    }

    handleSubmit() {
        const { stakeholder } = this.props.store.stakeholder;
        if (stakeholder.name.trim().replace(/  +/g, ' ').length > 0) {
            this.props.fetchPostStakeholder(stakeholder);
        }
        this.setState({ submit: true });
    }

    render() {
        const { addArtistDialog, dirty, findArtists, stakeholderArtists, submit } = this.state;
        const {
            store: { loading, stakeholder: { stakeholder } },
        } = this.props;

        const dynamicBodies = stakeholder.artists.map((data, i) => {
            return (
                <ArtistBox
                    key={data.id}
                    artist={data}
                    onClick={() => this.handleArtists(data)}
                />
            );
        });

        return (
            <Page
                title={i18next.t('app_page_stakeholder_create')}
                onClick={() => this.handleAction(Config.action.BACK, null)}
                loading={loading}
            >
                <Panel title={i18next.t('app_general_information')} toggleable={false}>
                    <div className='o-stakeholder-create'>
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className='p-grid'>
                                    <div className='p-col-6'>
                                        <InputText
                                            name='name'
                                            label={i18next.t('app_stakeholder_name')}
                                            value={stakeholder.name}
                                            onChange={this.handleChange}
                                            onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                                        />
                                        {submit && !stakeholder.name && (
                                            <div className='error'>
                                                *
                                                {i18next.t('app_error_stakeholder_mandatory_name')}
                                            </div>
                                        )}
                                    </div>
                                    <div className='p-col-6'>
                                        <InputMultiSelectInline
                                            label={i18next.t('app_stakeholder_type')}
                                            name='type'
                                            value={stakeholder.type}
                                            options={this.types}
                                            onChange={this.handleChange}
                                            multiple={false}
                                        />
                                        {submit && !stakeholder.name && (
                                            <div className='error'>
                                                *
                                                {i18next.t('app_error_stakeholder_mandatory_name')}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='p-grid'>
                                    <div className='p-col-6'>
                                        <InputNumber
                                            name='share'
                                            label={i18next.t('app_stakeholder_default_share')}
                                            value={stakeholder.share}
                                            onChange={this.handleChangeShare}
                                            min={0}
                                            max={100}
                                            step={1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel title={i18next.t('app_artist_list')} toggleable={true} collapsed={false}>
                    <div className='o-stakeholder-create-artists'>
                        <ArtistNewBox onClick={() => this.setState({ addArtistDialog: true })} />
                        {dynamicBodies}
                    </div>
                </Panel >
                <div className='p-grid'>
                    <div className='p-col-4 p-offset-4'>
                        <SubmitButton
                            label={i18next.t('app_submit')}
                            onClick={() => this.handleSubmit()}
                            disabled={loading || !dirty}
                        />
                    </div>
                </div>
                <Dialog
                    visible={addArtistDialog}
                    onCancel={() => this.setState({
                        addArtistDialog: false,
                        showArtists: false,
                        findArtists: [],
                        stakeholderArtists: [],
                    })}
                    onHide={() => this.setState({
                        addArtistDialog: false,
                        showArtists: false,
                        findArtists: [],
                        stakeholderArtists: [],
                    })}
                    onSubmit={() => this.handleArtistSubmit()}
                    onSubmitLabel='app_add'
                >
                    <AddArtist
                        artists={stakeholderArtists}
                        onAdd={this.handleArtistAdd}
                        onCancel={() => this.setState({ showArtists: false, findArtists: [] })}
                        onChange={this.handleArtistChange}
                        onSearch={this.handleArtistSearch}
                        options={this.state.showArtists ? findArtists.map(artist => { return { id: artist.id, name: artist.name }; }) : []}
                    />
                </Dialog>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, stakeholder }) => {
    return { store: { app, stakeholder } };
};

const mapDispatchToProps = {
    resetStakeholder, setStakeholder, fetchGetArtists, fetchPostStakeholder,
};

const StakeholderCreatePage = connect(mapStateToProps, mapDispatchToProps)(ConnectedStakeholderCreatePage);

export default StakeholderCreatePage;
