import React from 'react';
import Radium from 'radium';
import './static-text.component.css';

const StaticText = ({ label, value }) => {
    return (
        <div className='o-static-text'>
            {label && <label>{label}</label>}
            <div className='o-static-text-value'><span>{value}</span></div>
        </div>
    );
};

export default Radium(StaticText);
