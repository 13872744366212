import { httpService as http } from '../../core/services/http.service';

export const type = {
    APP_INIT: 'APP_INIT',
    APP_INIT_PAGE: 'APP_INIT_PAGE',
    APP_SET_LOCALE: 'APP_SET_LOCALE',
    APP_SET_PAGE: 'APP_SET_PAGE',
    APP_SET_SIDE_MENU: 'APP_SET_SIDE_MENU',
};

export const buildURLQuery = options => {
    let query = '';
    if (options) {
        for (const [key, value] of Object.entries(options)) {
            if (value) {
                query += query === '' ? '?' : '&';
                query += `${key}=${value}`;
            }
        }
    }
    return query;
};

export const menuItem = {
    ACCOUNT: 'account',
    ADMIN: 'admin',
    ARTISTS: 'artists',
    BANNERS: 'banners',
    LOGOUT: 'logout',
    POSTS: 'posts',
    SECTIONS: 'sections',
    SHOP: 'shop',
    STAKEHOLDERS: 'stakeholders',
    STORECOINS: 'storecoins',
    USERS: 'users',
};

export const initialState = {
    lastPage: '',
    locale: 'fr',
    page: '',
    sideMenu: menuItem.HOME,
    template: 'default',
};

export const fetchPostPicture = (pictureFile, format) => {
    const data = new FormData();
    data.append('picture', pictureFile);

    const query = format ? `?format=${format}` : '';
    return http.postFile(`/v1/media${query}`, data).then(
        response => {
            return response.data;
        },
        error => {
            console.log(error);
            return null;
        }
    );
};

export const fetchPutPicture = (oldPicture, pictureFile, format) => {
    const data = new FormData();
    data.append('picture', pictureFile);

    const query = format ? `?format=${format}` : '';
    return http.putFile(`/v1/media/${oldPicture}${query}`, data).then(
        response => {
            return response.data;
        },
        error => {
            console.log(error);
            return null;
        }
    );
};

export const initApp = () => {
    return { type: type.APP_INIT };
};

export const initPage = page => {
    return { type: type.APP_INIT_PAGE, page: page };
};

export const setLocale = locale => {
    return { type: type.APP_SET_LOCALE, locale: locale };
};

export const setPage = page => {
    return { type: type.APP_SET_PAGE, page: page };
};

export const setSideMenu = sideMenu => {
    return { type: type.APP_SET_SIDE_MENU, sideMenu: sideMenu };
};
