import { createTransform } from 'redux-persist';
import CryptoJS from 'crypto-js';

const encrypt = createTransform(
    (inboundState, key) => {
        if (!inboundState) return inboundState;
        const cryptedText = CryptoJS.AES.encrypt(
            JSON.stringify(inboundState),
            process.env.REACT_APP_JWT_SECRET
        );

        return cryptedText.toString();
    },
    (outboundState, key) => {
        if (!outboundState) return outboundState;
        const bytes = CryptoJS.AES.decrypt(outboundState, process.env.REACT_APP_JWT_SECRET);
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);

        return JSON.parse(decrypted);
    }
);

export default encrypt;
