import { httpService as http } from '../../core/services/http.service';
import { showError } from './message.action';

export const type = {
    NOTIFICATION_FETCH_PENDING: 'NOTIFICATION_FETCH_PENDING',
    NOTIFICATION_FETCH_POST_SUCCESS: 'NOTIFICATION_FETCH_POST_SUCCESS',
    NOTIFICATION_INIT_COUNT: 'NOTIFICATION_INIT_COUNT',
    NOTIFICATION_INIT_FILTER: 'NOTIFICATION_INIT_FILTER',
    NOTIFICATION_INIT_USERS: 'NOTIFICATION_INIT_USERS',
    NOTIFICATION_SET_COUNT: 'NOTIFICATION_SET_COUNT',
    NOTIFICATION_SET_FILTER: 'NOTIFICATION_SET_FILTER',
    NOTIFICATION_SET_USERS: 'NOTIFICATION_SET_USERS',
};

export const initialState = {
    count: 0,
    filter: {},
    loading: false,
    users: [],
};

export const fetchPending = loading => {
    return { type: type.NOTIFICATION_FETCH_PENDING, loading };
};

export const fetchPostNotificationSuccess = data => {
    return {
        type: type.NOTIFICATION_FETCH_POST_SUCCESS,
        loading: false,
    };
};

export const fetchPostNotification = (filter, notification) => {
    return dispatch => {
        dispatch(fetchPending(true));

        const data = {
            filter: { ...filter, all: true, deleted: false },
            notification,
        };

        http.post('/v1/bo/users/notification', data).then(
            response => {
                dispatch(fetchPostNotificationSuccess(response.data));
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const initCount = () => {
    return {
        type: type.NOTIFICATION_INIT_COUNT,
        count: initialState.count,
    };
};

export const initFilter = () => {
    return {
        type: type.NOTIFICATION_INIT_FILTER,
        filter: initialState.filter,
    };
};

export const initUsers = () => {
    return {
        type: type.NOTIFICATION_INIT_USERS,
        users: initialState.users,
    };
};

export const setCount = count => {
    return {
        type: type.NOTIFICATION_SET_COUNT,
        count,
    };
};

export const setFilter = filter => {
    return {
        type: type.NOTIFICATION_SET_FILTER,
        filter,
    };
};

export const setUsers = users => {
    return {
        type: type.NOTIFICATION_SET_USERS,
        users,
    };
};
