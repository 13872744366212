import React from 'react';
import { InputSwitch as PrimeInputSwitch } from 'primereact/inputswitch';
import './input-switch.component.css';

const InputSwitch = ({ disabled = false, label, name, onChange, checked }) => {
    return (
        <div className='o-input-text'>
            {label && <label>{label}</label>}
            <PrimeInputSwitch
                disabled={disabled}
                name={name}
                onChange={e => onChange({ target: { name, value: e.value } })}
                checked={checked}
            />
        </div>
    );
};

export default InputSwitch;
