import { initialState, type } from '../actions/admin.action';

const admin = (state = initialState, action) => {
    switch (action.type) {
        case type.ADMIN_FETCH_PENDING:
            return { ...state, loading: action.loading };
        case type.ADMIN_FETCH_GET_CGU_SUCCESS:
            return { ...state, cgu: action.cgu, loading: action.loading };
        case type.ADMIN_FETCH_POST_CGU_SUCCESS:
            return { ...state, cgu: action.cgu, loading: action.loading };
        case type.ADMIN_SET_CGU:
            return { ...state, cgu: action.cgu };
        default:
            return state;
    }
};

export default admin;
