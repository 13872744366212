// import { httpService as http } from '../../core/services/http.service';
// import { showSuccess } from './message.action';
import S3 from 'react-aws-s3';

export const type = {
    AMAZON_FETCH_PENDING: 'AMAZON_FETCH_PENDING',
    AMAZON_FETCH_UPLOAD_VIDEO_SUCCESS: 'AMAZON_FETCH_UPLOAD_VIDEO_SUCCESS',
};

export const initialState = {
    loading: false,
};

export const fetchPending = loading => {
    return { type: type.AMAZON_FETCH_PENDING, loading };
};

export const fetchUploadFile = async (config, file, newFileName) => {
    try {
        const ReactS3Client = new S3(config);
        const response = await ReactS3Client.uploadFile(file, newFileName);
        return { ...response, ok: true };
    } catch (err) {
        console.log(err);
        return { ok: false };
    }
};
