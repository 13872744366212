import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import Dialog from '../shared/components/dialog.component';
import InputText from '../shared/components/form/input-text.component';
import InputNumber from '../shared/components/form/input-number.component';
import InputSwitch from '../shared/components/form/input-switch.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { initPage } from '../store/actions/app.action';
import { getTypes, resetStoreCoin, setStoreCoin, fetchPostStoreCoin } from '../store/actions/coin.action';
import Config from '../config';
import './storecoins-create-page.component.css';

class ConnectedStoreCoinCreatePage extends Component {
    constructor(props) {
        super(props);

        this.locales = Config.locales.LIST.map(item => {
            return {
                label: i18next.t(item.label),
                value: item.value,
            };
        });

        this.state = {
            dirty: false,
            localeValues: ['fr'],
            submit: false,
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLabel = this.handleChangeLabel.bind(this);
        this.handleChangeLabelLocale = this.handleChangeLabelLocale.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDialogSubmit = this.handleDialogSubmit.bind(this);
        this.handleDialogCancel = this.handleDialogCancel.bind(this);
    }

    componentWillUnmount() {
        this.props.resetStoreCoin();
    }

    handleAction = (action) => {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        const storeCoin = { ...this.props.store.coin.storeCoin };
        storeCoin[name] = value;
        this.props.setStoreCoin(storeCoin);
        this.setState({ dirty: true });
    };

    handleChangeLabelLocale = (event, locale) => {
        const { value } = event.target;
        const storeCoin = { ...this.props.store.coin.storeCoin };
        storeCoin.label[locale] = value;
        this.props.setStoreCoin(storeCoin);
        this.setState({ dirty: true });
    };

    handleChangeLabel = (label) => {
        const storeCoin = { ...this.props.store.coin.storeCoin };
        storeCoin.label = label;
        this.props.setStoreCoin(storeCoin);
        this.setState({ dirty: true });
    };

    handleChangeLocales(event) {
        let localeValues = this.state.localeValues;
        const value = event.value;
        const eventLength = value.length;

        if (localeValues.length > eventLength && eventLength > 0) {
            const removedLocale = localeValues.filter(l => !value.includes(l))[0];
            this.setState({
                isDialogVisible: true,
                dialogLocaleToRemove: removedLocale,
                dialogNextLocaleValues: value,
            });
        }

        if (eventLength > 0 && localeValues.length <= eventLength) {
            localeValues = value;
            this.setState({ localeValues });
        }
    }

    handleDialogSubmit() {
        const storeCoin = { ...this.props.store.coin.storeCoin };
        delete storeCoin[this.state.dialogLocaleToRemove];
        this.handleChangeLabel(storeCoin);

        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            localeValues: this.state.dialogNextLocaleValues,
            dialogNextLocaleValues: [],
        });
    }

    handleDialogCancel() {
        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            dialogNextLocaleValues: [],
        });
    }

    handleSubmit = () => {
        const storeCoin = { ...this.props.store.coin.storeCoin };
        if (Object.keys(storeCoin.label).length > 0 && storeCoin.quantity > 0) {
            this.props.fetchPostStoreCoin(storeCoin);
        }
        this.setState({ submit: true });
    };

    render() {
        const { dirty, submit } = this.state;
        const { store: { coin: { storeCoin }, loading } } = this.props;

        const types = this.props.getTypes().map(type => { return { ...type, label: i18next.t(type.label) }; });

        const dynamicLabels = this.state.localeValues.map(locale => {
            const id = `label_${locale}`;
            return (
                <InputText
                    key={id}
                    label={i18next.t(Config.locales.LABELS[locale])}
                    name='label'
                    onChange={(e) => this.handleChangeLabelLocale(e, locale)}
                    onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                    value={storeCoin.label[locale]}
                />
            );
        });

        return (
            <Page
                title={i18next.t('app_page_storecoins_create')}
                onClick={() => this.handleAction(Config.action.BACK)}
                loading={loading}
            >
                <div className='o-storecoin-create'>
                    <Panel title={i18next.t('app_general_information')} toggleable={false}>
                        <div className='p-grid'>
                            <div className='p-col-12 p-lg-8'>
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <InputMultiSelectInline
                                            label={i18next.t('app_storecoins_label')}
                                            name='locales'
                                            value={this.state.localeValues}
                                            options={this.locales}
                                            onChange={this.handleChangeLocales}
                                        />
                                        {dynamicLabels}
                                        {submit && storeCoin.label.length === 0 && (
                                            <div className='error'>
                                                *
                                                {i18next.t('app_error_storecoins_mandatory_label')}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='p-grid'>
                                    <div className='p-col-12 p-lg-6'>
                                        <InputNumber
                                            name='quantity'
                                            label={i18next.t('app_storecoins_quantity')}
                                            value={storeCoin.quantity}
                                            onChange={this.handleChange}
                                            min={0}
                                            step={1}
                                        />
                                        {submit && storeCoin.quantity <= 0 && (
                                            <div className='error'>
                                                *
                                                {i18next.t('app_error_storecoins_mandatory_quantity')}
                                            </div>
                                        )}
                                    </div>
                                    <div className='p-col-12 p-lg-6'>
                                        <InputNumber
                                            name='bonusQuantity'
                                            label={i18next.t('app_storecoins_bonus_quantity')}
                                            value={storeCoin.bonusQuantity}
                                            onChange={this.handleChange}
                                            min={0}
                                            step={1}
                                        />
                                    </div>
                                </div>
                                <div className='p-grid'>
                                    <div className='p-col-12 p-md-6 p-lg-3'>
                                        <InputSwitch
                                            name='bestBuy'
                                            label={i18next.t('app_storecoins_best_buy')}
                                            checked={storeCoin.bestBuy}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className='p-col-12 p-md-6 p-lg-3'>
                                        <InputSwitch
                                            name='promote'
                                            label={i18next.t('app_storecoins_promote')}
                                            checked={storeCoin.promote}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className='p-col-12 p-md-6 p-lg-3'>
                                        <InputSwitch
                                            name='oneshot'
                                            label={i18next.t('app_storecoins_oneshot')}
                                            checked={storeCoin.oneshot}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <InputMultiSelectInline
                                            name='type'
                                            label={i18next.t('app_storecoins_type')}
                                            onChange={this.handleChange}
                                            value={storeCoin.type}
                                            options={types}
                                            multiple={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-grid'>
                            <div className='p-col-12 p-lg-4 p-lg-offset-8'>
                                <SubmitButton
                                    label={i18next.t('app_submit')}
                                    onClick={() => this.handleSubmit()}
                                    disabled={loading || !dirty}
                                />
                            </div>
                        </div>
                    </Panel>
                </div>
                <Dialog
                    visible={this.state.isDialogVisible}
                    onHide={() => this.handleDialogCancel()}
                    onCancel={() => this.handleDialogCancel()}
                    onSubmit={() => this.handleDialogSubmit()}
                    text={i18next.t('app_dialog_delete_locale')}
                />
            </Page >
        );
    }
}

const mapStateToProps = ({ app, coin }) => {
    return { store: { app, coin } };
};

const mapDispatchToProps = {
    getTypes, initPage, resetStoreCoin, setStoreCoin, fetchPostStoreCoin,
};

const StoreCoinCreatePage = connect(mapStateToProps, mapDispatchToProps)(ConnectedStoreCoinCreatePage);

export default StoreCoinCreatePage;
