import React, { Component } from 'react';
import i18next from 'i18next';
import ArtistBoxes from './components/artist-boxes.component';
import ArtistTable from './components/artist-table.component';
import Config from '../config';
import Dialog from '../shared/components/dialog.component';
import InputText from '../shared/components/form/input-text.component';
import Page from '../shared/components/page.component';
import Paginator from '../shared/components/paginator.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { connect } from 'react-redux';
import { fetchGetArtists, fetchPatchRemove, setArtists, setFilter, toggleSummaryList } from '../store/actions/artist.action';
import { historyHelper as history } from '../core/helpers/history.helper';
import { initPage } from '../store/actions/app.action';
import './artists-page.component.css';

class ConnectedArtistsPage extends Component {
    constructor(props) {
        super(props);
        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
        this.handleOnPageChange = this.handleOnPageChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRemoveOK = this.handleRemoveOK.bind(this);

        this.state = {
            removeDialogVisible: false,
            removeArtist: null,
        };
    }

    componentDidMount() {
        this.props.initPage('/');

        const { filter } = this.props.store.artist;
        if (!filter.deleted) {
            filter.deleted = 'false';
            this.props.setFilter(filter);
        }

        this.props.fetchGetArtists(filter);
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.ADD:
                history.push('/artists/new');
                break;

            case Config.action.MODIFY:
                history.push(`/artists/modify/${data.id}`);
                break;

            default:
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { filter } = this.props.store.artist;

        if (value !== 'all') {
            filter[name] = value;
        } else {
            delete filter[name];
        }

        this.props.setFilter(filter);
        this.props.fetchGetArtists(filter);
    }

    handleOnKeyPress(event) {
        if (event.key === 'Enter') {
            const { name, value } = event.target;
            if (value.length >= 3) {
                this.handleChange(event);
            } else {
                const { filter } = this.props.store.artist;
                if (value) {
                    filter[name] = value;
                } else {
                    if (filter[name] && filter[name].length > 0) {
                        delete filter[name];
                        this.handleChange(event);
                    }
                }
                this.props.setFilter(filter);
            }
        }
    }

    handleOnPageChange(first, rows) {
        const { filter } = this.props.store.artist;
        filter.limit = rows;
        filter.offset = first;
        this.props.setFilter(filter);
        this.props.fetchGetArtists(filter);
    }

    handleRemove(artist) {
        this.setState({ removeDialogVisible: true, removeArtist: artist });
    }

    handleRemoveOK() {
        const { removeArtist } = this.state;
        const { artists } = this.props.store.artist;
        const index = artists.findIndex(e => e.id === removeArtist.id);
        artists.splice(index, 1);
        this.props.setArtists(artists);
        this.props.fetchPatchRemove(removeArtist.id);
        this.setState({ removeDialogVisible: false, removeArtist: null });
    }

    render() {
        const { removeDialogVisible, removeArtist } = this.state;

        const {
            store: { artist: { artists, countAll, filter, loading, summaryList } },
            toggleSummaryList,
        } = this.props;

        return (
            <Page title={i18next.t('app_page_artists')} loading={loading}>
                <div className='o-artists-page-type'>
                    <i className={`fas ${summaryList ? 'fa-th' : 'fa-th-list'}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => toggleSummaryList()}></i>
                </div>
                <div className='p-grid'>
                    <div className='p-col-3'>
                        <InputText
                            name='find'
                            label={i18next.t('app_find')}
                            onKeyPress={this.handleOnKeyPress}
                            placeholder={i18next.t('app_find_min_char')}
                        />
                    </div>
                    {summaryList && (<div className='p-col-6'></div>)}
                    {summaryList && (<div className='p-col-3 o-posts-page-new'>
                        <SubmitButton
                            label={i18next.t('app_new_artist')}
                            onClick={() => this.handleAction(Config.action.ADD, {})}
                        />
                    </div>)}
                </div>
                <div className='p-grid'>
                    <div className='p-col-12 o-artists'>
                        {summaryList && (
                            <ArtistTable
                                artists={artists}
                                onRemove={this.handleRemove}
                                filter={filter}
                            />
                        )}
                        {!summaryList && (
                            <ArtistBoxes
                                artists={artists}
                                handleAction={this.handleAction}
                                handleRemove={this.handleRemove}
                            />
                        )}
                    </div>
                </div>
                <div className='p-grid o-artists-page-footer'>
                    <Paginator
                        countAll={countAll}
                        offset={filter.offset}
                        rows={filter.limit}
                        onPageChange={this.handleOnPageChange}
                    ></Paginator>
                </div>
                <Dialog
                    visible={removeDialogVisible}
                    onCancel={() => this.setState({ removeDialogVisible: false, removeArtist: null })}
                    onHide={() => this.setState({ removeDialogVisible: false, removeArtist: null })}
                    onOk={() => this.handleRemoveOK()}
                >
                    <p>{i18next.t('app_dialog_confirm_artist_remove')}</p>
                    <p><b>{removeArtist ? removeArtist.name : ''}</b></p>
                </Dialog >
            </Page >
        );
    }
}

const mapStateToProps = ({ artist }) => {
    return { store: { artist } };
};

const mapDispatchToProps = {
    initPage,
    fetchGetArtists,
    fetchPatchRemove,
    setArtists,
    setFilter,
    toggleSummaryList,
};

const ArtistsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedArtistsPage);

export default ArtistsPage;
