import React, { useState } from 'react';
import { connect } from 'react-redux';

import AccountMenu from './account-menu.component';
import './account.component.css';

const ConnectedAccount = ({ user }) => {
    const [collapse, setCollapse] = useState(false);

    const handleOnMouseLeave = () => {
        setCollapse(false);
    };

    const stylePicture = {
        backgroundImage: user.account.picture
            ? `url('${user.account.picture}?h=200&w=200')`
            : `url(//unavatar.now.sh/${user.account.email}?fallback=//ui-avatars.com/api/?name=${encodeURIComponent(user.account.firstname + ' ' + user.account.lastname)})`,
    };

    return (
        <div className='o-account'>
            <div className='o-account-avatar'>
                <div className='o-account-picture' style={stylePicture}></div>
                <div className='o-account-icon' onClick={() => setCollapse(!collapse)}>
                    <i className={`fas ${collapse ? 'fa-angle-left' : 'fa-angle-right'}`} />
                </div>
            </div>
            <div className={`o-account-menu ${collapse ? 'active' : ''}`}>
                <AccountMenu onMouseLeave={handleOnMouseLeave} />
            </div>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    return { user };
};

const Account = connect(mapStateToProps, null)(ConnectedAccount);

export default Account;
