import React, { Component } from 'react';
import i18next from 'i18next';
import CguDetail from './cgu-detail.component';
import Config from '../../config';
import Dialog from '../../shared/components/dialog.component';
import InputMultiSelectInline from '../../shared/components/form/input-multiselect-inline.component';
import Panel from '../../shared/components/panel.component';
import SubmitButton from '../../shared/components/form/submit-button.component';

class CguInformation extends Component {
    constructor(props) {
        super(props);
        this.locales = Config.locales.LIST.map(item => {
            return {
                label: i18next.t(item.label),
                value: item.value,
            };
        });

        this.state = {
            localeValues: [],
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            dialogNextLocaleValues: [],
            collapsed: false,
            dirty: false,
        };

        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleChangeDetail = this.handleChangeDetail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDialogOnSubmit = this.handleDialogOnSubmit.bind(this);
        this.handleDialogOnCancel = this.handleDialogOnCancel.bind(this);
    }

    handleChangeLocales(event) {
        let localeValues = this.state.localeValues;
        const value = event.value;
        const eventLength = value.length;

        if (localeValues.length > eventLength && eventLength > 0) {
            const removedLocale = localeValues.filter(l => !value.includes(l))[0];
            this.setState({
                isDialogVisible: true,
                dialogLocaleToRemove: removedLocale,
                dialogNextLocaleValues: value,
            });
        }

        if (eventLength > 0 && localeValues.length <= eventLength) {
            localeValues = value;
            this.setState({ localeValues });
        }
    }

    handleChangeDetail(cgu) {
        this.props.handleChangeDetail(cgu);
    }

    handleSubmit() {
        const cgu = this.props.cgu;
        if (Object.keys(cgu).length !== 0 && Object.values(cgu).every(v => v && v !== '')) {
            this.props.onSubmit(cgu);
        }

        this.setState({ dirty: true });
    }

    handleDialogOnSubmit() {
        const cgu = { ...this.props.cgu };
        delete cgu[this.state.dialogLocaleToRemove];
        this.props.handleChangeDetail(cgu);

        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            localeValues: this.state.dialogNextLocaleValues,
            dialogNextLocaleValues: [],
        });
    }

    handleDialogOnCancel() {
        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            dialogNextLocaleValues: [],
        });
    }

    render() {
        const cgu = this.props.cgu;
        const cguKeys = Object.keys(cgu);
        const styles = this.props.styles;

        if (this.state.localeValues.length === 0 && cguKeys.length !== 0) {
            this.setState({ localeValues: cguKeys });
        }

        const dynamicDetails = this.state.localeValues.map(locale => {
            const id = `cgu_description_${locale}`;
            return (
                <div className='o-cgu-informations-editor' key={locale}>
                    <CguDetail
                        key={id}
                        cgu={cgu}
                        lang={locale}
                        styles={styles}
                        dirty={this.state.dirty}
                        onChange={this.handleChangeDetail}
                    />
                </div>
            );
        });

        return (
            <Panel
                title={i18next.t('app_cgu')}
                styles={styles}
                toggleable={true}
                collapsed={this.state.collapsed}
            >
                <div className='o-cgu-information-locales p-grid'>
                    <div className='p-col-8 p-offset-2'>
                        <InputMultiSelectInline
                            name='locales'
                            value={this.state.localeValues}
                            options={this.locales}
                            onChange={this.handleChangeLocales}
                            styles={styles}
                        />
                    </div>
                    <div className='p-col-8 p-offset-2'>{dynamicDetails}</div>
                </div>
                <div className='o-cgu-information-button p-grid'>
                    <div className='p-col-4 p-offset-6'>
                        <SubmitButton
                            label={i18next.t('app_submit')}
                            styles={styles}
                            onClick={this.handleSubmit}
                            disabled={this.props.disabled}
                        />
                    </div>
                </div>
                <Dialog
                    visible={this.state.isDialogVisible}
                    onHide={() => this.handleDialogOnCancel()}
                    onCancel={() => this.handleDialogOnCancel()}
                    onSubmit={() => this.handleDialogOnSubmit()}
                    text={i18next.t('app_dialog_delete_locale')}
                />
            </Panel>
        );
    }
}

export default CguInformation;
