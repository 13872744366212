/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import App from './app.component';
import Config from './config';
import { setLocale } from './store/actions/app.action';

import './core/services/i18n.service';

class ConnectedIntl extends Component {
    componentDidMount() {
        const { user } = this.props.store;

        let href = window.location.href;
        href = href.replace(/\/#/, '');
        const url = new URL(href);
        const searchParams = new URLSearchParams(url.search);
        const lang = searchParams.get('lang');

        let locale = Config.app.DEFAULT_LOCALE;
        if (lang) {
            locale = lang;
        } else if (user.locale) {
            locale = user.locale;
        } else {
            const navigatorLocale = navigator.language || navigator.userLanguage;
            locale = navigatorLocale.substring(0, 2).toLowerCase();
        }

        this.props.setLocale(locale);
    }

    render() {
        return <App />;
    }
}

const mapStateToProps = ({ app, user }) => {
    return { store: { app, user } };
};

const mapDispatchToProps = {
    setLocale,
};

const Intl = connect(mapStateToProps, mapDispatchToProps)(ConnectedIntl);

export default Intl;
