import { initialState, type } from '../actions/stakeholder.action';

const stakeholder = (state = initialState, action) => {
    switch (action.type) {
        case type.STAKEHOLDER_FETCH_PENDING:
            return { ...state, loading: action.loading };

        case type.STAKEHOLDER_FETCH_GET_SUCCESS:
            return {
                ...state,
                stakeholders: action.stakeholders,
                countAll: action.countAll,
                loading: action.loading,
            };

        case type.STAKEHOLDER_FETCH_PATCH_REMOVE_SUCCESS:
            return { ...state, loading: action.loading };

        case type.STAKEHOLDER_FETCH_POST_SUCCESS:
        case type.STAKEHOLDER_FETCH_GET_ONE_SUCCESS:
            return {
                ...state,
                stakeholder: action.stakeholder,
                loading: action.loading,
            };

        case type.STAKEHOLDER_RESET:
        case type.STAKEHOLDER_SET:
            return {
                ...state,
                stakeholder: action.stakeholder,
            };

        case type.STAKEHOLDER_SET_FILTER:
            return { ...state, filter: action.filter };

        case type.STAKEHOLDER_SET_STAKEHOLDERS:
            return {
                ...state,
                stakeholders: action.stakeholders,
            };

        default:
            return state;
    }
};

export default stakeholder;
