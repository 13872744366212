import React, { Component } from 'react';
import i18next from 'i18next';
import Config from '../config';
import Dialog from '../shared/components/dialog.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import InputText from '../shared/components/form/input-text.component';
import Page from '../shared/components/page.component';
import Paginator from '../shared/components/paginator.component';
import PostsTable from './components/posts-table.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { connect } from 'react-redux';
import {
    fetchGetPosts,
    fetchPatchRemovePost,
    fetchPatchRestorePost,
    setFilter,
    setPosts,
} from '../store/actions/post.action';
import { historyHelper as history } from '../core/helpers/history.helper';
import { initPage } from '../store/actions/app.action';
import './posts-page.component.css';

class ConnectedPostsPage extends Component {
    constructor(props) {
        super(props);

        this.deleted = Config.post.deleted.LIST.map(item => {
            return {
                label: i18next.t(item.label),
                value: item.value,
            };
        });

        this.state = {
            removeDialogVisible: false,
            removePost: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
        this.handleOnPageChange = this.handleOnPageChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRemoveOK = this.handleRemoveOK.bind(this);
    }

    componentDidMount() {
        this.props.initPage('/posts');

        const { filter } = this.props.store.post;
        if (!filter.deleted) {
            filter.deleted = 'false';
            this.props.setFilter(filter);
        }

        this.props.fetchGetPosts(filter);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { filter } = this.props.store.post;

        if (value !== 'all') {
            filter[name] = value;
        } else {
            delete filter[name];
        }

        this.props.setFilter(filter);
        this.props.fetchGetPosts(filter);
    }

    handleOnKeyPress(event) {
        if (event.key === 'Enter') {
            const { name, value } = event.target;
            if (value.length >= 3) {
                this.handleChange(event);
            } else {
                const { filter } = this.props.store.post;
                if (value) {
                    filter[name] = value;
                } else {
                    if (filter[name] && filter[name].length > 0) {
                        delete filter[name];
                        this.handleChange(event);
                    }
                }
                this.props.setFilter(filter);
            }
        }
    }

    handleOnPageChange(first, rows) {
        const { filter } = this.props.store.post;
        filter.limit = rows;
        filter.offset = first;
        this.props.setFilter(filter);
        this.props.fetchGetPosts(filter);
    }

    handleRemove(item) {
        this.setState({ removeDialogVisible: true, removePost: item });
    }

    handleRemoveOK() {
        const { removePost } = this.state;
        const { posts } = this.props.store.post;
        const index = posts.findIndex(e => e.id === removePost.id);
        posts.splice(index, 1);
        this.props.setPosts(posts);

        if (!removePost.deletedAt) {
            this.props.fetchPatchRemovePost(removePost.id);
        } else {
            this.props.fetchPatchRestorePost(removePost.id);
        }

        this.setState({ removeDialogVisible: false, removePost: null });
    }

    render() {
        const { store: { post, user } } = this.props;
        const { removeDialogVisible, removePost } = this.state;

        return (
            <Page title={i18next.t('app_page_posts')} loading={post.loading}>
                <div className='o-posts-page'>
                    <div className='p-grid o-posts-page-header'>
                        <div className='p-col-3'>
                            <InputText
                                name='find'
                                label={i18next.t('app_find')}
                                onKeyPress={this.handleOnKeyPress}
                                placeholder={i18next.t('app_find_min_char')}
                            />
                        </div>
                        <div className='p-col-6 p-d-flex p-ai-end'>
                            <div style={{ marginBottom: '.5rem' }}>
                                <InputMultiSelectInline
                                    name='deleted'
                                    onChange={this.handleChange}
                                    value={post.filter.deleted}
                                    options={this.deleted}
                                    multiple={false}
                                />
                            </div>
                        </div>
                        <div className='p-col-3 o-posts-page-new'>
                            <SubmitButton
                                label={i18next.t('app_new_post')}
                                onClick={() => history.push('/posts/new')}
                            />
                        </div>
                    </div>
                    <div className='p-grid'>
                        <div className='p-col-12 o-posts'>
                            <PostsTable
                                locale={user.account.locale}
                                onRemove={this.handleRemove}
                                posts={post.posts}
                                filter={post.filter}
                            />
                        </div>
                    </div>
                    <div className='p-grid o-posts-page-footer'>
                        <Paginator
                            countAll={post.countAll}
                            offset={post.filter.offset}
                            rows={post.filter.limit}
                            onPageChange={this.handleOnPageChange}
                        ></Paginator>
                    </div>
                </div>
                <Dialog
                    visible={removeDialogVisible}
                    onCancel={() => this.setState({ removeDialogVisible: false, removePost: null })}
                    onHide={() => this.setState({ removeDialogVisible: false, removePost: null })}
                    onOk={() => this.handleRemoveOK()}
                >
                    <p>{removePost && !removePost.deletedAt ? i18next.t('app_dialog_confirm_post_remove') : i18next.t('app_dialog_confirm_post_restore')}</p>
                    <p><b>{removePost ? removePost.title : ''}</b></p>
                </Dialog>
            </Page>
        );
    }
}

const mapStateToProps = ({ post, user }) => {
    return { store: { post, user } };
};

const mapDispatchToProps = {
    initPage,
    fetchGetPosts,
    fetchPatchRemovePost,
    fetchPatchRestorePost,
    setFilter,
    setPosts,
};

const PostsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedPostsPage);

export default PostsPage;
