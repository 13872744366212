import React, { Component } from 'react';
import i18next from 'i18next';
import Config from '../../config';
import Panel from '../../shared/components/panel.component';
import { Editor } from 'primereact/editor';
import './cgu-detail.component.css';

class CguDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let cgu = this.props.cgu;
        cgu = {
            ...cgu,
            [this.props.lang]: event.htmlValue,
        };
        this.props.onChange(cgu);
    }

    render() {
        const header = (
            <span className='ql-formats header-flex'>
                <select className='ql-header' defaultValue="">
                    <option></option>
                    <option value='1'></option>
                    <option value='2'></option>
                </select>
                <button className='ql-bold' aria-label='Bold'></button>
                <button className='ql-italic' aria-label='Italic'></button>
                <button className='ql-underline' aria-label='Underline'></button>
                <button className='ql-list' value='ordered' aria-label='Ordered list'></button>
                <button className='ql-list' value='bullet' aria-label='Bullet list'></button>
                <button className='ql-blockquote' aria-label='Block quote'></button>
                <button className='ql-link' aria-label='Link'></button>
            </span>
        );

        const lang = this.props.lang;
        const value = this.props.cgu ? this.props.cgu[lang] : null;
        return (
            <Panel
                title={i18next.t(Config.locales.LABELS[lang])}
                styles={this.props.styles}
                toggleable={true}
                collapsed={this.state.collapsed}
                kind='detail'
            >
                <div className='o-cgu-detail-label'>
                    {i18next.t('app_cgu_description')}
                </div>
                <Editor
                    id={this.props.key}
                    style={{ height: '15em' }}
                    value={value}
                    headerTemplate={header}
                    formats={[
                        'bold',
                        'italic',
                        'link',
                        'header',
                        'underline',
                        'list',
                        'blockquote',
                    ]}
                    onTextChange={e => this.handleChange(e)}
                />
                {this.props.dirty && (value === '' || !value) && (
                    <div className='o-cgu-detail-error'>*{i18next.t('app_error_regulation_mandatory_description')}</div>
                )}
            </Panel>
        );
    }
}

export default CguDetail;
