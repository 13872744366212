import React from 'react';
import i18next from 'i18next';
import * as Prime from 'primereact/password';

const InputPassword = ({ name, onChange, placeholder, value, onKeyPress }) => {
    const styleContainer = {
        marginBottom: '.5rem',
    };

    return (
        <div style={styleContainer}>
            {/* <Input type="password" placeholder={placeholder} onChange={onChange} /> */}
            <Prime.Password id={name} value={value}
                placeholder={placeholder ? i18next.t(placeholder) : ''}
                onChange={(e) => onChange({ target: { name: e.target.id, value: e.target.value } })}
                onKeyPress={onKeyPress}/>
        </div>
    );
};

export default InputPassword;
