import { initialState, type } from '../actions/notification.action';

const zone = (state = initialState, action) => {
    switch (action.type) {
        case type.NOTIFICATION_FETCH_PENDING:
            return { ...state, loading: action.loading };
        case type.NOTIFICATION_FETCH_POST_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                filter: initialState.filter,
                users: initialState.users,
            };
        case type.NOTIFICATION_INIT_COUNT:
            return { ...state, count: initialState.count };
        case type.NOTIFICATION_INIT_FILTER:
            return { ...state, filter: initialState.filter };
        case type.NOTIFICATION_INIT_USERS:
            return { ...state, users: initialState.users };
        case type.NOTIFICATION_SET_COUNT:
            return { ...state, count: action.count };
        case type.NOTIFICATION_SET_FILTER:
            return { ...state, filter: action.filter };
        case type.NOTIFICATION_SET_USERS:
            return { ...state, users: action.users };
        default:
            return state;
    }
};

export default zone;
