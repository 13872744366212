import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { logout } from '../../store/actions/user.action';
import { setSideMenu, menuItem } from '../../store/actions/app.action';
import { showWarning } from '../../store/actions/message.action';

import './account-menu.component.css';

export class ConnectedAccountMenu extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Change activated item
     *
     * @param {*} item
     */
    handleClick(action) {
        switch (action) {
            case menuItem.ACCOUNT:
                this.props.setSideMenu(menuItem.ACCOUNT);
                history.push('/account');
                break;
            case menuItem.LOGOUT:
                this.props.setSideMenu(menuItem.HOME);
                this.props.logout();
                history.push('/');
                break;
            default:
        }
    }

    render() {
        return (
            <div className='o-accountmenu' onMouseLeave={() => this.props.onMouseLeave()}>
                <ul>
                    <li key={menuItem.ACCOUNT} onClick={() => this.handleClick(menuItem.ACCOUNT)}>
                        {i18next.t('app_my_account')}
                    </li>
                    <li key={menuItem.LOGOUT} onClick={() => this.handleClick(menuItem.LOGOUT)}>
                        {i18next.t('app_logout')}
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    return { store: { app } };
};

const mapDispatchToProps = {
    logout,
    setSideMenu,
    showWarning,
};

const AccountMenu = connect(mapStateToProps, mapDispatchToProps)(ConnectedAccountMenu);

export default AccountMenu;
