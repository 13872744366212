import { httpService as http } from '../../core/services/http.service';
import { showError, showSuccess } from './message.action';

export const type = {
    ADMIN_FETCH_PENDING: 'ADMIN_FETCH_PENDING',
    ADMIN_FETCH_GET_CGU_SUCCESS: 'ADMIN_FETCH_GET_CGU_SUCCESS',
    ADMIN_FETCH_POST_CGU_SUCCESS: 'ADMIN_FETCH_POST_CGU_SUCCESS',
    ADMIN_SET_CGU: 'ADMIN_SET_CGU',
};

export const initialState = {
    loading: false,
    cgu: {},
};

export const fetchPending = (loading) => {
    return { type: type.ADMIN_FETCH_PENDING, loading };
};

export const fetchGetCguSuccess = data => {
    return { type: type.ADMIN_FETCH_GET_CGU_SUCCESS, cgu: data.cgu, loading: false };
};

export const fetchGetCgu = () => {
    return dispatch => {
        dispatch(fetchPending(true));

        http.get('/v1/cgu').then(
            response => {
                dispatch(fetchGetCguSuccess(response.data));
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPostCguSuccess = data => {
    return { type: type.ADMIN_FETCH_POST_CGU_SUCCESS, cgu: data.cgu, loading: false };
};

export const fetchPostCgu = cgu => {
    return dispatch => {
        dispatch(fetchPending(true));

        const data = { cgu };
        http.post('/v1/cgu', data).then(
            response => {
                dispatch(fetchPostCguSuccess(response.data));
                dispatch(showSuccess({ summary: 'app_cgu_updated' }));
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const setCgu = cgu => {
    return { type: type.ADMIN_SET_CGU, cgu };
};
