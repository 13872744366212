import React from 'react';
import './post-type.component.css';

const PostType = ({ type, options }) => {
    let option = { label: '' };
    if (type && options.length > 0) {
        option = options.find(option => option.value === type);
    }

    return (
        <div className='o-post-type'>
            {option.label ? option.label : ''}
        </div>
    );
};

export default PostType;
