import React, { useState } from 'react';
import i18next from 'i18next';

import InputText from '../../shared/components/form/input-text.component';
import Panel from '../../shared/components/panel.component';
import Config from '../../config';

const SectionDetail = ({ lang, section, onChange, onKeyDown, submit }) => {
    const [collapsed] = useState(false);

    const handleChangeTitle = event => {
        section.title = { ...section.title, [lang]: event.target.value };
        onChange(section);
    };

    const title = section.title ? section.title[lang] : null;

    return (
        <Panel
            title={i18next.t(Config.locales.LABELS[lang])}
            kind='detail'
            toggleable={true}
            collapsed={collapsed}
        >
            <InputText
                name='title'
                label={i18next.t('app_section_title')}
                value={title}
                onChange={e => handleChangeTitle(e)}
                onKeyDown={onKeyDown}
            />
            {submit && !title && (
                <div className='error'>* {i18next.t('app_error_section_mandatory_title')}</div>
            )}
        </Panel>
    );
};

export default SectionDetail;
