import Config from '../../config';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { httpService as http } from '../../core/services/http.service';
import { setLocale } from './app.action';
import { showError, showSuccess } from './message.action';

export const type = {
    USER_FETCH_PENDING: 'USER_FETCH_PENDING',
    USER_FETCH_GET_TECH_ACCESS_SUCCESS: 'USER_FETCH_GET_TECH_ACCESS_SUCCESS',
    USER_FETCH_GET_USERS_SUCCESS: 'USER_FETCH_GET_USERS_SUCCESS',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_POST_RECOVERY_SUCCESS: 'USER_POST_RECOVERY_SUCCESS',
    USER_PUT_SUCCESS: 'USER_PUT_SUCCESS',
    USER_PUT_RECOVERY_SUCCESS: 'USER_PUT_RECOVERY_SUCCESS',
    USER_RESET_TECH_ACCESS: 'USER_RESET_TECH_ACCESS',
    USER_SET_FILTER: 'USER_SET_FILTER',
    USER_SET_GDPR: 'USER_SET_GDPR',
    USER_SET_NEW_PASSWORD: 'USER_SET_NEW_PASSWORD',
    USER_VERIFY_SUCCESS: 'USER_VERIFY_SUCCESS',
};

export const initialState = {
    account: {
        id: null,
        techPassword: '',
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        checked: null,
        locale: '',
        active: false,
        picture: '',
        role: '',
        function: '',
        gdpr: false,
        birthdate: null,
    },
    countAll: 0,
    filter: {},
    loading: false,
    newPassword: false,
    users: [],
};

export const fetchPending = loading => {
    return { type: type.USER_FETCH_PENDING, loading };
};

export const fetchGetTechAccessSuccess = data => {
    return {
        type: type.USER_FETCH_GET_TECH_ACCESS_SUCCESS,
        techPassword: data.password,
        loading: false,
    };
};

export const fetchGetTechAccess = user => {
    return dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/users/${user}/techaccess`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetTechAccessSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchGetUsersSuccess = data => {
    return {
        type: type.USER_FETCH_GET_USERS_SUCCESS,
        countAll: data.countAll,
        users: data.users,
        loading: false,
    };
};

export const fetchGetUsers = (options = {}) => {
    return dispatch => {
        dispatch(fetchPending(true));

        let query = '';
        if (Object.keys(options).length > 0) {
            query += options.locale ? `&locale=${options.locale}` : '';
            query += options.find ? `&find=${options.find}` : '';
            query +=
                options.status && options.status !== 'all' && options.status !== 'deleted'
                    ? `&status=${options.status}`
                    : '';
            query += options.status && options.status === 'deleted' ? '&deleted=true' : '';
            query += options.connection ? `&connection=${options.connection.getTime()}` : '';
            query += options.limit ? `&limit=${options.limit}` : '';
            query += options.offset ? `&offset=${options.offset}` : '';
            query = query.replace('&', '?');
        }

        http.get(`/v1/bo/users${query}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetUsersSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchLoginSuccess = ({ user }) => {
    return {
        type: type.USER_LOGIN_SUCCESS,
        loading: false,
        user,
    };
};

export const fetchLogin = (username, password, newPassword) => {
    return dispatch => {
        dispatch(fetchPending(true));

        let data = { username, password };
        if (newPassword) {
            data = { ...data, newPassword };
        }

        http.auth('/v1/login', data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    if (
                        response.error.detail &&
                        response.error.detail.includes('api_new_password_required')
                    ) {
                        dispatch(setNewPassword(true));
                    } else {
                        dispatch(showError(response.error));
                    }
                } else {
                    localStorage.setItem('token', response.data.token);
                    dispatch(fetchLoginSuccess(response.data));
                    dispatch(setLocale(response.data.user.locale));
                    dispatch(setNewPassword(false));
                    if (Config.app.ACCESS.includes(response.data.user.role)) {
                        history.push('/');
                    } else {
                        dispatch(showError({ summary: 'app_error_forbidden' }));
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPostRecoverySuccess = user => {
    return { type: type.USER_POST_RECOVERY_SUCCESS, loading: false };
};

export const fetchPostRecovery = username => {
    return dispatch => {
        dispatch(fetchPending(true));

        http.post('/v1/recovery', { username }).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostRecoverySuccess());
                    dispatch(showSuccess({ summary: 'app_recovery_email_sended' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPutSuccess = ({ user }) => {
    return {
        type: type.USER_PUT_SUCCESS,
        loading: false,
        user,
    };
};

export const fetchPut = user => {
    return dispatch => {
        dispatch(fetchPending(true));

        const { email, firstname, lastname, locale, password, birthdate } = user;
        let account = { email, firstname, lastname, locale, birthdate };

        if (password) {
            account = { ...account, password };
        }

        http.put(`/v1/users/${user.id}`, account).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPutSuccess(response.data));
                    dispatch(setLocale(response.data.user.locale));
                    dispatch(showSuccess({ summary: 'app_user_updated' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPutRecoverySuccess = user => {
    return { type: type.USER_PUT_RECOVERY_SUCCESS, loading: false, user };
};

export const fetchPutRecovery = (token, callback = undefined) => {
    return dispatch => {
        dispatch(fetchPending(true));

        http.put(`/v1/recovery/${token}`, {}).then(
            response => {
                dispatch(fetchPending(false));
                if (!response.ok) {
                    if (callback) {
                        callback(null);
                    }
                } else {
                    if (callback) {
                        callback(response.data.user);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                console.log(error);
                if (callback) {
                    callback(null);
                }
            }
        );
    };
};

export const fetchVerifySuccess = user => {
    return { type: type.USER_VERIFY_SUCCESS, loading: false, user };
};

export const fetchVerify = token => {
    return dispatch => {
        dispatch(fetchPending(true));

        http.post(`/v1/verify/${token}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchVerifySuccess(response.data.user));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const logout = () => {
    return dispatch => {
        // remove informations from local storage to log user out
        localStorage.removeItem('persist:store');
        localStorage.removeItem('token');
        history.push('/');
    };
};

export const resetTechAccess = () => {
    return {
        type: type.USER_RESET_TECH_ACCESS,
        techPassword: initialState.techPassword,
    };
};

export const setFilter = filter => {
    return { type: type.USER_SET_FILTER, filter };
};

export const setGDPR = () => {
    return { type: type.USER_SET_GDPR };
};

export const setNewPassword = newPassword => {
    return { type: type.USER_SET_NEW_PASSWORD, newPassword };
};
