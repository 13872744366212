import { initialState, type } from '../actions/banner.action';

const banner = (state = initialState, action) => {
    switch (action.type) {
        case type.BANNER_FETCH_PENDING:
            return { ...state, loading: action.loading };

        case type.BANNER_FETCH_GET_SUCCESS:
            return {
                ...state,
                countAll: action.countAll,
                banners: action.banners,
                loading: action.loading,
            };

        case type.BANNER_FETCH_GET_CONTENT_SUCCESS:
            return {
                ...state,
                content: action.content,
                loading: action.loading,
            };

        case type.BANNER_FETCH_GET_ONE_SUCCESS:
            return {
                ...state,
                banner: action.banner,
                picture: action.picture,
                loading: action.loading,
            };

        case type.BANNER_FETCH_POST_SUCCESS:
        case type.BANNER_FETCH_PUT_SUCCESS:
            return {
                ...state,
                banner: action.banner,
                picture: action.picture,
                pictureFile: null,
                loading: action.loading,
            };

        case type.BANNER_FETCH_POST_REORDER_SUCCESS:
            return {
                ...state,
                banners: action.banners,
                loading: action.loading,
            };

        case type.BANNER_RESET:
            return {
                ...state,
                banner: { ...initialState.banner },
                content: { ...initialState.content },
                picture: null,
                pictureFile: null,
            };

        case type.BANNER_SET:
            return {
                ...state,
                banner: action.banner,
            };

        case type.BANNER_SET_BANNERS:
            return {
                ...state,
                banners: action.banners,
            };

        case type.BANNER_SET_CONTENT:
            return {
                ...state,
                content: action.content,
            };

        case type.BANNER_SET_PICTURE:
            return {
                ...state,
                picture: action.picture ? URL.createObjectURL(action.picture) : null,
                pictureFile: action.picture,
            };

        default:
            return state;
    }
};

export default banner;
