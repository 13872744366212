import React from 'react';
import i18next from 'i18next';
import ActionIcon from '../../shared/components/action-icon.component';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const BannerTable = ({ banners, onRemove, onRowReorder }) => {
    const columns = [
        { field: 'position', header: i18next.t('app_banners_position') },
        { field: 'title', header: i18next.t('app_banners_title') },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="p-d-flex p-jc-between">
                <ActionIcon
                    icon="fas fa-edit"
                    label={i18next.t('app_tip_edit')}
                    onClick={() => history.push(`/banners/modify/${rowData.id}`)}>
                </ActionIcon>
                <ActionIcon
                    icon="fas fa-archive"
                    label={i18next.t('app_tip_archive')}
                    onClick={() => onRemove(rowData)}
                    style={{ marginLeft: '.5rem' }}>
                </ActionIcon>
            </div>
        );
    };

    const dynamicColumns = columns.map((col, i) => {
        let style = { textAlign: 'left' };
        style = col.field === 'position' ? { textAlign: 'center', width: '6rem' } : style;
        return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={style} />;
    });

    const handleRowReorder = (e) => {
        onRowReorder(e.value);
    };

    return (
        <div className='o-banner-table'>
            <DataTable value={banners} reorderableColumns onRowReorder={handleRowReorder}>
                <Column rowReorder style={{ width: '3rem', cursor: 'grab' }} />
                {dynamicColumns}
                <Column className="o-banner-table-action" style={{ textAlign: 'center', width: '4.4rem' }} body={actionBodyTemplate}></Column>
            </DataTable>
        </div>
    );
};

export default BannerTable;
