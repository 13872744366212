import React from 'react';
import Config from '../../config';
import { Paginator as PPaginator } from 'primereact/paginator';
import './paginator.component.css';

const Paginator = ({ countAll, offset, limit, onPageChange }) => {
    return (
        <div className='o-paginator'>
            <PPaginator
                totalRecords={countAll}
                first={offset || Config.paginator.DEFAULT_FIRST}
                rows={limit || Config.paginator.DEFAULT_ROWS
                }
                onPageChange={e => onPageChange(e.first, e.rows)}
            ></PPaginator>
        </div>
    );
};

export default Paginator;
