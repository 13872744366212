import React, { Component } from 'react';
import i18next from 'i18next';
import slugify from 'slugify';
import AddStakeholder from './components/add-stakeholder.component';
import ButtonIcon from '../shared/components/form/button-icon.component';
import ButtonRounded from '../shared/components/form/button-rounded.component';
import Config from '../config';
import Dialog from '../shared/components/dialog.component';
import DialogOnAir from './components/dialog-on-air.component';
import ImageUpload from '../shared/components/form/image-upload.component';
import InputCompletionText from '../shared/components/form/input-completion-text.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import InputNumber from '../shared/components/form/input-number.component';
import InputMultiSelect from '../shared/components/form/input-multiselect.component';
import InputText from '../shared/components/form/input-text.component';
import InputFile from '../shared/components/form/input-file.component';
import InputSwitch from '../shared/components/form/input-switch.component';
import InputDatetime from '../shared/components/form/input-datetime2.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import PostDetail from './components/post-detail.component';
import PostStakeholderList from './components/post-stakeholder-list.component';
import PostType from './components/post-type.component';
import ReactPlayer from 'react-player';
import StaticText from '../shared/components/form/static-text.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import _ from 'lodash';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { showError } from '../store/actions/message.action';
import { Steps } from 'primereact/steps';
import {
    fetchGetArtists,
} from '../store/actions/artist.action';
import {
    fetchGetLiveLink,
    fetchGetLiveSetup,
    fetchGetPost,
    fetchGetSections,
    fetchGetStakeholders,
    fetchPostAddSection,
    fetchPostLive2VOD,
    fetchPostOnAir,
    fetchPostRemoveSection,
    fetchPostStakeholders,
    fetchPostVideo,
    fetchPutPost,
    fetchRemoveStakeholder,
    getTypes,
    getUploadSteps,
    resetPost,
    setLivePicture,
    setPicture,
    setPost,
    setPostStakeholders,
    setStakeholdersFilter,
} from '../store/actions/post.action';
import {
    fetchGetStakeholders as fetchGetAllStakeholders,
} from '../store/actions/stakeholder.action';
import { fetchGetTags } from '../store/actions/tag.action';
import './post-modify-page.component.css';

class ConnectedPostModifyPage extends Component {
    constructor(props) {
        super(props);

        this.locales = Config.locales.LIST.map(item => {
            return {
                label: i18next.t(item.label),
                value: item.value,
            };
        });

        this.state = {
            addStakeholderDialog: false,
            artists: [],
            dirty: false,
            displayConfirmUploadDialog: false,
            findStakeholders: [],
            isDialogVisible: false,
            localeValues: [],
            mediaError: false,
            notifyOnAir: false,
            orientationOnAir: 'landscape',
            postStakeholders: [],
            showArtists: false,
            showOnAir: false,
            showOnAirErrors: [],
            showStakeholders: false,
            showTags: false,
            submit: false,
            types: [],
            uploadSteps: [],
            videoToUpload: [],
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleAddArtist = this.handleAddArtist.bind(this);
        this.handleAddTag = this.handleAddTag.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeAbout = this.handleChangeAbout.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleChangeMedia = this.handleChangeMedia.bind(this);
        this.handleLivePictureOnChange = this.handleLivePictureOnChange.bind(this);
        this.handleLivePictureOnClick = this.handleLivePictureOnClick.bind(this);
        this.handleLiveSetup = this.handleLiveSetup.bind(this);
        this.handleLocaleDialogCancel = this.handleLocaleDialogCancel.bind(this);
        this.handleLocaleDialogSubmit = this.handleLocaleDialogSubmit.bind(this);
        this.handleMediaRefresh = this.handleMediaRefresh.bind(this);
        this.handleMediaUpload = this.handleMediaUpload.bind(this);
        this.handleOnAir = this.handleOnAir.bind(this);
        this.handlePictureOnChange = this.handlePictureOnChange.bind(this);
        this.handlePictureOnClick = this.handlePictureOnClick.bind(this);
        this.handleRemoveArtist = this.handleRemoveArtist.bind(this);
        this.handleRemoveTag = this.handleRemoveTag.bind(this);
        this.handleSearchArtists = this.handleSearchArtists.bind(this);
        this.handleSearchStakeholders = this.handleSearchStakeholders.bind(this);
        this.handleSearchTags = this.handleSearchTags.bind(this);
        this.handleStakeholderAdd = this.handleStakeholderAdd.bind(this);
        this.handleStakeholderChange = this.handleStakeholderChange.bind(this);
        this.handleStakeholderRemove = this.handleStakeholderRemove.bind(this);
        this.handleStakeholderSearch = this.handleStakeholderSearch.bind(this);
        this.handleStakeholderSubmit = this.handleStakeholderSubmit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateSections = this.updateSections.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;

        const types = this.props.getTypes().map(type => { return { ...type, label: i18next.t(type.label) }; });
        const uploadSteps = this.props.getUploadSteps().map(step => { return { label: i18next.t(step.label) }; });

        this.props.fetchGetSections();

        await this.props.fetchGetPost(id, {}, post => {
            if (post.about && typeof post.about === 'object') {
                const localeValues = Object.keys(post.about);
                this.setState({ localeValues });
            }

            if (post.type === 'live') {
                this.props.fetchGetLiveLink(id);
            }
        });

        await this.props.fetchGetStakeholders(id, {}, stakeholders => {
            this.props.setPostStakeholders(stakeholders);
        });

        this.props.fetchGetArtists({}, artists => {
            this.setState({ artists: artists.map(artist => { return { label: artist.name, value: artist.id }; }) });
        });

        this.setState({ types, uploadSteps });
    }

    componentWillUnmount() {
        this.props.resetPost();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    }

    handleAddArtist(artist) {
        const post = { ...this.props.store.post.post };
        const artists = post.artists;
        artists.push(artist);
        post.artists = artists;
        this.props.setPost(post);
        this.setState({ showArtists: false, dirty: true });
    }

    handleAddTag(tag) {
        const post = { ...this.props.store.post.post };
        const tags = post.tags;
        if (tag.id === null) {
            const slugged = slugify(tag.name, {
                lower: true,
                strict: true,
                remove: /[*+~.()'"!:@]/g,
            });
            tags.push({ name: slugged });
        } else {
            tags.push(tag);
        }
        post.tags = tags;
        this.props.setPost(post);
        this.setState({ showTags: false, dirty: true });
    }

    async updateSections(selection, post) {
        const olds = post.sections.map(section => { return section.id; });
        const news = _.difference(selection, olds);
        const remove = _.difference(olds, selection);

        if (remove.length !== 0) {
            await Promise.all(remove.map(async item => {
                await this.props.fetchPostRemoveSection(post.id, item);
            }));
        }

        if (news.length !== 0) {
            await Promise.all(news.map(async item => {
                await this.props.fetchPostAddSection(post.id, item);
            }));
        }
    }

    async handleChange(event) {
        const { name, value } = event.target;
        const post = { ...this.props.store.post.post };

        if (name === 'sections') {
            await this.updateSections(value, post);
        } else if (name === 'available' && value === true && post.type !== 'news' &&
            (!post.media ||
                (post.type === 'video' && (!post.media.video || post.media.video.step < 2))
            )) {
            this.props.showError({ summary: 'app_error_media_not_complete' });
            this.setState({ mediaError: true });
        } else {
            post[name] = value;
            this.props.setPost(post);
            this.setState({ dirty: true });
        }
    }

    handleChangeAbout(about) {
        const post = { ...this.props.store.post.post };
        post.about = about;
        this.props.setPost(post);
        this.setState({ dirty: true });
    }

    handleChangeLocales(event) {
        let localeValues = this.state.localeValues;
        const value = event.value;
        const eventLength = value.length;

        if (localeValues.length > eventLength && eventLength > 0) {
            const removedLocale = localeValues.filter(l => !value.includes(l))[0];
            this.setState({
                isDialogVisible: true,
                dialogLocaleToRemove: removedLocale,
                dialogNextLocaleValues: value,
            });
        }

        if (eventLength > 0 && localeValues.length <= eventLength) {
            localeValues = value;
            this.setState({ localeValues });
        }
    }

    handleChangeMedia(event) {
        const { name, value } = event.target;
        const post = { ...this.props.store.post.post };

        if (name === 'onAir' && value === true &&
            (!post.media || (post.type === 'live' && !post.media.live)
            )) {
            this.props.showError({ summary: 'app_error_media_not_complete' });
            this.setState({ mediaError: true });
        } else {
            post.media[post.type][name] = value;
            this.props.setPost(post);
            this.setState({ dirty: true });
        }
    }

    handleLivePictureOnChange(files) {
        const file = files[0];
        if (file) {
            this.props.setLivePicture(file);
        }
        this.setState({ dirty: true });
    }

    handleLivePictureOnClick() {
        this.props.setLivePicture(null);
        this.setState({ dirty: true });
    }

    handleLiveSetup(id) {
        this.props.fetchGetLiveSetup(id);
    }

    handleLive2VOD() {
        const { post } = this.props.store.post;
        this.props.fetchPostLive2VOD(post.id);
    }

    handleLocaleDialogCancel() {
        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            dialogNextLocaleValues: [],
        });
    }

    handleLocaleDialogSubmit() {
        const about = { ...this.props.store.post.post.about };
        delete about[this.state.dialogLocaleToRemove];
        this.handleChangeAbout(about);

        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            localeValues: this.state.dialogNextLocaleValues,
            dialogNextLocaleValues: [],
        });
    }

    handleMediaRefresh() {
        const { post } = this.props.store.post;
        this.props.fetchGetPost(post.id);
    }

    handleMediaUpload() {
        const { videoToUpload } = this.state;
        const { post } = this.props.store.post;
        this.props.fetchPostVideo(post, videoToUpload);
        this.setState({ displayConfirmUploadDialog: false, videoToUpload: [] });
    }

    handleOnAir(onAir = false) {
        if (!onAir) {
            this.setState({ showOnAir: !this.state.showOnAir, notifyOnAir: false });
        } else {
            const { post } = this.props.store.post;
            this.props.fetchPostOnAir(post, this.state.notifyOnAir, this.state.orientationOnAir, error => {
                if (error) {
                    this.setState({ showOnAirErrors: error.detail });
                } else {
                    this.setState({ showOnAir: false, showOnAirErrors: [], notifyOnAir: false });
                }
            });
        }
    }

    handlePictureOnChange(files) {
        const file = files[0];
        if (file) {
            this.props.setPicture(file);
        }
        this.setState({ dirty: true });
    }

    handlePictureOnClick() {
        this.props.setPicture(null);
        this.setState({ dirty: true });
    }

    handleRemoveArtist(index) {
        const { artist } = this.props.store.artist;
        const { post } = this.props.store.post;
        const artists = post.artists;
        if (artists[index].id !== artist.id) {
            artists.splice(index, 1);
            post.artists = artists;
            this.props.setPost(post);
            this.setState({ dirty: true });
        }
    }

    handleRemoveTag(index) {
        const post = { ...this.props.store.post.post };
        const tags = post.tags;
        tags.splice(index, 1);
        post.tags = tags;
        this.props.setPost(post);
        this.setState({ dirty: true });
    }

    handleSearchStakeholders(event) {
        if (event.key === 'Enter') {
            const { name, value } = event.target;
            const { post, stakeholdersFilter } = this.props.store.post;

            const oldValue = stakeholdersFilter[name] ? stakeholdersFilter[name] : '';

            if (value) {
                stakeholdersFilter[name] = value;
            } else {
                if (stakeholdersFilter[name] && stakeholdersFilter[name].length > 0) {
                    delete stakeholdersFilter[name];
                }
            }
            this.props.setStakeholdersFilter(stakeholdersFilter);

            if (oldValue !== value && (value.length >= 3 || value.length === 0)) {
                this.props.fetchGetStakeholders(post.id, stakeholdersFilter);
            }
        }
    }

    handleSearchTags(query) {
        this.setState({ showTags: true });
        this.props.fetchGetTags(query);
    }

    async handleSearchArtists(query) {
        const post = { ...this.props.store.post.post };
        const included = id => {
            return post.artists.filter(artist => artist.id === id).length !== 0;
        };

        await this.props.fetchGetArtists({ find: query }, artists => {
            if (artists === null) {
                this.setState({ showArtists: false });
                return;
            }
            this.setState({ showArtists: true, artists: artists.filter(artist => !included(artist.id)) });
        });
    }

    handleStakeholderAdd(stakeholder) {
        const { findStakeholders, postStakeholders } = this.state;
        const index = findStakeholders.findIndex(e => e.id === stakeholder.id);
        postStakeholders.push({ ...findStakeholders[index], share: findStakeholders[index].share });
        this.setState({ showStakeholders: false, findStakeholders: [], postStakeholders });
    }

    handleStakeholderChange(stakeholders) {
        this.setState({ postStakeholders: stakeholders });
    }

    handleStakeholderRemove(stakeholder) {
        const { post, stakeholders } = this.props.store.post;
        this.props.fetchRemoveStakeholder(post.id, stakeholder, stakeholders);
    }

    async handleStakeholderSearch(query) {
        const storeStakeholders = this.props.store.post.postStakeholders;
        const postStakeholders = this.state.postStakeholders;

        await this.props.fetchGetAllStakeholders({ find: query }, stakeholders => {
            if (!stakeholders) {
                this.setState({ showStakeholders: false, findStakeholders: [] });
                return;
            }

            if (!postStakeholders) {
                this.setState({ showStakeholders: true, findStakeholders: stakeholders });
                return;
            }

            const findStakeholders = stakeholders.filter(stakeholder => {
                const postIndex = postStakeholders.findIndex(e => e.id === stakeholder.id);
                const storeIndex = storeStakeholders.findIndex(e => e.id === stakeholder.id);
                return (postIndex < 0 && storeIndex < 0);
            });

            this.setState({ showStakeholders: true, findStakeholders });
        });
    }

    handleStakeholderSubmit() {
        const { post } = this.props.store.post;
        const postStakeholders = this.state.postStakeholders;
        this.props.fetchPostStakeholders(post.id, postStakeholders, stakeholders => {
            this.props.setPostStakeholders(stakeholders);
        });

        this.setState({
            addStakeholderDialog: false,
            dirty: true,
            showStakeholders: false,
            findStakeholders: [],
            postStakeholders: [],
        });
    }

    handleSubmit() {
        const { post, pictureFile, livePictureFile } = this.props.store.post;
        if (post.title.trim().replace(/  +/g, ' ').length > 0 &&
            post.artists.length > 0 &&
            (!post.purchase || (post.purchase && post.purchaseCoins > 0))
        ) {
            this.props.fetchPutPost(post, pictureFile, livePictureFile, () => {
                this.setState({ dirty: false });
            });
        }
        this.setState({ dirty: true });
    }

    render() {
        const {
            addStakeholderDialog,
            artists,
            dirty,
            displayConfirmUploadDialog,
            findStakeholders,
            isDialogVisible,
            localeValues,
            mediaError,
            notifyOnAir,
            orientationOnAir,
            postStakeholders,
            showArtists,
            showOnAir,
            showOnAirErrors,
            showStakeholders,
            showTags,
            submit,
            types,
            uploadSteps,
            videoToUpload,
        } = this.state;

        const {
            store: {
                post: { link, loading, loadingOnAir, livePicture, livePictureFile, participationRate, picture, pictureFile, post, sectionOptions, stakeholders },
                tag: { tags },
            },
        } = this.props;

        let livePicturePath = livePicture;
        if (livePicturePath && !livePicturePath.includes('blob:')) {
            livePicturePath = `${livePicturePath}?method=resize&h=250`;
        }

        let picturePath = picture;
        if (picturePath && !picturePath.includes('blob:')) {
            picturePath = `${picturePath}?method=resize&h=250`;
        }

        const aboutKeys = typeof post.about === 'object' ? Object.keys(post.about) : ['fr'];
        if (localeValues.length === 0 && aboutKeys.length !== 0) {
            this.setState({ localeValues: aboutKeys });
        }

        let { media } = post;
        if (post.type === 'video') {
            if (!media) {
                media = { video: { reference: '', step: 0 } };
            } else if (!media.video) {
                media.video = { reference: '', step: 0 };
            }
        } else if (!media && post.type === 'live') {
            media = { live: { channel: null, onAir: false } };
        }

        let liveReplay = false;
        if (media && media.live && media.live.hls && media.live.hls[720]) {
            liveReplay = true;
        }

        const postSections = post.sections.map(section => {
            return section.id;
        });

        let postType = { label: '' };
        if (post.type && types.length > 0) {
            postType = types.find(type => type.value === post.type);
        }

        return (
            <Page
                title={`${i18next.t(postType.label)} - ${post.title}`}
                onClick={() => this.handleAction(Config.action.BACK, null)}
                loading={loading}
            >
                <div className='o-post-modify-page'>
                    <Panel title={i18next.t('app_general_information')} toggleable={true}>
                        <div className='o-artist-modify'>
                            <div className='p-grid p-dir-rev'>
                                <div className='p-col-12 p-xl-4'>
                                    <div className='p-grid p-align-center'>
                                        <div className='p-col-12 p-md-6 p-xl-12'>
                                            <ImageUpload
                                                name='picture'
                                                label={i18next.t('app_post_picture')}
                                                onClick={this.handlePictureOnClick}
                                                onChange={this.handlePictureOnChange}
                                                image={picturePath}
                                            />
                                            {dirty && !pictureFile && !picture && (
                                                <div className='error'>*{i18next.t('app_error_artist_mandatory_picture')}</div>
                                            )}
                                        </div>
                                        {post.type === 'live' && (
                                            <div className='p-offset-0 p-col-12 p-md-offset-1 p-md-4 p-xl-offset-2 p-xl-8'>
                                                <ImageUpload
                                                    name='livePicture'
                                                    label={i18next.t('app_post_live_picture')}
                                                    onClick={this.handleLivePictureOnClick}
                                                    onChange={this.handleLivePictureOnChange}
                                                    image={livePicturePath}
                                                    kind="live-picture"
                                                    ratio={0.6932}
                                                />
                                                {dirty && !livePictureFile && !livePicture && (
                                                    <div className='error'>*{i18next.t('app_error_artist_mandatory_picture')}</div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='p-col-12 p-xl-8'>
                                    <div className='p-grid'>
                                        <div className='p-col-12 p-md-4 p-xl-3 p-d-flex p-jc-center p-jc-md-start'>
                                            <PostType type={post.type} options={types} />
                                        </div>
                                        <div className=' o-post-modify-views p-col-6 p-md-4 p-xl-3'>
                                            <span className='value'>{post.views || '0'}</span>
                                            <span>{post.views > 1 ? i18next.t('app_post_views') : i18next.t('app_post_view')}</span>
                                        </div>
                                        {post.type === 'live' && (
                                            <div className=' o-post-modify-views p-col-6 p-md-4 p-xl-3'>
                                                <span className='value'>{post.liveViews || '0'}</span>
                                                <span>{post.views > 1 ? i18next.t('app_post_live_views') : i18next.t('app_post_live_view')}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className='p-grid'>
                                        <div className='p-col-12'>
                                            <InputText
                                                name='title'
                                                label={i18next.t('app_post_title')}
                                                value={post.title}
                                                onChange={this.handleChange}
                                                onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                                            />
                                            {submit && !post.title && (
                                                <div className='error'>*{i18next.t('app_error_artist_mandatory_title')}</div>
                                            )}

                                            <InputCompletionText
                                                name='artists'
                                                label={i18next.t('app_post_artists')}
                                                onSearch={this.handleSearchArtists}
                                                onCancel={() => this.setState({ showArtists: false })}
                                                onAdd={this.handleAddArtist}
                                                onRemove={this.handleRemoveArtist}
                                                options={showArtists ? artists : []}
                                                values={post.artists}
                                                editable={false}
                                            />
                                            <InputMultiSelect
                                                name='sections'
                                                label={i18next.t('app_sections')}
                                                options={sectionOptions}
                                                values={postSections}
                                                onChange={this.handleChange}
                                            />
                                            <InputCompletionText
                                                name='tags'
                                                label={i18next.t('app_post_tags')}
                                                onSearch={this.handleSearchTags}
                                                onCancel={() => this.setState({ showTags: false })}
                                                onAdd={this.handleAddTag}
                                                onRemove={this.handleRemoveTag}
                                                options={showTags ? tags.map(tag => { return { id: tag, name: tag }; }) : []}
                                                values={post.tags}
                                            />
                                        </div>
                                    </div>
                                    <div className='p-grid'>
                                        <div className='p-col-12'>
                                            <div className='p-grid'>
                                                <div className='p-col-4'>
                                                    <InputSwitch
                                                        name='activeComments'
                                                        label={i18next.t('app_post_active_comments')}
                                                        checked={post.activeComments}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                {/*
                                                <div className='p-col-4'>
                                                    <InputSwitch
                                                        checked={post.promote}
                                                        label={i18next.t('app_post_promote')}
                                                        name='promote'
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                */}
                                            </div>
                                        </div>
                                    </div>
                                    {post.type === 'video' && (<div className='p-grid'>
                                        <div className='p-col-12'>
                                            <div className='p-grid'>
                                                <div className='p-col-8'>
                                                    <StaticText
                                                        name='reference'
                                                        label={i18next.t('app_post_reference')}
                                                        value={media.video.reference}
                                                    />
                                                </div>
                                                <div className='p-col-4'>
                                                    <InputDatetime
                                                        label={i18next.t('app_post_on_air_at')}
                                                        name="onAirAt"
                                                        onChange={this.handleChangeMedia}
                                                        value={media.video ? media.video.onAirAt : null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-col-12'>
                                            <div className='p-grid'>
                                                {media.video.step !== 1 && (<div className='p-col-4 o-media-upload'>
                                                    <InputFile
                                                        accept="video/mp4"
                                                        className={media.video.step !== 0 ? 'secondary' : ''}
                                                        disabled={loading}
                                                        name='video'
                                                        chooseLabel={i18next.t('app_post_type_video')}
                                                        // chooseLabel={fileReaded === 0 ? i18next.t('app_post_type_video') : `${fileReaded} %`}
                                                        uploadHandler={(e) => this.setState({ videoToUpload: e.files[0], displayConfirmUploadDialog: true })}
                                                    />
                                                </div>)}
                                                {media.video.step === 1 && (<div className='p-col-4 o-media-refresh'>
                                                    <ButtonIcon icon='fas fa-sync-alt' onClick={() => this.handleMediaRefresh()} />
                                                </div>)}
                                                <div className='p-col-8 o-steps'>
                                                    <Steps model={uploadSteps} activeIndex={media.video ? media.video.step : 0} />
                                                    {(mediaError && media.video && (media.video.step < 2)) && (
                                                        <div className='error'>*{i18next.t('app_error_media_video_not_ready')}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {media.video.step === 2 && (<div className='p-col-12'>
                                            <div className='p-grid'>
                                                <div className='p-col-8 p-offset-4 o-steps'>
                                                    <ReactPlayer url={`${media.url}${media.video.hls[360]}`} controls={true}
                                                        width="56vh" height="31.5vh" playsinline={true} />
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>)}
                                    {post.type === 'live' && (<div className='p-grid'>
                                        <div className='p-col-12'>
                                            <div className='p-grid'>
                                                <div className='p-col-6'>
                                                    <StaticText
                                                        name='channel'
                                                        label={i18next.t('app_post_channel')}
                                                        value={media.live ? media.live.channel : ''}
                                                    />
                                                </div>
                                                {/*
                                                <div className='p-col-3 o-live-setup'>
                                                    <ButtonIcon icon='fas fa-download'
                                                        kind='secondary'
                                                        onClick={() => this.handleLiveSetup(post.id)}
                                                        disabled={loading}
                                                    />
                                                </div>
                                                */}
                                            </div>
                                        </div>
                                        <div className='p-col-12'>
                                            <StaticText
                                                name='link'
                                                label={i18next.t('app_post_live_link')}
                                                value={link}
                                            />
                                        </div>
                                        <div className='p-col-12'>
                                            <div className='p-grid'>
                                                <div className='p-col-4'>
                                                    <InputDatetime
                                                        label={i18next.t('app_post_on_air_at')}
                                                        name="onAirAt"
                                                        onChange={this.handleChangeMedia}
                                                        value={media.live ? media.live.onAirAt : null}
                                                    />
                                                </div>
                                                <div className='p-col-3'>
                                                    <InputSwitch
                                                        name='onAir'
                                                        label={i18next.t('app_post_on_air')}
                                                        checked={media.live ? media.live.onAir : false}
                                                        onChange={() => this.handleOnAir(false)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {liveReplay && (
                                            <div className='p-col-12'>
                                                <div>
                                                    <ReactPlayer url={`${media.url}${media.live.hls[720]}`} controls={true}
                                                        width="56vh" height="31.5vh" playsinline={true} />
                                                </div>
                                                <div>
                                                    <ButtonRounded
                                                        className='secondary'
                                                        label={i18next.t('app_create_vod')}
                                                        onClick={() => this.handleLive2VOD()}
                                                        disabled={loading}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>)}
                                    <div className='p-grid'>
                                        <div className='p-col-12'>
                                            <InputMultiSelectInline
                                                label={i18next.t('app_post_about')}
                                                name='locales'
                                                options={this.locales}
                                                onChange={this.handleChangeLocales}
                                                value={localeValues}
                                            />

                                            {this.state.localeValues.map(locale => {
                                                const id = `post_about_${locale}`;
                                                return (
                                                    <PostDetail
                                                        key={id}
                                                        lang={locale}
                                                        about={post.about}
                                                        onChange={this.handleChangeAbout}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                    {post.type !== 'news' && (<div className='p-grid'>
                                        <div className='p-col-12'>
                                            <div className='p-grid'>
                                                <div className='p-col-4'>
                                                    <InputSwitch
                                                        name='purchase'
                                                        label={i18next.t('app_post_purchase')}
                                                        checked={post.purchase}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className='p-col-4'>
                                                    <InputNumber
                                                        disabled={!post.purchase}
                                                        label={i18next.t('app_post_coins')}
                                                        min={0}
                                                        name='purchaseCoins'
                                                        onChange={this.handleChange}
                                                        value={post.purchaseCoins}
                                                    />
                                                    {(dirty && post.purchase && post.purchaseCoins === '0') && (
                                                        <div className='error'>*{i18next.t('api_error_post_no_purchase_coin')}</div>
                                                    )}
                                                </div>
                                                <div className='p-col-4'>
                                                    <InputNumber
                                                        disabled={!post.purchase}
                                                        label={i18next.t('app_post_free_time')}
                                                        min={0}
                                                        name='purchaseFreeTime'
                                                        onChange={this.handleChange}
                                                        value={post.purchaseFreeTime}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                                    <div className='p-grid'>
                                        <div className='p-col-12'>
                                            <div className='p-grid'>
                                                <div className='p-col-4'>
                                                    <InputSwitch
                                                        name='available'
                                                        label={i18next.t('app_post_available')}
                                                        checked={post.available}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className='p-col-4'>
                                                    <InputDatetime
                                                        disabled={!post.available}
                                                        label={i18next.t('app_post_begins_at')}
                                                        name="beginsAt"
                                                        onChange={this.handleChange}
                                                        value={post.beginsAt}
                                                    />
                                                </div>
                                                <div className='p-col-4'>
                                                    <InputDatetime
                                                        disabled={!post.available}
                                                        label={i18next.t('app_post_finished_at')}
                                                        name="finishedAt"
                                                        onChange={this.handleChange}
                                                        value={post.finishedAt}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-col-8'></div>
                                <div className='p-col-4'>
                                    <SubmitButton
                                        label={i18next.t('app_submit')}
                                        onClick={() => this.handleSubmit()}
                                        disabled={loading || !dirty}
                                    />
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <PostStakeholderList
                        add={() => this.setState({ addStakeholderDialog: true })}
                        post={post}
                        remove={this.handleStakeholderRemove}
                        searchStakeholders={this.handleSearchStakeholders}
                        stakeholders={stakeholders}
                    />
                    <Dialog
                        visible={displayConfirmUploadDialog}
                        onCancel={() => this.setState({ displayConfirmUploadDialog: false, videoToUpload: [] })}
                        onHide={() => this.setState({ displayConfirmUploadDialog: false, videoToUpload: [] })}
                        onOk={() => this.handleMediaUpload()}
                    >
                        {i18next.t('app_dialog_confirm_video_upload')}
                        <div className='o-dialog-upload-video-name'>{videoToUpload.name}</div>
                    </Dialog>
                    <Dialog
                        visible={isDialogVisible}
                        onHide={() => this.handleLocaleDialogCancel()}
                        onCancel={() => this.handleLocaleDialogCancel()}
                        onSubmit={() => this.handleLocaleDialogSubmit()}
                        text={i18next.t('app_dialog_delete_locale')}
                    />
                    <Dialog
                        visible={addStakeholderDialog}
                        onCancel={() => this.setState({
                            addStakeholderDialog: false,
                            showStakeholders: false,
                            findStakeholders: [],
                            postStakeholders: [],
                        })}
                        onHide={() => this.setState({
                            addStakeholderDialog: false,
                            showStakeholders: false,
                            findStakeholders: [],
                            postStakeholders: [],
                        })}
                        onSubmit={() => this.handleStakeholderSubmit()}
                        onSubmitLabel='app_add'
                    >
                        <AddStakeholder
                            stakeholders={postStakeholders}
                            onAdd={this.handleStakeholderAdd}
                            onCancel={() => this.setState({ showStakeholders: false, findStakeholders: [] })}
                            onChange={this.handleStakeholderChange}
                            onSearch={this.handleStakeholderSearch}
                            options={showStakeholders ? findStakeholders.map(stakeholder => { return { id: stakeholder.id, name: stakeholder.name }; }) : []}
                            participationRate={participationRate}
                        />
                    </Dialog>
                    <DialogOnAir
                        disabled={loadingOnAir}
                        errors={showOnAirErrors}
                        notify={notifyOnAir}
                        orientation={orientationOnAir}
                        onCancel={() => this.handleOnAir(false)}
                        onHide={() => this.setState({ showOnAir: showOnAir, notifyOnAir: false })}
                        onNotify={(e) => this.setState({ notifyOnAir: e.target.value })}
                        onOrientation={(e) => this.setState({ orientationOnAir: e.target.value })}
                        onOk={() => this.handleOnAir(true)}
                        post={post}
                        visible={showOnAir}
                    />
                </div>
            </Page >
        );
    }
}

const mapStateToProps = ({ app, artist, post, stakeholder, tag }) => {
    return { store: { app, artist, post, stakeholder, tag } };
};

const mapDispatchToProps = {
    fetchGetArtists,
    fetchGetAllStakeholders,
    fetchGetLiveLink,
    fetchGetLiveSetup,
    fetchGetPost,
    fetchGetSections,
    fetchGetStakeholders,
    fetchGetTags,
    fetchPostAddSection,
    fetchPostLive2VOD,
    fetchPostOnAir,
    fetchPostRemoveSection,
    fetchPostStakeholders,
    fetchPostVideo,
    fetchPutPost,
    fetchRemoveStakeholder,
    getTypes,
    getUploadSteps,
    resetPost,
    setLivePicture,
    setPicture,
    setPost,
    setPostStakeholders,
    setStakeholdersFilter,
    showError,
};

const PostModifyPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedPostModifyPage);

export default PostModifyPage;
