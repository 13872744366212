/* eslint-disable react/jsx-key */
/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import i18next from 'i18next';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { Toast } from 'primereact/toast';
import { PrivateRoute } from './shared/components/private-route.component';
import { historyHelper as history } from './core/helpers/history.helper';
import { resetMessage } from './store/actions/message.action';

import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'react-circular-progressbar/dist/styles.css';
import 'tippy.js/dist/tippy.css';

import './app.component.css';
import './custom-opp.css';

import AccountPage from './main/account-page.component';
import AdminPage from './admin/admin-page.component';
import ArtistsPage from './artist/artists-page.component';
import ArtistChatPage from './artist/artist-chat-page.component';
import ArtistCreatePage from './artist/artist-create-page.component';
import ArtistModifyPage from './artist/artist-modify-page.component';
import BannersPage from './banner/banners-page.component';
import BannerCreatePage from './banner/banner-create-page.component';
import BannerModifyPage from './banner/banner-modify-page.component';
import EmailValidationPage from './main/email-validation-page.component';
import ItemCreatePage from './shop/item-create-page.component';
import ItemModifyPage from './shop/item-modify-page.component';
import LoginPage from './main/login-page.component';
import NotificationCreatePage from './user/notification-create-page.component';
import PostsPage from './post/posts-page.component';
import PostModifyPage from './post/post-modify-page.component';
import PostCreatePage from './post/post-create-page.component';
import PostStakeholderPage from './post/post-stakeholder-page.component';
import RecoveryPage from './main/recovery-page.component';
import SectionsPage from './section/sections-page.component';
import SectionCreatePage from './section/section-create-page.component';
import SectionModifyPage from './section/section-modify-page.component';
import ShopPage from './shop/shop-page.component';
import StakeholdersPage from './stakeholder/stakeholders-page.component';
import StakeholderCreatePage from './stakeholder/stakeholder-create-page.component';
import StakeholderModifyPage from './stakeholder/stakeholder-modify-page.component';
import StoreCoinsPage from './storecoin/storecoins-page.component';
import StoreCoinCreatePage from './storecoin/storecoins-create-page.component';
import StoreCoinModifyPage from './storecoin/storecoins-modify-page.component';
import UsersPage from './user/users-page.component';

const routes = [
    { path: '/login', component: LoginPage },
    { path: '/verify', component: EmailValidationPage },
    { path: '/recovery', component: RecoveryPage },
    { path: '/account', component: AccountPage, private: true },
    { path: '/', component: ArtistsPage, private: true, exact: true },
    {
        path: '/artists/chat/:id',
        component: ArtistChatPage,
        private: true,
        exact: true,
        strict: true,
    },
    { path: '/artists/new', component: ArtistCreatePage, private: true, exact: true, strict: true },
    {
        path: '/artists/modify/:id',
        component: ArtistModifyPage,
        private: true,
        exact: true,
        strict: true,
    },
    {
        path: '/artists/:id/posts/new',
        component: PostCreatePage,
        private: true,
        exact: true,
        strict: true,
    },
    { path: '/banners', component: BannersPage, private: true, exact: true, strict: true },
    {
        path: '/banners/modify/:id',
        component: BannerModifyPage,
        private: true,
        exact: true,
        strict: true,
    },
    { path: '/banners/new', component: BannerCreatePage, private: true, exact: true, strict: true },
    { path: '/posts', component: PostsPage, private: true, exact: true, strict: true },
    {
        path: '/posts/modify/:id',
        component: PostModifyPage,
        private: true,
        exact: true,
        strict: true,
    },
    {
        path: '/posts/new',
        component: PostCreatePage,
        private: true,
        exact: true,
        strict: true,
    },
    {
        path: '/posts/:postid/stakeholders/:id',
        component: PostStakeholderPage,
        private: true,
        exact: true,
        strict: true,
    },
    { path: '/sections', component: SectionsPage, private: true, exact: true, strict: true },
    {
        path: '/sections/modify/:id',
        component: SectionModifyPage,
        private: true,
        exact: true,
        strict: true,
    },
    { path: '/sections/new', component: SectionCreatePage, private: true, exact: true, strict: true },
    { path: '/shop', component: ShopPage, private: true, exact: true, strict: true },
    { path: '/shop/new', component: ItemCreatePage, private: true, exact: true, strict: true },
    { path: '/shop/modify/:id', component: ItemModifyPage, private: true, exact: true, strict: true },
    { path: '/stakeholders', component: StakeholdersPage, private: true, exact: true, strict: true },
    {
        path: '/stakeholders/new',
        component: StakeholderCreatePage,
        private: true,
        exact: true,
        strict: true,
    },
    {
        path: '/stakeholders/modify/:id',
        component: StakeholderModifyPage,
        private: true,
        exact: true,
        strict: true,
    },
    { path: '/storecoins', component: StoreCoinsPage, private: true, exact: true, strict: true },
    {
        path: '/storecoins/new',
        component: StoreCoinCreatePage,
        private: true,
        exact: true,
        strict: true,
    },
    {
        path: '/storecoins/modify/:id',
        component: StoreCoinModifyPage,
        private: true,
        exact: true,
        strict: true,
    },
    { path: '/admin', component: AdminPage, private: true, exact: true },
    { path: '/users', component: UsersPage, private: true, exact: true },
    {
        path: '/users/notify',
        component: NotificationCreatePage,
        private: true,
        exact: true,
        strict: true,
    },
];

class ConnectedApp extends Component {
    constructor(props) {
        super(props);
        let path = window.location.href;
        path = path.replace(window.location.origin, '');
        if (!props.user.account.id && !path.includes('/verify') && !path.includes('/recovery')) {
            history.push('/login');
        }
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.showMessages.messages) {
            const messages = nextProps.showMessages.messages;
            if (messages.length > 0) {
                const showMessages = messages.map(item => {
                    const summary = i18next.t(item.summary);
                    // Translate all details
                    let details = [];
                    if (item.detail && item.detail.length > 0) {
                        details = item.detail.map(detailItem => {
                            const detail = i18next.t(detailItem);
                            return <div>{detail}</div>;
                        });
                    }

                    return { ...item, summary: summary, detail: details };
                });
                this.growl.show(showMessages);
                this.props.resetMessage();
            }
        }
    }

    render() {
        return (
            <Router history={history}>
                <div className='o-app'>
                    <Toast
                        ref={el => {
                            this.growl = el;
                        }}
                        baseZIndex={1000}
                    ></Toast>
                    {routes.map((route, index) =>
                        route.private ? (
                            <PrivateRoute key={index} {...route} />
                        ) : (
                            <Route key={index} {...route} />
                        )
                    )}
                </div>
            </Router>
        );
    }
}

const mapStateToProps = ({ showMessages, user }) => {
    return { showMessages, user };
};

const mapDispatchToProps = {
    resetMessage,
};

const App = connect(mapStateToProps, mapDispatchToProps)(ConnectedApp);

export default App;
