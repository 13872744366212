import React from 'react';
import { Button } from 'primereact/button';

const Submit = ({ icon, label, onClick }) => {
    const styleContainer = {
        marginBottom: '.5rem',
    };

    return (
        <div style={styleContainer}>
            <Button label={label || ''}
                icon={icon || ''} iconPos="right" onClick={onClick.bind(this)} />
        </div>
    );
};

export default Submit;
