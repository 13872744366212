import React from 'react';
import i18next from 'i18next';
import * as Prime from 'primereact/password';

const LoginInputPassword = ({ name, onChange, onKeyPress, placeholder, value }) => {
    const styleContainer = {
        marginBottom: '.5rem',
    };

    return (
        <div style={styleContainer}>
            <Prime.Password
                id={name}
                value={value}
                feedback={false}
                placeholder={placeholder ? i18next.t(placeholder) : ''}
                onChange={e => onChange({ target: { name: e.target.id, value: e.target.value } })}
                onKeyPress={onKeyPress ? e => onKeyPress(e) : ''}
            />
        </div>
    );
};

export default LoginInputPassword;
