import React from 'react';
import ArtistBox from '../../shared/components/artist-box.component';
import ArtistNewBox from '../../shared/components/artist-new-box.component';
import Config from '../../config';
import './artist-boxes.component.css';

const ArtistBoxes = ({ artists, handleAction }) => {
    return (
        <div className=' o-artist-boxes'>
            <ArtistNewBox onClick={() => handleAction(Config.action.ADD, {})} />
            { artists.map((data, i) => {
                return (
                    <ArtistBox
                        key={data.id}
                        artist={data}
                        onClick={() => handleAction(Config.action.MODIFY, data)}
                    />
                );
            })}
        </div>
    );
};

export default ArtistBoxes;
