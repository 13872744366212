import React from 'react';
import i18next from 'i18next';
import './sidemenu-item.component.css';

const SideMenuItem = ({ active, icon, id, label, name, onClick }) => {
    return (
        <li
            className={`o-sidemenu-item ${active ? 'active' : ''}`}
            key={name}
            onClick={() => onClick(name)}
        >
            {icon && <i className={icon} />}
            {!label && (
                <span className='o-sidemenu-item-label'>{i18next.t(id)}</span>
            )}
            {label && <span className='o-sidemenu-item-label'>{label}</span>}
        </li>
    );
};

export default SideMenuItem;
