import React from 'react';
import i18next from 'i18next';
import InputText from '../../shared/components/form/input-text.component';
import Panel from '../../shared/components/panel.component';
import SubmitButton from '../../shared/components/form/submit-button.component';
import { historyHelper as history } from '../../core/helpers/history.helper';
import './post-stakeholder-list.component.css';
// import displayDate from '../shared/components/display-date';

const PostStakeholderList = ({ add, post, remove, searchStakeholders, stakeholders }) => {
    let participation = 0;
    stakeholders.forEach(element => {
        participation = parseFloat(participation) + parseFloat(element.share);
    });

    const nf = new Intl.NumberFormat();
    return (
        <Panel title={i18next.t('app_post_stakeholders')} toggleable={true} collapsed={false}>
            <div className='p-grid o-posts-page-header'>
                <div className='p-col-3'>
                    <InputText
                        name='find'
                        label={i18next.t('app_find')}
                        onKeyPress={(e) => searchStakeholders(e)}
                        placeholder={i18next.t('app_find_min_char')}
                    />
                </div>
                <div className='p-col-6'></div>
                <div className='p-col-3 o-posts-page-new'>
                    <SubmitButton
                        label={i18next.t('app_post_add_stakeholder')}
                        onClick={add}
                    />
                </div>
            </div>
            <div className='p-grid'>
                <div className='o-table p-col-12'>
                    <table>
                        <thead>
                            <tr>
                                <th className='o-post-stakeholder-name'>{i18next.t('app_stakeholder_name')}</th>
                                <th>{i18next.t('app_stakeholder_type')}</th>
                                <th>{i18next.t('app_stakeholder_share')} (%)</th>
                                {/* <th>{i18next.t('app_stakeholder_begins_at')}</th> */}
                                {/* <th>{i18next.t('app_stakeholder_finish_at')}</th> */}
                                <td>{i18next.t('app_actions')}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {stakeholders.length === 0 && <tr><td colSpan="4">{i18next.t('app_post_no_stakeholder')}</td></tr>}
                            {stakeholders.map(stakeholder => {
                                return <tr key={stakeholder.id}>
                                    <td className='o-post-stakeholder-name'>{stakeholder.name}</td>
                                    <td>{stakeholder.type ? i18next.t(`app_stakeholder_type_${stakeholder.type}`) : ''}</td>
                                    <td>{nf.format(stakeholder.share, { style: 'decimale' })}</td>
                                    {/* <td>{displayDate(stakeholder.beginsAt)}</td> */}
                                    {/* <td>{displayDate(stakeholder.finishAt)}</td> */}
                                    <td>
                                        <div className='o-table-actions o-table-actions-alt'>
                                            <i className="fas fa-edit"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => history.push(`/posts/${post.id}/stakeholders/${stakeholder.id}`)}></i>
                                            <i className="fas fa-trash-alt"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => remove(stakeholder)}></i>
                                        </div>
                                    </td>
                                </tr>;
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td className='o-post-stakeholder-total'>{i18next.t('app_stakeholder_total')}</td>
                                <td className='o-post-stakeholder-rate'>{participation}</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </Panel>
    );
};

export default PostStakeholderList;
