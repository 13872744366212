import React from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'primereact/calendar';
import './input-datetime2.component.css';

const InputDatetime2 = ({ disabled, label, name, onChange, value, showTime, dateFormat = 'dd/mm/yy' }) => {
    return (
        <div className='o-input-datetime2 o-input-datetime2-container'>
            {label && <label className='o-input-datetime2-label'>{label}</label>}
            <div className='o-input-datetime2-value'>
                <Calendar
                    dateFormat={dateFormat}
                    disabled={disabled}
                    id={name}
                    name={name}
                    onChange={e => onChange(e)}
                    showTime={showTime === undefined ? true : showTime}
                    value={value}
                />
            </div>
        </div>
    );
};

InputDatetime2.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
};

export default InputDatetime2;
