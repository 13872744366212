import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import LoginInputPassword from './form/login-input-password.component';
import LoginSubmit from './form/login-submit.component';

import './new-form.component.css';

const NewForm = ({
    dirty,
    onChange,
    onKeyPress,
    onSubmit,
    submitted,
    value,
}) => {
    const [mismatch, setMismatch] = useState(false);
    useEffect(() => {
        if (value.newPassword !== value.newConfirmation) {
            setMismatch(true);
        }
    }, [dirty, value]);

    return (
        <div className='o-new-form'>
            <div className='o-new-form-title'>
                <label>{i18next.t('app_change_password')}</label>
            </div>
            <LoginInputPassword
                name='newPassword'
                placeholder='app_password'
                value={value.newPassword}
                onChange={onChange}
                onKeyPress={onKeyPress || ''}
            />
            <LoginInputPassword
                name='newConfirmation'
                placeholder='app_password_confirmation'
                value={value.newConfirmation}
                onChange={onChange}
                onKeyPress={onKeyPress || ''}
            />
            {dirty && mismatch && (
                <div className='o-new-form-error'>
                    {i18next.t('app_error_password_confirmation_mismatch')}
                </div>
            )}
            <LoginSubmit label='app_validate' disabled={submitted} onClick={onSubmit} />
        </div>
    );
};

export default NewForm;
