import React, { Component } from 'react';
import i18next from 'i18next';
import BannerDetail from './components/banner-detail.component';
import Dialog from '../shared/components/dialog.component';
import DialogAddContent from './components/dialog-add-content.component';
import ImageUpload from '../shared/components/form/image-upload.component';
import InputText from '../shared/components/form/input-text.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { connect } from 'react-redux';
import { fetchGetArtists } from '../store/actions/artist.action';
import { fetchGetStoreCoins } from '../store/actions/coin.action';
import {
    fetchGetContent,
    fetchPostBanner,
    getTypes,
    resetBanner,
    setBanner,
    setContent,
    setPicture,
} from '../store/actions/banner.action';
import { fetchGetPosts } from '../store/actions/post.action';
import { historyHelper as history } from '../core/helpers/history.helper';
import Config from '../config';
import './banner-create-page.component.css';

class ConnectedBannerCreatePage extends Component {
    constructor(props) {
        super(props);

        this.locales = Config.locales.LIST.map(item => {
            return {
                label: i18next.t(item.label),
                value: item.value,
            };
        });

        this.state = {
            addContentDialog: false,
            dialogLocaleToRemove: '',
            dialogNextLocaleValues: [],
            dirty: false,
            findContents: [],
            isDialogVisible: false,
            localeValues: ['fr'],
            showContents: false,
            submit: false,
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDetail = this.handleChangeDetail.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleContentAdd = this.handleContentAdd.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
        this.handleContentRemove = this.handleContentRemove.bind(this);
        this.handleContentSearch = this.handleContentSearch.bind(this);
        this.handleContentSubmit = this.handleContentSubmit.bind(this);
        this.handleLocaleDialogCancel = this.handleLocaleDialogCancel.bind(this);
        this.handleLocaleDialogSubmit = this.handleLocaleDialogSubmit.bind(this);
        this.handlePictureOnChange = this.handlePictureOnChange.bind(this);
        this.handlePictureOnClick = this.handlePictureOnClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.resetBanner();
    }

    componentWillUnmount() {
        this.props.resetBanner();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        const banner = { ...this.props.store.banner.banner };
        banner[name] = value;
        this.props.setBanner(banner);
        this.setState({ dirty: true });
    }

    handleChangeDetail(banner) {
        this.props.setBanner(banner);
        this.setState({ dirty: true });
    }

    handleChangeLocales(event) {
        let localeValues = this.state.localeValues;
        const value = event.value;
        const eventLength = value.length;

        if (localeValues.length > eventLength && eventLength > 0) {
            const removedLocale = localeValues.filter(l => !value.includes(l))[0];
            this.setState({
                isDialogVisible: true,
                dialogLocaleToRemove: removedLocale,
                dialogNextLocaleValues: value,
            });
        }

        if (eventLength > 0 && localeValues.length <= eventLength) {
            localeValues = value;
            this.setState({ localeValues });
        }
    }

    handleContentAdd(content, type) {
        const entityEvent = { target: { name: 'entityId', value: content.id } };
        this.handleChange(entityEvent);
        this.handleChange({ target: { name: 'type', value: type } });

        this.props.fetchGetContent(type, content.id);
        this.setState({
            addContentDialog: false,
            showContents: false,
            findContents: [],
            dirty: true,
        });
    }

    handleContentChange(event) {
        const { value } = event.target;
        const banner = { ...this.props.store.banner.banner };

        if (value !== banner.type) {
            banner.type = value;
            banner.entityId = '';
            this.props.setBanner(banner);
            this.props.setContent({});
            this.setState({
                showContents: false,
                findContents: [],
            });
        }
    }

    handleContentRemove() {
        const banner = { ...this.props.store.banner.banner };
        banner.type = null;
        banner.entityId = null;
        this.props.setBanner(banner);
        this.props.setContent({});
    }

    async handleContentSearch(query, type, entityId) {
        switch (type) {
            case 'artist':
                await this.props.fetchGetArtists({ find: query }, artists => {
                    if (artists === null) {
                        this.setState({ showContents: false, findContents: [] });
                        return;
                    }
                    this.setState({ showContents: true, findContents: artists.filter(artist => artist.id !== entityId) });
                });
                break;
            case 'post':
                await this.props.fetchGetPosts({ find: query }, posts => {
                    if (posts === null) {
                        this.setState({ showContents: false, findContents: [] });
                        return;
                    }
                    this.setState({ showContents: true, findContents: posts.filter(post => post.id !== entityId) });
                });
                break;
            case 'storeCoin':
                await this.props.fetchGetStoreCoins({ find: query }, coins => {
                    if (coins === null) {
                        this.setState({ showContents: false, findContents: [] });
                        return;
                    }
                    this.setState({ showContents: true, findContents: coins.filter(coin => coin.id !== entityId) });
                });
                break;
            default:
        }
    }

    handleContentSubmit() {
        this.setState({
            addContentDialog: false,
            showContents: false,
            findContents: [],
            dirty: true,
        });
    }

    handleLocaleDialogCancel() {
        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            dialogNextLocaleValues: [],
        });
    }

    handleLocaleDialogSubmit() {
        const { dialogLocaleToRemove } = this.state;
        const banner = { ...this.props.store.banner.banner };

        if (banner.about && typeof banner.about[dialogLocaleToRemove] !== 'undefined') {
            delete banner.about[dialogLocaleToRemove];
        }

        if (banner.title && typeof banner.title[dialogLocaleToRemove] !== 'undefined') {
            delete banner.title[dialogLocaleToRemove];
        }

        this.handleChangeDetail(banner);

        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            localeValues: this.state.dialogNextLocaleValues,
            dialogNextLocaleValues: [],
        });
    }

    handlePictureOnChange(files) {
        this.setState({ dirty: true });
        const file = files[0];
        if (file) {
            this.props.setPicture(file);
        }
    }

    handlePictureOnClick() {
        this.setState({ dirty: true });
        this.props.setPicture(null);
    }

    handleSubmit() {
        const { banner, content, pictureFile } = this.props.store.banner;
        if (Object.keys(banner.title).length > 0 &&
            Object.values(banner.title).every(n => n.trim() !== '') &&
            pictureFile && pictureFile.name) {
            this.props.fetchPostBanner(banner, content, pictureFile);
        }
        this.setState({ submit: true });
    }

    render() {
        const { addContentDialog, dirty, findContents, isDialogVisible, localeValues, showContents, submit } = this.state;
        const { getTypes, store: { banner: { banner, content, loading, picture, pictureFile } } } = this.props;

        const types = getTypes().map(type => { return { ...type, label: i18next.t(type.label) }; });

        const aboutKeys = typeof banner.title === 'object' ? Object.keys(banner.title) : ['fr'];
        if (localeValues.length === 0 && aboutKeys.length !== 0) {
            this.setState({ localeValues: aboutKeys });
        }

        const contentValues = [];

        return (
            <Page
                title={i18next.t('app_page_banner_create')}
                onClick={() => this.handleAction(Config.action.BACK, null)}
                loading={loading}
            >
                <div className='o-banner-create'>
                    <Panel title={i18next.t('app_general_information')} toggleable={false}>
                        <div className='p-grid'>
                            <div className='p-col-8'>
                                <InputMultiSelectInline
                                    name='locales'
                                    options={this.locales}
                                    onChange={this.handleChangeLocales}
                                    value={localeValues}
                                />
                                {localeValues.map(locale => {
                                    return (<BannerDetail
                                        key={`banner_detail_${locale}`}
                                        banner={banner}
                                        lang={locale}
                                        onChange={this.handleChangeDetail}
                                        submit={submit}
                                        onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                                    />);
                                })}
                                <InputText
                                    name='url'
                                    label={i18next.t('app_banner_url')}
                                    value={banner.url}
                                    onChange={this.handleChange}
                                    onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                                />
                            </div>
                            <div className='p-col-4'>
                                <ImageUpload
                                    name='picture'
                                    label={i18next.t('app_banner_picture')}
                                    onClick={this.handlePictureOnClick}
                                    onChange={this.handlePictureOnChange}
                                    image={picture}
                                />
                                {submit && !pictureFile && (
                                    <div className='error'>* {i18next.t('app_error_banner_mandatory_picture')}</div>
                                )}
                                <SubmitButton
                                    kind="secondary"
                                    label={i18next.t('app_banner_add_content')}
                                    onClick={() => this.setState({ addContentDialog: true })}
                                />
                                {banner.type && (
                                    <div className='o-type'>{i18next.t(`app_banner_type_${banner.type}`)}</div>
                                )}
                                {content.type && (
                                    <div className="o-banner-create-content">
                                        {content.picture && (<div
                                            className='o-picture'
                                            style={{ backgroundImage: `url(${content.picture})` }}
                                        >
                                        </div>)}
                                        <div className='o-title'>{content.title}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='p-grid'>
                            <div className='p-col-8'></div>
                            <div className='p-col-4'>
                                <SubmitButton
                                    label={i18next.t('app_submit')}
                                    onClick={() => this.handleSubmit()}
                                    disabled={loading || !dirty}
                                />
                            </div>
                        </div>
                    </Panel>
                    <Dialog
                        visible={isDialogVisible}
                        onHide={() => this.handleLocaleDialogCancel()}
                        onCancel={() => this.handleLocaleDialogCancel()}
                        onSubmit={() => this.handleLocaleDialogSubmit()}
                        text={i18next.t('app_dialog_delete_locale')}
                    />
                    <DialogAddContent
                        banner={banner}
                        onAdd={this.handleContentAdd}
                        onCancel={() => this.setState({
                            addContentDialog: false,
                            showContents: false,
                            findContents: [],
                        })}
                        onChange={this.handleContentChange}
                        onHide={() => this.setState({
                            addContentDialog: false,
                            showContents: false,
                            findContents: [],
                        })}
                        onRemove={this.handleContentRemove}
                        onSearch={this.handleContentSearch}
                        onSubmit={this.handleContentSubmit}
                        options={showContents
                            ? findContents.map(content => { return { id: content.id, name: content.name || content.title }; })
                            : []}
                        types={types}
                        values={contentValues}
                        visible={addContentDialog}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, banner }) => {
    return { store: { app, banner } };
};

const mapDispatchToProps = {
    fetchGetArtists,
    fetchGetContent,
    fetchGetPosts,
    fetchGetStoreCoins,
    fetchPostBanner,
    getTypes,
    resetBanner,
    setBanner,
    setContent,
    setPicture,
};

const BannerCreatePage = connect(mapStateToProps, mapDispatchToProps)(ConnectedBannerCreatePage);

export default BannerCreatePage;
