import { initialState, type } from '../actions/statistic.action';

const statistic = (state = initialState, action) => {
    switch (action.type) {
        case type.STATISTIC_FETCH_PENDING:
            return { ...state, loading: action.loading };
        case type.STATISTIC_FETCH_GET_USERS_CSV_SUCCESS:
            return { ...state, loading: action.loading };
        default:
            return state;
    }
};

export default statistic;
