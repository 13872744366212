import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Config from '../../config';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'fr',
        lng: 'fr',
        debug: true,
        backend: {
            backends: [
                LocalStorageBackend,
                HttpApi,
            ],
            backendOptions: [
                {
                    expirationTime: Config.i18n.EXPIRATION_TIME,
                },
                {
                    loadPath: `${process.env.REACT_APP_API_HOST}/v1/messages/{{lng}}?prefix[]=app&prefix[]=api`,
                    crossDomain: true,
                    customHeaders: () => ({
                        'X-API-KEY': Config.app.APIKEY,
                        'X-APP-VERSION': Config.app.version.NUMBER,
                    }),
                    parse: (data) => {
                        const json = JSON.parse(data);
                        const { data: { messages } } = json;

                        return messages;
                    },
                },
            ],
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
