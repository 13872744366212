import { initialState, type } from '../actions/coin.action';

const coins = (state = initialState, action) => {
    switch (action.type) {
        case type.COIN_FETCH_PENDING:
            return { ...state, loading: action.loading };

        case type.COIN_FETCH_GET_STORECOIN_SUCCESS:
            return { ...state, storeCoin: action.storeCoin, loading: action.loading };

        case type.COIN_FETCH_GET_STORECOINS_SUCCESS:
            return {
                ...state,
                storeCoins: action.storeCoins,
                countAll: action.countAll,
                loading: action.loading,
            };

        case type.COIN_FETCH_POST_SUCCESS:
        case type.COIN_FETCH_PUT_SUCCESS:
            return {
                ...state,
                storeCoin: action.storeCoin,
                loading: action.loading,
            };

        case type.COIN_RESET_STORECOIN:
        case type.COIN_SET_STORECOIN:
            return {
                ...state,
                storeCoin: action.storeCoin,
            };

        case type.STAKEHOLDER_SET_FILTER:
            return { ...state, filter: action.filter };

        default:
            return state;
    }
};

export default coins;
