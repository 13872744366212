import React from 'react';
import i18next from 'i18next';
import ActionIcon from '../../shared/components/action-icon.component';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const SectionTable = ({ sections, onRemove, onRowReorder }) => {
    const columns = [
        { field: 'position', header: i18next.t('app_sections_position') },
        { field: 'title', header: i18next.t('app_sections_title') },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="p-d-flex p-jc-center">
                <ActionIcon
                    icon="fas fa-edit"
                    label={i18next.t('app_tip_edit')}
                    onClick={() => history.push(`/sections/modify/${rowData.id}`)}>
                </ActionIcon>
                {/*
                <ActionIcon
                    icon="fas fa-archive"
                    label={i18next.t('app_tip_archive')}
                    onClick={() => onRemove(rowData)}>
                </ActionIcon>
                */}
            </div>
        );
    };

    const dynamicColumns = columns.map((col, i) => {
        let style = { textAlign: 'left' };
        style = col.field === 'position' ? { textAlign: 'center', width: '6rem' } : style;
        return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} style={style} />;
    });

    const handleRowReorder = (e) => {
        onRowReorder(e.value);
    };

    const typeBodyTemplate = (rowData) => {
        return rowData.type ? i18next.t(`app_section_type_${rowData.type}`) : '';
    };

    const activeBodyTemplate = (rowData) => {
        return rowData.active ? <i className="fas fa-check-circle" /> : '';
    };

    return (
        <div className='o-section-table'>
            <DataTable value={sections} reorderableColumns onRowReorder={handleRowReorder}>
                <Column rowReorder style={{ width: '3rem', cursor: 'grab' }} />
                {dynamicColumns}
                <Column
                    body={typeBodyTemplate}
                    field="type"
                    header={i18next.t('app_sections_type')}
                    style={{ textAlign: 'center', width: '8rem' }}>
                </Column>
                <Column
                    body={activeBodyTemplate}
                    field="active"
                    header={i18next.t('app_sections_active')}
                    style={{ textAlign: 'center', width: '5rem' }}>
                </Column>
                <Column
                    body={actionBodyTemplate}
                    style={{ textAlign: 'center', width: '4rem' }}>
                </Column>
            </DataTable>
        </div>
    );
};

export default SectionTable;
