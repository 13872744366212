import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { initPage } from '../store/actions/app.action';
import ButtonIcon from '../shared/components/form/button-icon.component';
import Dialog from '../shared/components/dialog.component';
import InputDatetime2 from '../shared/components/form/input-datetime2.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import InputSelect from '../shared/components/form/input-select.component';
import InputText from '../shared/components/form/input-text.component';
import Page from '../shared/components/page.component';
import Table from './components/table.component';
import { setFilter as setFilterNotification, setCount } from '../store/actions/notification.action';
import { fetchGetUsersCSV } from '../store/actions/statistic.action';
import {
    fetchGetUsers,
    fetchGetTechAccess,
    resetTechAccess,
    setFilter,
} from '../store/actions/user.action';
import { Paginator } from 'primereact/paginator';
import SubmitButton from '../shared/components/form/submit-button.component';
import Config from '../config';
import './users-page.component.css';

class ConnectedUsersPage extends Component {
    constructor(props) {
        super(props);

        const { store: { user } } = this.props;

        this.locales = Config.locales.LIST.map(item => {
            return {
                label: i18next.t(item.label),
                value: item.value,
            };
        });

        this.status = Config.user.status.LIST.map(item => {
            return {
                label: i18next.t(item.label),
                value: item.value,
            };
        });

        this.state = {
            find: user.filter.find ? user.filter.find : null,
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleCSV = this.handleCSV.bind(this);
        this.handleDialogOnCancel = this.handleDialogOnCancel.bind(this);
        this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
        this.handleOnPageChange = this.handleOnPageChange.bind(this);
    }

    componentDidMount() {
        this.props.initPage('/users');
        const { filter } = this.props.store.user;
        filter.limit = filter.limit ? filter.limit : Config.paginator.DEFAULT_ROWS;
        filter.offset = filter.offset ? filter.offset : Config.paginator.DEFAULT_FIRST;
        this.props.fetchGetUsers(filter);
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.NOTIFY:
                // eslint-disable-next-line no-case-declarations
                const { user } = this.props.store;
                this.props.setCount(user.countAll);
                this.props.setFilterNotification(user.filter);
                history.push('/users/notify');
                break;
            case Config.action.TECH:
                this.props.fetchGetTechAccess(data.id);
                break;
            default:
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { filter } = this.props.store.user;

        if (value !== 'all') {
            filter[name] = value;
        } else {
            delete filter[name];
        }

        this.props.setFilter(filter);
        this.props.fetchGetUsers(filter);
    }

    handleChangeStatus(event) {
        const { value } = event.target;
        const { filter } = this.props.store.user;

        delete filter.status;
        if (value !== 'all') {
            filter.status = value;
        }

        this.props.setFilter(filter);
        this.props.fetchGetUsers(filter);
    }

    handleDialogOnCancel() {
        this.props.resetTechAccess();
    }

    handleOnKeyPress(event) {
        if (event.key === 'Enter') {
            const { name, value } = event.target;
            if (value.length >= 3) {
                this.handleChange(event);
            } else {
                const { filter } = this.props.store.user;
                if (value) {
                    filter[name] = value;
                } else {
                    if (filter[name] && filter[name].length > 0) {
                        delete filter[name];
                        this.handleChange(event);
                    }
                }
                this.props.setFilter(filter);
            }
        }
    }

    handleOnPageChange(first, rows) {
        const { filter } = this.props.store.user;
        filter.limit = rows;
        filter.offset = first;
        this.props.setFilter(filter);
        this.props.fetchGetUsers(filter);
    }

    handleCSV() {
        const { filter } = this.props.store.user;
        this.props.fetchGetUsersCSV(filter);
    }

    render() {
        const { store: { app, user } } = this.props;

        return (
            <Page title={i18next.t('app_page_users')} loading={user.loading}>
                <div className='o-users-page'>
                    <div className='o-users-filter-container'>
                        <InputSelect
                            all={true}
                            label={i18next.t('app_language')}
                            name='locale'
                            options={this.locales}
                            onChange={this.handleChange}
                            value={user.filter.locale ? user.filter.locale : null}
                        />

                        <InputText
                            label={i18next.t('app_find')}
                            name='find'
                            onChange={e => this.setState({ find: e.target.value })}
                            onKeyPress={this.handleOnKeyPress}
                            placeholder={i18next.t('app_find_min_char')}
                            value={this.state.find}
                        />

                        <InputMultiSelectInline
                            label={i18next.t('app_status')}
                            multiple={false}
                            name='status'
                            options={this.status}
                            onChange={this.handleChangeStatus}
                            value={user.filter.status ? user.filter.status : 'all'}
                        />

                        <InputDatetime2
                            label={i18next.t('app_last_connection')}
                            name='connection'
                            onChange={this.handleChange}
                            showTime={false}
                            value={user.filter.connection ? user.filter.connection : null}
                        />
                    </div>
                    <div className='o-users-table-header'>
                        <div className='o-users-paginator-container'>
                            <Paginator
                                totalRecords={user.countAll}
                                first={
                                    user.filter.offset
                                        ? user.filter.offset
                                        : Config.paginator.DEFAULT_FIRST
                                }
                                rows={
                                    user.filter.limit
                                        ? user.filter.limit
                                        : Config.paginator.DEFAULT_ROWS
                                }
                                onPageChange={e => this.handleOnPageChange(e.first, e.rows)}
                            ></Paginator>
                            <div className='o-users-paginator-info'>
                                {new Intl.NumberFormat(app.locale).format(user.countAll)}{' '}
                                {i18next.t('app_user_s')}
                            </div>
                            <SubmitButton
                                label={i18next.t('app_users_notify')}
                                onClick={() => this.handleAction(Config.action.NOTIFY, {})}
                                disabled={user.loading || user.filter.status === 'false'}
                                kind='tinySecondary'
                            />
                        </div>
                        <ButtonIcon icon='fas fa-file-csv' onClick={() => this.handleCSV()} />
                    </div>
                    <Table
                        users={user.users}
                        locale={user.account.locale}
                        onClick={this.handleAction}
                    />
                </div>
                <Dialog
                    visible={user.techPassword}
                    onHide={() => this.handleDialogOnCancel()}
                    onOk={() => this.handleDialogOnCancel()}
                >
                    {i18next.t('app_dialog_tech_password')}
                    <b>{user.techPassword}</b>
                </Dialog>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, user }) => {
    return { store: { app, user } };
};

const mapDispatchToProps = {
    fetchGetUsers,
    fetchGetUsersCSV,
    fetchGetTechAccess,
    initPage,
    resetTechAccess,
    setCount,
    setFilter,
    setFilterNotification,
};

const UsersPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedUsersPage);

export default UsersPage;
