import React from 'react';
import i18next from 'i18next';
import ButtonRounded from './button-rounded.component';
import ImageUploader from 'react-images-upload';
import './image-upload.component.css';

const ImageUpload = ({ disabled, image, imageLabel, kind, label, name, onChange, onClick, ratio }) => {
    const format = ratio ? Math.round((100 / ratio) * 100) / 100 : 56.25;
    const styleImage = {
        backgroundImage: `url(${image})`,
        paddingTop: `calc(${format}% - 2px)`,
    };

    const styleFileContainer = {
        width: '15rem',
        height: '10rem',
        borderRadius: '0',
        textAlign: 'center',
        border: '0',
        boxShadow: 'unset',
    };

    return (
        <div className='o-image-upload'>
            {label && <p>{label}</p>}
            {!image && (
                <div className='o-image-upload-container'>
                    <ImageUploader
                        name={name}
                        withIcon={true}
                        onChange={onChange.bind(this)}
                        buttonText={i18next.t('app_choose_image')}
                        fileContainerStyle={styleFileContainer}
                        label={imageLabel || i18next.t('app_choose_image_label')}
                        imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                        singleImage={true}
                        fileSizeError={i18next.t('app_choose_image_error_size')}
                        fileTypeError={i18next.t('app_choose_image_error_type')}
                    />
                </div>
            )}
            {image && (
                <div className='o-image-upload-container'>
                    <div
                        className={`o-image-upload-picture ${kind}`}
                        style={styleImage}
                    ></div>
                    <div>
                        <ButtonRounded
                            label='app_change_image'
                            onClick={onClick.bind(this)}
                            disabled={disabled || false}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageUpload;
