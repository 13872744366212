import React from 'react';
import Radium from 'radium';
import './submit-button.component.css';

const SubmitButton = ({ disabled, kind, label, onClick }) => {
    return (
        <div className={`o-submit-button ${kind === 'secondary' ? 'secondary' : ''}`}>
            <button
                type='submit'
                onClick={onClick.bind(this)}
                disabled={disabled || false}
                className={disabled ? 'disabled' : ''}
            >
                {label}
            </button>
        </div>
    );
};

export default Radium(SubmitButton);
