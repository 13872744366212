import React, { useEffect, useState } from 'react';
import './input-text.component.css';

const InputText = ({ disabled, input, label, name, onChange, onKeyDown, onKeyPress, onKeyUp, placeholder = '', value }) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <div className='o-input-text'>
            {label && <label>{label}</label>}
            <input
                disabled={disabled === true ? 'disabled' : false}
                name={name}
                onBlur={onChange}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={onKeyDown}
                onKeyPress={onKeyPress}
                onKeyUp={onKeyUp}
                placeholder={placeholder}
                type='text'
                value={inputValue}
                autoComplete='off'
                ref={el => { input = el; }}
            />
        </div>
    );
};

export default InputText;
