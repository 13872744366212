import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import Dialog from '../../shared/components/dialog.component';
import InputCompletionText from '../../shared/components/form/input-completion-text.component';
import InputMultiSelectInline from '../../shared/components/form/input-multiselect-inline.component';

import './dialog-add-content.component.css';

const DialogAddContent = ({ banner, onAdd, onCancel, onChange, onHide, onSearch, onSubmit, options, values, visible, types }) => {
    const [tags, setTags] = useState([]);
    useEffect(() => {
        const contentValues = (values && Object.keys(values).length > 0) ? [{ name: values.title, id: values.id }] : [];
        setTags(contentValues);
    }, [values]);

    const [tagsOptions, setTagsOptions] = useState([]);
    useEffect(() => {
        setTagsOptions(options);
    }, [options]);

    const [tagsType, setTagsType] = useState('');
    useEffect(() => {
        setTagsType(banner.type);
    }, [banner.type]);

    const onTagsAdd = (content) => {
        if (tagsType === 'storeCoin') {
            const entityEvent = [{ name: content.name, id: content.id }];
            setTags(entityEvent);
            setTagsOptions([]);
        } else {
            onAdd(content, tagsType);
        }
    };

    const onTagsCancel = () => {
        const contentValues = (values && Object.keys(values).length > 0) ? [{ name: values.title, id: values.id }] : [];
        setTags(contentValues);
        onCancel();
    };

    const onTagsRemove = () => {
        setTags([]);
    };

    const onTagsSearch = (value) => {
        onSearch(value, tagsType, tags.id);
    };

    const onTagsSubmit = () => {
        if (banner.type !== tagsType) {
            onChange({ target: { value: tagsType } });
        }

        if (tags && tags.length > 0) {
            const entityEvent = { title: tags[0].name, id: tags[0].id };
            onAdd(entityEvent);
        }
        onSubmit();
    };

    const onTypeChange = (event) => {
        const { value } = event.target;
        if (value !== tagsType) {
            setTagsType(value);
            setTags([]);
        }
    };

    return (
        <Dialog
            visible={visible}
            onCancel={onTagsCancel}
            onHide={onHide}
            onSubmit={onTagsSubmit}
            submitDisabled={((!tagsType && !tags) || (tagsType && (!tags || tags.length === 0))) && tagsType !== 'storeCoin'}>
            <InputMultiSelectInline
                name='type'
                onChange={onTypeChange}
                value={tagsType}
                options={types}
                multiple={false}
            />
            {tagsType === 'storeCoin' && (<div className="">{i18next.t('app_banner_store_coin_not_mandatory')}</div>)}
            <InputCompletionText
                bottom={true}
                disabled={!tagsType}
                editable={false}
                name='find'
                onAdd={onTagsAdd}
                onCancel={onTagsCancel}
                onRemove={onTagsRemove}
                onSearch={onTagsSearch}
                options={tagsOptions}
                placeholder={i18next.t('app_find')}
                values={tags}
            />
        </Dialog>
    );
};

export default DialogAddContent;
