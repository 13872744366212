import React from 'react';
import i18next from 'i18next';
import { Button } from 'primereact/button';

const LoginSubmit = ({ icon, label, onClick }) => {
    const styleContainer = {
        marginBottom: '.5rem',
    };

    return (
        <div style={styleContainer}>
            <Button
                label={label ? i18next.t(label) : ''}
                icon={icon || ''}
                iconPos='right'
                onClick={onClick.bind(this)}
            />
        </div>
    );
};

export default LoginSubmit;
