import React, { Component } from 'react';
import i18next from 'i18next';
import BannerTable from './components/banner-table.component';
import Dialog from '../shared/components/dialog.component';
import Page from '../shared/components/page.component';
import { historyHelper as history } from '../core/helpers/history.helper';
import { connect } from 'react-redux';
import { initPage } from '../store/actions/app.action';
import { fetchGetBanners, fetchPatchRemoveBanner, fetchPostReorder, setBanners } from '../store/actions/banner.action';
import SubmitButton from '../shared/components/form/submit-button.component';
import Config from '../config';
import './banners-page.component.css';
// import displayDate from '../shared/components/display-date';

class ConnectedBannersPage extends Component {
    constructor(props) {
        super(props);
        this.handleAction = this.handleAction.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        // this.handleRemoveOK = this.handleRemoveOK.bind(this);
        this.handleRowReorder = this.handleRowReorder.bind(this);

        this.state = {
            removeDialogVisible: false,
            removeBanner: null,
        };
    }

    async componentDidMount() {
        this.props.initPage('/banners');
        this.props.fetchGetBanners();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.ADD:
                history.push('/banners/new');
                break;

            case Config.action.MODIFY:
                history.push(`/banners/modify/${data.id}`);
                break;

            default:
        }
    }

    handleRemove(banner) {
        this.setState({ removeDialogVisible: true, removeBanner: banner });
    }

    handleRemoveOK() {
        const { removeBanner } = this.state;
        const { banners } = this.props.store.banner;
        const index = banners.findIndex(e => e.id === removeBanner.id);
        banners.splice(index, 1);
        this.props.setBanners(banners);
        this.props.fetchPatchRemoveBanner(removeBanner.id);
        this.setState({ removeDialogVisible: false, removeBanner: null });
    }

    handleRowReorder(banners) {
        this.props.fetchPostReorder(banners);
    }

    render() {
        const { removeDialogVisible, removeBanner } = this.state;

        const {
            store: { banner: { banners }, loading },
        } = this.props;

        return (
            <Page title={i18next.t('app_page_banners')} loading={loading}>
                <div className='p-grid'>
                    <div className='p-col-3'>
                    </div>
                    <div className='p-col-9 o-new-button'>
                        <SubmitButton
                            label={i18next.t('app_banners_new')}
                            onClick={() => history.push('/banners/new')}
                        />
                    </div>
                </div>
                <div className='p-grid'>
                    <div className='p-col-12'>
                        <BannerTable
                            banners={banners}
                            onRemove={this.handleRemove}
                            onRowReorder={this.handleRowReorder}
                        />
                    </div>
                </div>
                <Dialog
                    visible={removeDialogVisible}
                    onCancel={() => this.setState({ removeDialogVisible: false, removeBanner: null })}
                    onHide={() => this.setState({ removeDialogVisible: false, removeBanner: null })}
                    onOk={() => this.handleRemoveOK()}
                >
                    <p>{i18next.t('app_dialog_confirm_banner_remove')}</p>
                    <p><b>{removeBanner ? removeBanner.title : ''}</b></p>
                </Dialog>
            </Page>
        );
    }
}

const mapStateToProps = ({ banner }) => {
    return { store: { banner } };
};

const mapDispatchToProps = {
    fetchGetBanners,
    fetchPatchRemoveBanner,
    fetchPostReorder,
    initPage,
    setBanners,
};

const BannersPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedBannersPage);

export default BannersPage;
