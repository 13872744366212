import { initialState, type } from '../actions/artist.action';

const artist = (state = initialState, action) => {
    switch (action.type) {
        case type.ARTIST_FETCH_PENDING:
            return { ...state, loading: action.loading };

        case type.ARTIST_FETCH_GET_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                artist: action.artist,
                banner: action.banner,
                picture: action.picture,
            };

        case type.ARTIST_FETCH_GET_ARTISTS_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                countAll: action.countAll,
                artists: action.artists,
            };

        case type.ARTIST_FETCH_GET_POST_SUCCESS:
            return {
                ...state,
                post: action.post,
                picture: action.picture,
                loading: action.loading,
            };

        case type.ARTIST_FETCH_GET_STAKEHOLDERS_SUCCESS: {
            return {
                ...state,
                stakeholders: action.stakeholders,
            };
        }

        case type.ARTIST_FETCH_PATCH_REMOVE_SUCCESS:
            return { ...state, loading: action.loading };

        case type.ARTIST_FETCH_POST_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                artist: action.artist,
            };

        case type.ARTIST_FETCH_PUT_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                artist: action.artist,
                picture: action.picture,
                banner: action.banner,
                pictureFile: null,
                bannerFile: null,
            };

        case type.ARTIST_RESET:
            return {
                ...state,
                artist: action.artist,
                banner: null,
                bannerFile: null,
                picture: null,
                pictureFile: null,
            };

        case type.ARTIST_SET:
            return { ...state, artist: action.artist };

        case type.ARTIST_SET_BANNER:
            return {
                ...state,
                banner: action.banner ? URL.createObjectURL(action.banner) : null,
                bannerFile: action.banner,
            };

        case type.ARTIST_SET_CHAT_LAST_MESSAGE:
            return {
                ...state,
                chatLastMessage: action.chatLastMessage,
            };

        case type.ARTIST_SET_FILTER:
            return { ...state, filter: action.filter };

        case type.ARTIST_SET_PICTURE:
            return {
                ...state,
                picture: action.picture ? URL.createObjectURL(action.picture) : null,
                pictureFile: action.picture,
            };

        case type.ARTIST_TOGGLE_SUMMARY_LIST:
            return {
                ...state,
                summaryList: !state.summaryList,
            };

        case type.ARTIST_SET_ARTISTS:
            return {
                ...state,
                artists: action.artists,
            };

        default:
            return state;
    }
};

export default artist;
