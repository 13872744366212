import Config from '../../config';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { httpService as http } from '../../core/services/http.service';
import { fetchPostPicture, fetchPutPicture } from './app.action';
import { showError, showSuccess } from './message.action';
import moment from 'moment';

export const type = {
    ARTIST_FETCH_PENDING: 'ARTIST_FETCH_PENDING',
    ARTIST_FETCH_GET_SUCCESS: 'ARTIST_FETCH_GET_SUCCESS',
    ARTIST_FETCH_GET_ARTISTS_SUCCESS: 'ARTIST_FETCH_GET_ARTISTS_SUCCESS',
    ARTIST_FETCH_GET_POST_SUCCESS: 'ARTIST_FETCH_GET_POST_SUCCESS',
    ARTIST_FETCH_GET_STAKEHOLDERS_SUCCESS: 'ARTIST_FETCH_GET_STAKEHOLDERS_SUCCESS',
    ARTIST_FETCH_PATCH_REMOVE_SUCCESS: 'ARTIST_FETCH_PATCH_REMOVE_SUCCESS',
    ARTIST_FETCH_POST_SUCCESS: 'ARTIST_FETCH_POST_SUCCESS',
    ARTIST_FETCH_PUT_SUCCESS: 'ARTIST_FETCH_PUT_SUCCESS',
    ARTIST_POST_FETCH_PUT_SUCCESS: 'ARTIST_POST_FETCH_PUT_SUCCESS',
    ARTIST_RESET: 'ARTIST_RESET',
    ARTIST_SET: 'ARTIST_SET',
    ARTIST_SET_ARTISTS: 'ARTIST_SET_ARTISTS',
    ARTIST_SET_BANNER: 'ARTIST_SET_BANNER',
    ARTIST_SET_CHAT_LAST_MESSAGE: 'ARTIST_SET_CHAT_LAST_MESSAGE',
    ARTIST_SET_FILTER: 'ARTIST_SET_FILTER',
    ARTIST_SET_PICTURE: 'ARTIST_SET_PICTURE',
    ARTIST_TOGGLE_SUMMARY_LIST: 'ARTIST_TOGGLE_SUMMARY_LIST',
};

export const initialState = {
    artist: {
        id: null,
        about: {},
        name: '',
        banner: null,
        picture: null,
        tags: [],
        promote: false,
    },
    artists: [],
    banner: null,
    bannerFile: null,
    countAll: 0,
    filter: {
        deleted: 'false',
        limit: Config.paginator.DEFAULT_ROWS,
        offset: Config.paginator.DEFAULT_FIRST,
    },
    chatLastMessage: {},
    summaryList: false,
    loading: false,
    picture: null,
    pictureFile: null,
    posts: [],
    post: {
        id: null,
        artists: [],
        available: false,
        title: '',
        about: {},
        type: '',
        media: null,
        purchase: false,
        beginsAt: null,
        finishedAt: null,
        activeComments: false,
        tags: [],
        promote: false,
        picture: null,
    },
    showTags: false,
    stakeholders: [],
    tags: [],
};

const fetchPending = loading => {
    return { type: type.ARTIST_FETCH_PENDING, loading };
};

const fetchGetArtistSuccess = ({ artist, banner, picture }) => {
    return {
        type: type.ARTIST_FETCH_GET_SUCCESS,
        artist: {
            ...artist,
            tags: artist.tags.map(tag => { return { id: tag, name: tag }; }),
        },
        banner,
        picture,
        loading: false,
    };
};

export const fetchGetArtist = (artist, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/artists/${artist}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetArtistSuccess(response.data));
                    if (callback) {
                        callback(response.data.artist);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchGetArtistsSuccess = ({ artists, countAll }) => {
    return {
        type: type.ARTIST_FETCH_GET_ARTISTS_SUCCESS,
        artists,
        countAll,
        loading: false,
    };
};

export const fetchGetArtists = (options = {}, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        let query = '?all=true';
        if (Object.keys(options).length > 0) {
            query += options.find ? `&find=${options.find}` : '';
            query += options.limit ? `&limit=${options.limit}` : '';
            query += options.offset ? `&offset=${options.offset}` : '';
        }

        http.get(`/v1/bo/artists${query}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetArtistsSuccess(response.data));
                    if (callback) {
                        callback(response.data.artists);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchGetArtistPostSuccess = ({ post, picture }) => {
    return {
        type: type.ARTIST_FETCH_GET_POST_SUCCESS,
        post: {
            ...post,
            tags: post.tags.map(tag => { return { id: tag, name: tag }; }),
            beginsAt: post.beginsAt ? moment(post.beginsAt).toDate() : null,
            finishedAt: post.finishedAt ? moment(post.finishedAt).toDate() : null,
        },
        picture,
        loading: false,
    };
};

export const fetchGetArtistPost = (artistId, id) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/artists/${artistId}/posts/${id}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetArtistPostSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

const fetchGetArtistStakeholdersSuccess = ({ stakeholders }) => {
    return {
        type: type.ARTIST_FETCH_GET_STAKEHOLDERS_SUCCESS,
        loading: false,
        stakeholders,
    };
};

export const fetchGetArtistStakeholders = (id) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/artists/${id}/stakeholders`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetArtistStakeholdersSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

const fetchGetChatLastMessageSuccess = ({ chat: { message } }) => {
    return {
        type: type.ARTIST_SET_CHAT_LAST_MESSAGE,
        chatLastMessage: message ? JSON.parse(message) : {},
    };
};

export const fetchGetChatLastMessage = (artist, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/artists/${artist}/lastchat`).then(
            response => {
                dispatch(fetchPending(false));
                if (!response.ok) {
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetChatLastMessageSuccess(response.data));
                    if (callback) {
                        callback(response.data.chat);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchPatchRemoveSuccess = () => {
    return {
        type: type.ARTIST_FETCH_PATCH_REMOVE_SUCCESS,
        loading: false,
    };
};

export const fetchPatchRemove = id => {
    return async dispatch => {
        dispatch(fetchPending(true));
        const data = {};

        http.patch(`/v1/bo/artists/${id}/remove`, data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPatchRemoveSuccess());
                    dispatch(showSuccess({ summary: 'app_artist_removed' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchPostArtistSuccess = ({ artist }) => {
    return {
        type: type.ARTIST_FETCH_POST_SUCCESS,
        artist: {
            ...artist,
            tags: artist.tags.map(tag => { return { id: tag, name: tag }; }),
        },
        loading: false,
    };
};

export const fetchPostArtist = (artist, pictureFile, bannerFile) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        const { picture: pictureName } = await fetchPostPicture(pictureFile);
        const { picture: bannerName } = await fetchPostPicture(bannerFile);

        if (pictureName && bannerName) {
            const data = {
                ...artist,
                picture: pictureName,
                banner: bannerName,
                tags: artist.tags.map(tag => tag.name),
            };

            http.post('/v1/bo/artists', data).then(
                response => {
                    if (!response.ok) {
                        dispatch(fetchPending(false));
                        dispatch(showError(response.error));
                    } else {
                        dispatch(fetchPostArtistSuccess(response.data));
                        dispatch(showSuccess({ summary: 'app_artist_created' }));
                        history.push(`/artists/modify/${response.data.artist.id}`);
                    }
                },
                error => {
                    dispatch(fetchPending(false));
                    dispatch(showError(error.error));
                }
            );
        } else {
            dispatch(fetchPending(false));
            dispatch(showError({ error: { summary: 'app_picture_not_uploaded' } }));
        }
    };
};

const fetchPostChatSuccess = ({ chat: { message } }) => {
    return {
        type: type.ARTIST_SET_CHAT_LAST_MESSAGE,
        chatLastMessage: JSON.parse(message),
    };
};

export const fetchPostChat = (artist, message) => {
    return async dispatch => {
        dispatch(fetchPending(true));
        const data = { message };

        http.post(`/v1/bo/artists/${artist}/chat`, data).then(
            response => {
                dispatch(fetchPending(false));
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostChatSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchPutArtistSuccess = ({ artist, picture, banner }) => {
    return {
        type: type.ARTIST_FETCH_PUT_SUCCESS,
        artist: {
            ...artist,
            tags: artist.tags.map(tag => { return { id: tag, name: tag }; }),
        },
        picture,
        banner,
        loading: false,
    };
};

export const fetchPutArtist = (artist, pictureFile, bannerFile, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        let pictureName = artist.picture;

        if (pictureFile) {
            let result = {};

            if (artist.picture && artist.picture !== '') {
                result = await fetchPutPicture(artist.picture, pictureFile);
            } else {
                result = await fetchPostPicture(pictureFile);
            }

            pictureName = result.picture;
        }

        let bannerName = artist.banner;

        if (bannerFile) {
            let result = {};

            if (artist.banner && artist.banner !== '') {
                result = await fetchPutPicture(artist.banner, bannerFile);
            } else {
                result = await fetchPostPicture(bannerFile);
            }

            bannerName = result.picture;
        }

        if (pictureName && bannerName) {
            const data = {
                ...artist,
                picture: pictureName,
                banner: bannerName,
                tags: artist.tags.map(tag => tag.name),
            };

            http.put(`/v1/bo/artists/${artist.id}`, data).then(
                response => {
                    if (!response.ok) {
                        dispatch(fetchPending(false));
                        dispatch(showError(response.error));
                    } else {
                        dispatch(fetchPutArtistSuccess(response.data));
                        if (callback) {
                            callback(response.data);
                        }
                        dispatch(showSuccess({ summary: 'app_artist_updated' }));
                    }
                },
                error => {
                    dispatch(fetchPending(false));
                    dispatch(showError(error.error));
                }
            );
        } else {
            dispatch(fetchPending(false));
            dispatch(showError({ error: { summary: 'app_picture_not_uploaded' } }));
        }
    };
};

export const resetArtist = () => {
    return {
        type: type.ARTIST_RESET,
        artist: initialState.artist,
    };
};

export const setArtist = artist => {
    return { type: type.ARTIST_SET, artist };
};

export const setArtists = artists => {
    return { type: type.ARTIST_SET_ARTISTS, artists };
};

export const setBanner = banner => {
    return { type: type.ARTIST_SET_BANNER, banner };
};

export const setChatLastMessage = message => {
    return { type: type.ARTIST_SET_CHAT_LAST_MESSAGE, message };
};

export const setFilter = filter => {
    return { type: type.ARTIST_SET_FILTER, filter };
};

export const setPicture = picture => {
    return { type: type.ARTIST_SET_PICTURE, picture };
};

export const toggleSummaryList = () => {
    return { type: type.ARTIST_TOGGLE_SUMMARY_LIST };
};
