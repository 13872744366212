import React from 'react';
import { FileUpload } from 'primereact/fileupload';
import './input-file.component.css';

const InputFile = ({ accept, chooseLabel, className, disabled, label, name, uploadHandler }) => {
    return (
        <div className={`o-input-file ${className} ${disabled ? 'disabled' : ''}`}>
            {label && <label>{label}</label>}
            <FileUpload
                accept={accept || ''}
                auto
                chooseLabel={chooseLabel || 'Browse'}
                customUpload={true}
                disabled={disabled}
                mode="basic"
                name={name}
                uploadHandler={uploadHandler} />
        </div >
    );
};

export default InputFile;
