import React, { Component } from 'react';

import ArtistChat from './components/artist-chat.component';
import Config from '../config';
import Page from '../shared/components/page.component';
import { connect } from 'react-redux';
import { fetchGetArtist, fetchGetChatLastMessage, fetchPostChat, resetArtist } from '../store/actions/artist.action';
import { historyHelper as history } from '../core/helpers/history.helper';

import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import './artist-chat-page.component.css';

class ConnectedArtistChatPage extends Component {
    constructor(props) {
        super(props);
        this.handleAction = this.handleAction.bind(this);
        this.handlePostChat = this.handlePostChat.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        await this.props.fetchGetArtist(id, artist => {
            this.props.fetchGetChatLastMessage(artist.id);
        });
    }

    componentWillUnmount() {
        this.props.resetArtist();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    }

    handlePostChat(id, message) {
        this.props.fetchPostChat(id, message);
    }

    render() {
        const { store: { artist: { artist, loading, picture, chatLastMessage } } } = this.props;

        const pubnub = new PubNub({
            publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
            subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
            uuid: artist.id,
        });

        return (
            <Page
                title=""
                onClick={() => this.handleAction(Config.action.BACK, null)}
                loading={loading}
            >
                <PubNubProvider client={pubnub}>
                    <ArtistChat artist={artist} picture={picture} lastMessage={chatLastMessage} postChat={this.handlePostChat} />
                </PubNubProvider>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, artist }) => {
    return { store: { app, artist } };
};

const mapDispatchToProps = {
    fetchGetArtist,
    fetchGetChatLastMessage,
    fetchPostChat,
    resetArtist,
};

const ArtistChatPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedArtistChatPage);

export default ArtistChatPage;
