import React from 'react';
import './page-title.component.css';

const PageTitle = ({ onClick, title }) => {
    return (
        <div className='o-page-title-container'>
            <h1 style={{ textTransform: 'uppercase' }}>{title}</h1>
            {onClick && (
                <div className='o-page-title-back' onClick={onClick}>
                    <i className='fa fa-times menu-icon'></i>
                </div>
            )}
        </div>
    );
};

export default PageTitle;
