import React from 'react';
import { Dialog as DialogBox } from 'primereact/dialog';
import ButtonRounded from './form/button-rounded.component';
import './dialog.component.css';

const Dialog = ({ children, disabled, onCancel, onHide, onOk, onSubmit, onSubmitLabel, submitDisabled, text, visible }) => {
    return (
        <DialogBox showHeader={false} visible={visible} modal={true} onHide={onHide}>
            <div>{text}</div>
            <div>{children}</div>
            <div className='o-dialog-buttons'>
                {onCancel && <ButtonRounded className='secondary' disabled={disabled} label={'app_cancel'} onClick={onCancel} />}
                {onOk && <ButtonRounded disabled={disabled} label={'app_ok'} onClick={onOk} />}
                {onSubmit && <ButtonRounded disabled={disabled || submitDisabled} label={onSubmitLabel || 'app_validate'} onClick={onSubmit} />}
            </div>
        </DialogBox>
    );
};

export default Dialog;
