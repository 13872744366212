import React, { Component } from 'react';
import i18next from 'i18next';
import CguInformation from './components/cgu-information.component';
import Page from '../shared/components/page.component';
import { connect } from 'react-redux';
import { fetchGetCgu, fetchPostCgu, setCgu } from '../store/actions/admin.action';

class ConnectedAdminPage extends Component {
    constructor(props) {
        super(props);
        this.handleChangeCgu = this.handleChangeCgu.bind(this);
        this.handleSubmitCgu = this.handleSubmitCgu.bind(this);
    }

    componentDidMount() {
        const { fetchGetCgu } = this.props;
        Promise.all([fetchGetCgu()]);
    }

    handleChangeCgu(cgu) {
        this.props.setCgu(cgu);
    }

    handleSubmitCgu(cgu) {
        this.props.fetchPostCgu(cgu);
    }

    render() {
        const { store: { admin } } = this.props;

        return (
            <Page title={i18next.t('app_page_admin')} loading={admin.loading}>
                <div className='o-admin-item'>
                    <CguInformation
                        cgu={admin.cgu}
                        handleChangeDetail={cgu => this.handleChangeCgu(cgu)}
                        onSubmit={cgu => this.handleSubmitCgu(cgu)}
                        disabled={admin.loading}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ admin, app }) => {
    return { store: { admin, app } };
};

const mapDispatchToProps = {
    fetchGetCgu,
    fetchPostCgu,
    setCgu,
};

const AdminPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedAdminPage);

export default AdminPage;
