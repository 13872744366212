const Config = {
    action: {
        ADD: 'add',
        BACK: 'back',
        CHAT: 'chat',
        CLOSE: 'close',
        DELETE: 'delete',
        MANAGER: 'manager',
        MODIFY: 'modify',
        NOTIFY: 'notify',
        TECH: 'tech',
        USER: 'user',
    },
    active: {
        TYPE: [
            { label: 'app_active_all', value: 'all' },
            { label: 'app_active_ok', value: 'ok' },
            { label: 'app_active_not', value: 'not' },
        ],
    },
    app: {
        ACCESS: ['SUPERADMIN', 'ADMIN'],
        APIKEY: 'a34c3129-35cd-4816-82a1-9a77a405e077',
        DEFAULT_LOCALE: 'fr',
        role: {
            SUPER_ADMIN: 'superadmin',
            ADMIN: 'admin',
            USER: 'user',
        },
        version: {
            RELEASE: '21.7.13.0',
            NUMBER: 0,
        },
    },
    button: {
        rounded: {
            DEFAULT_FA: 'fa fa-mouse-pointer',
        },
    },
    inputText: {
        APP_ANSWER_MAX_LENGTH: 60,
        APP_NOTIFICATION_MAX_LENGTH: 140,
        APP_STATEMENT_MAX_LENGTH: 120,
        APP_QUESTION_COMMENT_MAX_LENGTH: 500,
        DEFAULT_MAX_LENGTH: 255,
    },
    http: {
        CODE_204: 'app_error_no_content',
        CODE_400: 'app_error_bad_request',
        CODE_403: 'app_error_forbidden',
        CODE_404: 'app_error_not_found',
        CODE_405: 'app_error_method_not_allowed',
        CODE_422: 'app_error_unprocessable_entity',
        CODE_500: 'app_error_internal_server_error',
        CODE_503: 'app_error_service_unavailable',
    },
    i18n: {
        EXPIRATION_TIME: process.env.REACT_APP_I18N_EXPIRATION_TIME,
    },
    locales: {
        ICON: {
            fr: 'fr',
            nl: 'nl',
            de: 'de',
            en: 'gb',
            es: 'es',
        },
        LIST: [
            { label: 'app_locale_fr', value: 'fr' },
            { label: 'app_locale_en', value: 'en' },
        ],
        LABELS: {
            fr: 'app_locale_fr',
            en: 'app_locale_en',
        },
        VALUES: 'en, fr',
    },
    paginator: {
        DEFAULT_FIRST: 0,
        DEFAULT_ROWS: 30,
    },
    post: {
        deleted: {
            LIST: [
                { label: 'app_post_deleted_false', value: 'false' },
                { label: 'app_post_deleted_true', value: 'true' },
            ],
        },
    },
    user: {
        status: {
            LIST: [
                { label: 'app_user_status_all', value: 'all' },
                { label: 'app_user_status_active', value: 'true' },
                { label: 'app_user_status_inactive', value: 'false' },
                { label: 'app_user_status_deleted', value: 'deleted' },
            ],
        },
    },
    shop: {
        ITEM_TYPES: [
            { label: 'app_shop_type_sticker', value: 'sticker' },
        ],

    },
    showMessage: {
        LIFE: 2000,
    },
    stakeholder: {
        TYPES: [
            { label: 'app_stakeholder_type_standard', value: 'standard' },
            { label: 'app_stakeholder_type_hey', value: 'hey' },
        ],
    },
};

export default Config;
