import React, { Component } from 'react';
import i18next from 'i18next';
import StoreCoinsTable from './components/storecoins-table.component';
import InputText from '../shared/components/form/input-text.component';
import Page from '../shared/components/page.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { historyHelper as history } from '../core/helpers/history.helper';
import { connect } from 'react-redux';
import { initPage } from '../store/actions/app.action';
import { fetchGetStoreCoins, setFilter } from '../store/actions/coin.action';
import './storecoins-page.component.css';

class ConnectedStoreCoinsPage extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleOnPageChange = this.handleOnPageChange.bind(this);
        this.handleOnKeyPress = this.handleOnKeyPress.bind(this);

        this.state = {
            artists: [],
            removeDialogVisible: false,
            removeStakeholder: null,
        };
    }

    async componentDidMount() {
        this.props.initPage('/storecoins');

        const { filter } = this.props.store.coin;
        this.props.fetchGetStoreCoins(filter);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { filter } = this.props.store.coin;

        if (value !== 'all') {
            filter[name] = value;
        } else {
            delete filter[name];
        }

        this.props.setFilter(filter);
        this.props.fetchGetStoreCoins(filter);
    }

    handleOnKeyPress(event) {
        if (event.key === 'Enter') {
            const { name, value } = event.target;
            if (value.length >= 3) {
                this.handleChange(event);
            } else {
                const { filter } = this.props.store.coin;
                if (value) {
                    filter[name] = value;
                } else {
                    if (filter[name] && filter[name].length > 0) {
                        delete filter[name];
                        this.handleChange(event);
                    }
                }
                this.props.setFilter(filter);
            }
        }
    }

    handleOnPageChange(first, rows) {
        const { filter } = this.props.store.coin;
        filter.limit = rows;
        filter.offset = first;
        this.props.setFilter(filter);
        this.props.fetchGetStoreCoins(filter);
    }

    render() {
        const { store: { coin: { storeCoins, loading, table: { rows } } } } = this.props;

        return (
            <Page title={i18next.t('app_page_storecoins')} loading={loading}>
                <div className='p-grid'>
                    <div className='p-col-12 p-md-6 p-lg-4'>
                        <InputText
                            name='find'
                            label={i18next.t('app_find')}
                            onKeyPress={this.handleOnKeyPress}
                            placeholder={i18next.t('app_find_min_char')}
                        />
                    </div>
                    <div className='p-col-12 p-md-6 p-lg-4 p-lg-offset-4 p-justify-end o-storecoins-new-button'>
                        <SubmitButton
                            label={i18next.t('app_storecoins_new')}
                            onClick={() => history.push('/storecoins/new')}
                        />
                    </div>
                </div>
                <div className='p-grid'>
                    <div className='p-col-12'>
                        <StoreCoinsTable
                            items={storeCoins}
                            rows={rows}
                        />
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = ({ coin }) => {
    return { store: { coin } };
};

const mapDispatchToProps = {
    initPage,
    fetchGetStoreCoins,
    setFilter,
};

const StoreCoinsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedStoreCoinsPage);

export default StoreCoinsPage;
