import React, { Component } from 'react';
import i18next from 'i18next';
import SectionDetail from './components/section-detail.component';
import Dialog from '../shared/components/dialog.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import InputSwitch from '../shared/components/form/input-switch.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { connect } from 'react-redux';
import {
    fetchGetSection,
    fetchPutSection,
    resetSection,
    setSection,
} from '../store/actions/section.action';
import { historyHelper as history } from '../core/helpers/history.helper';
import Config from '../config';
import './section-modify-page.component.css';

class ConnectedSectionModifyPage extends Component {
    constructor(props) {
        super(props);

        this.locales = Config.locales.LIST.map(item => {
            return {
                label: i18next.t(item.label),
                value: item.value,
            };
        });

        this.state = {
            dialogLocaleToRemove: '',
            dialogNextLocaleValues: [],
            dirty: false,
            isDialogVisible: false,
            localeValues: ['fr'],
            submit: false,
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDetail = this.handleChangeDetail.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleLocaleDialogCancel = this.handleLocaleDialogCancel.bind(this);
        this.handleLocaleDialogSubmit = this.handleLocaleDialogSubmit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        await this.props.fetchGetSection(id);
    }

    componentWillUnmount() {
        this.props.resetSection();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        const section = { ...this.props.store.section.section };
        section[name] = value;
        this.props.setSection(section);
        this.setState({ dirty: true });
    }

    handleChangeDetail(section) {
        this.props.setSection(section);
        this.setState({ dirty: true });
    }

    handleChangeLocales(event) {
        let localeValues = this.state.localeValues;
        const value = event.value;
        const eventLength = value.length;

        if (localeValues.length > eventLength && eventLength > 0) {
            const removedLocale = localeValues.filter(l => !value.includes(l))[0];
            this.setState({
                isDialogVisible: true,
                dialogLocaleToRemove: removedLocale,
                dialogNextLocaleValues: value,
            });
        }

        if (eventLength > 0 && localeValues.length <= eventLength) {
            localeValues = value;
            this.setState({ localeValues });
        }
    }

    handleLocaleDialogCancel() {
        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            dialogNextLocaleValues: [],
        });
    }

    handleLocaleDialogSubmit() {
        const { dialogLocaleToRemove } = this.state;
        const section = { ...this.props.store.section.section };

        if (section.title && typeof section.title[dialogLocaleToRemove] !== 'undefined') {
            delete section.title[dialogLocaleToRemove];
        }

        this.handleChangeDetail(section);

        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            localeValues: this.state.dialogNextLocaleValues,
            dialogNextLocaleValues: [],
        });
    }

    handleSubmit() {
        const { section } = this.props.store.section;
        if (Object.keys(section.title).length > 0 &&
            Object.values(section.title).every(n => n.trim() !== '')) {
            this.props.fetchPutSection(section, () => {
                this.setState({ dirty: false });
            });
        }
        this.setState({ submit: true });
    }

    render() {
        const { dirty, isDialogVisible, localeValues, submit } = this.state;
        const { store: { app: { locale }, section: { section, loading } } } = this.props;

        const sortDates = [
            { value: 'created_at', label: i18next.t('app_section_sort_date_created_at') },
            { value: 'onair_at', label: i18next.t('app_section_sort_date_onair_at') },
        ];

        return (
            <Page
                title={section.title[locale] || section.title[0]}
                onClick={() => this.handleAction(Config.action.BACK, null)}
                loading={loading}
            >
                <div className='o-section-modify'>
                    <Panel title={i18next.t('app_general_information')} toggleable={false}>
                        <div className='p-grid'>
                            <div className='p-col-12 p-xl-8 p-xl-offset-2'>
                                <div className='p-d-flex p-flex-column p-jc-start'>
                                    <InputMultiSelectInline
                                        name='locales'
                                        options={this.locales}
                                        onChange={this.handleChangeLocales}
                                        value={localeValues}
                                    />
                                    {localeValues.map(locale => {
                                        return (<SectionDetail
                                            key={`section_detail_${locale}`}
                                            section={section}
                                            lang={locale}
                                            onChange={this.handleChangeDetail}
                                            submit={submit}
                                            onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                                        />);
                                    })}
                                    <h3>{i18next.t('app_section_sort')}</h3>
                                    <div className='p-d-flex p-flex-wrap p-ac-center'>
                                        <InputMultiSelectInline
                                            name='sortDate'
                                            label={i18next.t('app_section_sort_date')}
                                            onChange={this.handleChange}
                                            value={section.sortDate}
                                            options={sortDates}
                                            multiple={false}
                                        />
                                        <InputSwitch
                                            name='sortAscending'
                                            label={i18next.t('app_section_sort_ascending')}
                                            checked={section.sortAscending}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <p style={{ margin: '0', padding: '0' }}>{i18next.t('app_section_sort_date_explanation')}</p>
                                    <div className='p-mt-6'>
                                        <InputSwitch
                                            name='active'
                                            label={i18next.t('app_section_active')}
                                            checked={section.active}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='p-d-flex p-jc-center p-mt-6'>
                                    <SubmitButton
                                        label={i18next.t('app_submit')}
                                        onClick={() => this.handleSubmit()}
                                        disabled={loading || !dirty}
                                    />
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <Dialog
                        visible={isDialogVisible}
                        onHide={() => this.handleLocaleDialogCancel()}
                        onCancel={() => this.handleLocaleDialogCancel()}
                        onSubmit={() => this.handleLocaleDialogSubmit()}
                        text={i18next.t('app_dialog_delete_locale')}
                    />
                </div>
            </Page >
        );
    }
}

const mapStateToProps = ({ app, section }) => {
    return { store: { app, section } };
};

const mapDispatchToProps = {
    fetchGetSection,
    fetchPutSection,
    resetSection,
    setSection,
};

const SectionModifyPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedSectionModifyPage);

export default SectionModifyPage;
