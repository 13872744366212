import React, { useState } from 'react';
import i18next from 'i18next';
import Config from '../../config';
import Panel from '../../shared/components/panel.component';
import Textarea from '../../shared/components/form/textarea.component';

const PostDetail = ({ lang, about, onChange }) => {
    const [collapsed] = useState(false);

    const handleChange = event => {
        const value = about || {};
        value[lang] = event.currentTarget.value;
        onChange(value);
    };

    const value = about ? about[lang] : null;

    return (
        <Panel
            title={i18next.t(Config.locales.LABELS[lang])}
            kind='detail'
            toggleable={true}
            collapsed={collapsed}
        >
            <Textarea
                name='about'
                label={i18next.t('app_artist_post_about')}
                value={value}
                onChange={handleChange}
            />
        </Panel>
    );
};

export default PostDetail;
