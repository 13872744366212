import React, { Component } from 'react';
import i18next from 'i18next';
import InputText from '../shared/components/form/input-text.component';
import ShopTable from './components/shop-table.component';
import Dialog from '../shared/components/dialog.component';
import Page from '../shared/components/page.component';
import { historyHelper as history } from '../core/helpers/history.helper';
import { connect } from 'react-redux';
import { initPage } from '../store/actions/app.action';
import {
    fetchGetItems,
    fetchPatchRemoveItem,
    setItems,
} from '../store/actions/shop.action';
import SubmitButton from '../shared/components/form/submit-button.component';
import Config from '../config';
import _ from 'lodash';
import './shop-page.component.css';

class ConnectedShopPage extends Component {
    constructor(props) {
        super(props);
        this.handleAction = this.handleAction.bind(this);
        this.handleFind = this.handleFind.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRemoveOK = this.handleRemoveOK.bind(this);

        this.state = {
            find: '',
            items: [],
            removeDialogVisible: false,
            removeItem: null,
        };
    }

    async componentDidMount() {
        this.props.initPage('/shop');
        this.props.fetchGetItems({}, items => {
            this.setState({ items });
        });
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.ADD:
                history.push('/shop/new');
                break;

            case Config.action.MODIFY:
                history.push(`/shop/modify/${data.id}`);
                break;

            default:
        }
    }

    handleFind(event) {
        const find = event.target.value;
        const { items } = this.props.store.shop;
        let list = [...items];

        if (find.length >= 3) {
            list = _.filter(items, item => {
                const type = item.type ? i18next.t(`app_shop_type_${item.type}`) : '';

                const artists = _.find(item.artists, artist => {
                    return artist.name.includes(find);
                });

                return item.label.includes(find) || type.includes(find) || typeof artists !== 'undefined';
            });
        }

        this.setState({ items: list });
    }

    handleRemove(item) {
        this.setState({ removeDialogVisible: true, removeItem: item });
    }

    handleRemoveOK() {
        const { removeItem } = this.state;
        const { items } = this.props.store.shop;
        const index = items.findIndex(e => e.id === removeItem.id);
        items.splice(index, 1);
        this.props.setItems(items);
        this.props.fetchPatchRemoveItem(removeItem.id);
        this.setState({ removeDialogVisible: false, removeItem: null });
    }

    render() {
        const { find, items, removeDialogVisible, removeItem } = this.state;

        const {
            store: { loading },
        } = this.props;

        return (
            <Page title={i18next.t('app_page_shop')} loading={loading}>
                <div className='p-grid'>
                    <div className='p-col-3'>
                        <InputText
                            name='find'
                            label={i18next.t('app_find')}
                            onKeyUp={e => this.handleFind(e)}
                            placeholder={i18next.t('app_find_min_char')}
                            value={find}
                        />
                    </div>
                    <div className='p-col-9 o-new-button'>
                        <SubmitButton
                            label={i18next.t('app_shop_new')}
                            onClick={() => history.push('/shop/new')}
                        />
                    </div>
                </div>
                <div className='p-grid'>
                    <div className='p-col-12'>
                        <ShopTable
                            items={items}
                            onRemove={this.handleRemove}
                        />
                    </div>
                </div>
                <Dialog
                    visible={removeDialogVisible}
                    onCancel={() => this.setState({ removeDialogVisible: false, removeItem: null })}
                    onHide={() => this.setState({ removeDialogVisible: false, removeItem: null })}
                    onOk={() => this.handleRemoveOK()}
                >
                    <p>{i18next.t('app_dialog_confirm_shop_item_remove')}</p>
                    <p><b>{removeItem ? removeItem.label : ''}</b></p>
                </Dialog>
            </Page>
        );
    }
}

const mapStateToProps = ({ shop }) => {
    return { store: { shop } };
};

const mapDispatchToProps = {
    fetchGetItems,
    fetchPatchRemoveItem,
    initPage,
    setItems,
};

const ShopPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedShopPage);

export default ShopPage;
