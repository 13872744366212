import React, { Component } from 'react';
import i18next from 'i18next';
import Config from '../config';
import InputNumber from '../shared/components/form/input-number.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { showError } from '../store/actions/message.action';
import {
    fetchGetPost,
    fetchGetStakeholder,
    fetchPutStakeholder,
    resetPost,
    resetStakeholder,
    setStakeholder,
} from '../store/actions/post.action';
import './post-stakeholder-page.component.css';

class ConnectedPostStakeholderPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dirty: false,
            total: 0,
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const { postid, id } = this.props.match.params;
        const { participationRate } = this.props.store.post;

        this.props.fetchGetPost(postid);
        this.props.fetchGetStakeholder(postid, id);
        this.setState({ total: participationRate });
    }

    componentWillUnmount() {
        this.props.resetPost();
        this.props.resetStakeholder();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { stakeholder } = this.props.store.post;
        stakeholder[name] = value;
        this.props.setStakeholder(stakeholder);
        this.setState({ dirty: true });
    }

    handleSubmit() {
        const { post, stakeholder } = this.props.store.post;
        this.props.fetchPutStakeholder(post.id, stakeholder);
    }

    render() {
        const { dirty } = this.state;
        const { loading, stakeholder } = this.props.store.post;

        return (
            <Page
                title={`${i18next.t('app_page_stakeholder')} : ${stakeholder.name}`}
                onClick={() => this.handleAction(Config.action.BACK, null)}
                loading={loading}
            >
                <div className='o-post-stakeholder-page'>
                    <Panel title={i18next.t('app_general_information')} toggleable={false}>
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className='p-grid'>
                                    <div className='p-col-4 o-add-stakeholder-item'>
                                        <div className='o-add-stakeholder-item-share'>
                                            <InputNumber
                                                label={i18next.t('app_stakeholder_share')}
                                                min={0}
                                                max={100}
                                                name='share'
                                                onChange={(e) => this.handleChange(e)}
                                                value={stakeholder.share} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className='p-grid'>
                                    <div className='p-col-4 p-offset-8'>
                                        <SubmitButton
                                            label={i18next.t('app_submit')}
                                            onClick={() => this.handleSubmit()}
                                            disabled={loading || !dirty}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div>
            </Page >
        );
    }
}

const mapStateToProps = ({ app, post }) => {
    return { store: { app, post } };
};

const mapDispatchToProps = {
    fetchGetPost,
    fetchGetStakeholder,
    fetchPutStakeholder,
    resetPost,
    resetStakeholder,
    setStakeholder,
    showError,
};

const PostStakeholderPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedPostStakeholderPage);

export default PostStakeholderPage;
