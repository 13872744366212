import { combineReducers } from 'redux';
import admin from './admin.reducer';
import amazon from './amazon.reducer';
import app from './app.reducer';
import artist from './artist.reducer';
import banner from './banner.reducer';
import coin from './coin.reducer';
import notification from './notification.reducer';
import post from './post.reducer';
import section from './section.reducer';
import shop from './shop.reducer';
import showMessages from './message.reducer';
import stakeholder from './stakeholder.reducer';
import statistic from './statistic.reducer';
import tag from './tag.reducer';
import user from './user.reducer';

export default combineReducers({
    admin,
    amazon,
    artist,
    app,
    banner,
    coin,
    notification,
    post,
    section,
    shop,
    showMessages,
    stakeholder,
    statistic,
    tag,
    user,
});
