import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './panel.component.css';

const Panel = ({ children, collapsed, title, toggleable }) => {
    const [collapse, setCollapse] = useState(collapsed || false);

    const styleCollapse = collapse ? { borderRadius: '1rem' } : {};
    const titleClass = collapse ? 'o-panel-children-collapsed' : 'o-panel-children-active';
    const icon = collapse ? 'fas fa-plus' : 'fas fa-minus';

    return (
        <div className='o-panel'>
            <div className='o-panel-title' style={styleCollapse}>
                {title}
                {toggleable && (
                    <div className='o-panel-title-icon' onClick={() => setCollapse(!collapse)}>
                        <i className={icon}></i>
                    </div>
                )}
            </div>
            <div className={`o-panel-children ${titleClass}`}>
                {!collapse && <div>{children}</div>}
            </div>
        </div>
    );
};

Panel.propTypes = {
    title: PropTypes.string,
    collapsed: PropTypes.bool,
    toggleable: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

Panel.defaultProps = {
    collapsed: false,
    toggleable: false,
    onClick: () => {},
};

export default Panel;
