import { initialState, type } from '../actions/amazon.action';

const amazon = (state = initialState, action) => {
    switch (action.type) {
        case type.AMAZON_FETCH_PENDING:
            return { ...state, loading: action.loading };
        case type.AMAZON_FETCH_UPLOAD_VIDEO_SUCCESS:
            return { ...state, loading: action.loading };
        default:
            return state;
    }
};

export default amazon;
