import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import InputText from '../shared/components/form/input-text.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import { fetchPostNotification } from '../store/actions/notification.action';
import SubmitButton from '../shared/components/form/submit-button.component';
import Config from '../config';
import './notification-create-page.component.css';

class ConnectedNotificationCreatePage extends Component {
    constructor(props) {
        super(props);

        this.locales = Config.locales.LIST.map(item => {
            return {
                label: i18next.t(item.label),
                value: item.value,
            };
        });

        this.state = {
            dirty: false,
            localeValues: [Config.app.DEFAULT_LOCALE],
            notification: {},
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    }

    handleChange(locale, event) {
        let { notification } = this.state;
        notification = { ...notification, [locale]: event.target.value };
        this.setState({ notification });
    }

    handleChangeLocales(event) {
        let localeValues = this.state.localeValues;
        if (event.value.length > 0) {
            localeValues = event.value;
            this.setState({ localeValues });
        }
    }

    handleSubmit() {
        const { notification } = this.state;

        const {
            store: {
                app,
                notification: { filter },
            },
        } = this.props;

        if (Object.keys(notification).length > 0) {
            this.props.fetchPostNotification(filter, notification);
            history.push(app.lastPage);
        }

        this.setState({ dirty: true });
    }

    render() {
        const { dirty } = this.state;

        const { store: { app, notification, style } } = this.props;

        const dynamicDetails = this.state.localeValues.map(locale => {
            const value = this.state.notification ? this.state.notification[locale] : '';

            return (
                <div className='o-notification-create-details-item' key={locale}>
                    <Panel
                        title={i18next.t(Config.locales.LABELS[locale])}
                        styles={style}
                        kind='detail'
                    >
                        <InputText
                            name='name'
                            label={i18next.t('app_text')}
                            value={value}
                            onChange={e => this.handleChange(locale, e)}
                            onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                            styles={style}
                            placeholder={i18next.t('app_notification_text_placeholder')}
                        />
                        {dirty && (value === '' || !value) && (
                            <div className='error'>*{i18next.t('app_error_notification_mandatory_description')}
                            </div>
                        )}
                    </Panel>
                </div>
            );
        });

        return (
            <Page
                title={i18next.t('app_page_notification')}
                loading={notification.loading}
                onClick={() => this.handleAction(Config.action.BACK, null)}
            >
                <Panel
                    title={i18next.t('app_message')}
                    styles={style}
                    toggleable={false}
                >
                    <div className='o-notification-create'>
                        <div className='p-grid'>
                            <div className='p-col-10 p-offset-1'>
                                <div className='p-grid'>
                                    <div className='p-col-12 o-notification-create-user-element'>
                                        <div className='o-notification-create-user-count'>
                                            {new Intl.NumberFormat(app.locale).format(
                                                notification.count
                                            )}{' '}
                                            {i18next.t('app_user_s')}
                                        </div>
                                    </div>
                                </div>
                                <div className='p-grid'>
                                    <div className='p-col-6'>
                                        <InputMultiSelectInline
                                            name='locales'
                                            value={this.state.localeValues}
                                            options={this.locales}
                                            onChange={this.handleChangeLocales}
                                            styles={style}
                                        />
                                    </div>
                                </div>
                                {dynamicDetails}
                                <div className='p-grid'>
                                    <div className='p-col-6 p-offset-6'>
                                        <SubmitButton
                                            label={i18next.t('app_validate')}
                                            styles={style}
                                            onClick={() => this.handleSubmit()}
                                            disabled={notification.loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, notification }) => {
    return { store: { app, notification } };
};

const mapDispatchToProps = {
    fetchPostNotification,
};

const NotificationCreatePage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedNotificationCreatePage);

export default NotificationCreatePage;
