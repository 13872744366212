import React from 'react';
import './textarea.component.css';

const Textarea = ({ disabled = false, label, name, onChange, onKeyPress, placeholder = '', value, rows = 10, cols = 5 }) => {
    return (
        <div className='o-textarea'>
            {label && <label>{label}</label>}
            <textarea
                cols={cols}
                disabled={disabled === true ? 'disabled' : false}
                name={name}
                onChange={onChange}
                onKeyPress={onKeyPress}
                placeholder={placeholder}
                rows={rows}
                value={value} />
        </div>
    );
};

export default Textarea;
