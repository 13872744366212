import React from 'react';
import i18next from 'i18next';
import ActionIcon from '../../shared/components/action-icon.component';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const ShopTable = ({ items, onRemove }) => {
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="p-d-flex p-jc-between">
                <ActionIcon
                    icon="fas fa-edit"
                    label={i18next.t('app_tip_edit')}
                    onClick={() => history.push(`/shop/modify/${rowData.id}`)}>
                </ActionIcon>
                <ActionIcon
                    icon="fas fa-archive"
                    label={i18next.t('app_tip_archive')}
                    onClick={() => onRemove(rowData)}>
                </ActionIcon>
            </div>
        );
    };

    const pictureBodyTemplate = (rowData) => {
        const stylePicture = {
            backgroundImage: rowData.picture
                ? `url('${rowData.picture}?h=50&w=50')`
                : '',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '1rem',
            height: '1rem',
        };
        return <div style={stylePicture}></div>;
    };

    const typeBodyTemplate = (rowData) => {
        return rowData.type ? i18next.t(`app_shop_type_${rowData.type}`) : '';
    };

    const artistsBodyTemplate = (rowData) => {
        let artists = '';
        if (rowData.artists) {
            for (let i = 0; i < rowData.artists.length; i++) {
                if (i !== 0) {
                    artists += ', ';
                };

                artists += rowData.artists[i].name;
            }
        }
        return artists;
    };

    const activeBodyTemplate = (rowData) => {
        return rowData.active ? <i className="fas fa-check-circle" /> : '';
    };

    return (
        <div className='o-shop-table'>
            <DataTable value={items} reorderableColumns>
                <Column
                    body={pictureBodyTemplate}
                    field="picture"
                    headerClassName=""
                    style={{ width: '3rem' }}>
                </Column>
                <Column
                    field="label"
                    header={i18next.t('app_shop_label')}
                    headerClassName="">
                </Column>
                <Column
                    body={typeBodyTemplate}
                    field="type"
                    header={i18next.t('app_shop_type')}
                    style={{ textAlign: 'center', width: '8rem' }}>
                </Column>
                <Column
                    body={artistsBodyTemplate}
                    field="type"
                    header={i18next.t('app_shop_artists')}
                    headerClassName="">
                </Column>
                <Column
                    body={activeBodyTemplate}
                    field="active"
                    header={i18next.t('app_shop_active')}
                    style={{ textAlign: 'center', width: '8rem' }}>
                </Column>
                <Column className="o-shop-table-action" style={{ textAlign: 'center', width: '4.4rem' }} body={actionBodyTemplate}></Column>
            </DataTable>
        </div>
    );
};

export default ShopTable;
