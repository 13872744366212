import React, { Component } from 'react';
import i18next from 'i18next';
import ImageUpload from '../shared/components/form/image-upload.component';
import InputMultiSelect from '../shared/components/form/input-multiselect.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import InputNumber from '../shared/components/form/input-number.component';
import InputText from '../shared/components/form/input-text.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { connect } from 'react-redux';
import {
    fetchGetArtists,
} from '../store/actions/artist.action';
import {
    fetchPostItem,
    resetItem,
    setItem,
    setPicture,
} from '../store/actions/shop.action';
import { historyHelper as history } from '../core/helpers/history.helper';
import Config from '../config';
import './item-create-page.component.css';

class ConnectedItemCreatePage extends Component {
    constructor(props) {
        super(props);

        const types = Config.shop.ITEM_TYPES.map(item => {
            return {
                value: item.value,
                label: i18next.t(item.label),
            };
        });

        this.state = {
            dirty: false,
            submit: false,
            types,
            artists: [],
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePictureOnChange = this.handlePictureOnChange.bind(this);
        this.handlePictureOnClick = this.handlePictureOnClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        await this.props.fetchGetArtists({}, artists => {
            this.setState({ artists: artists.map(artist => { return { label: artist.name, value: artist.id }; }) });
        });
    }

    componentWillUnmount() {
        this.props.resetItem();
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    }

    async handleChange(event) {
        const { name, value } = event.target;
        const item = { ...this.props.store.shop.item };

        if (name === 'type' && !value) {
            return true;
        }

        if (name === 'artists') {
            item.artists = value.map(id => { return { id }; });
            this.props.setItem(item);
            this.setState({ dirty: true });
        } else {
            const item = { ...this.props.store.shop.item };
            item[name] = value;
            this.props.setItem(item);
            this.setState({ dirty: true });
        }
    }

    handlePictureOnChange(files) {
        this.setState({ dirty: true });
        const file = files[0];
        if (file) {
            this.props.setPicture(file);
        }
    }

    handlePictureOnClick() {
        this.setState({ dirty: true });
        this.props.setPicture(null);
    }

    handleSubmit() {
        const { item, pictureFile } = this.props.store.shop;
        if (item.label.length > 0 &&
            pictureFile &&
            pictureFile.name) {
            this.props.fetchPostItem(item, pictureFile);
        }
        this.setState({ submit: true });
    }

    render() {
        const { artists, dirty, submit, types } = this.state;
        const { store: { shop: { item, loading, picture, pictureFile } } } = this.props;

        const itemArtists = item.artists.map(artist => {
            return artist.id;
        });

        return (
            <Page
                title={i18next.t('app_page_item_create')}
                onClick={() => this.handleAction(Config.action.BACK, null)}
                loading={loading}
            >
                <div className='o-item-create'>
                    <Panel title={i18next.t('app_general_information')} toggleable={false}>
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className='p-grid'>
                                    <div className='p-col-12 p-xl-6'>
                                        <InputMultiSelectInline
                                            label={i18next.t('app_shop_type')}
                                            name='type'
                                            value={item.type}
                                            options={types}
                                            onChange={this.handleChange}
                                            multiple={false}
                                        />
                                    </div>
                                </div>
                                <div className='p-grid p-dir-rev'>
                                    <div className='p-col-12 p-xl-6 p-d-flex p-flex-column p-ai-center'>
                                        <div className="o-item-create-picture">
                                            <ImageUpload
                                                name='picture'
                                                image={picture}
                                                label={i18next.t('app_shop_picture')}
                                                onClick={this.handlePictureOnClick}
                                                onChange={this.handlePictureOnChange}
                                                ratio={1}
                                            />
                                        </div>
                                        {submit && !pictureFile && (
                                            <div className='error'>* {i18next.t('app_picture_mandatory')}</div>
                                        )}
                                    </div>
                                    <div className='p-col-12 p-xl-6'>
                                        <div className=''>
                                            <InputText
                                                name='label'
                                                label={i18next.t('app_shop_label')}
                                                value={item.label}
                                                onChange={this.handleChange}
                                                onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                                            />
                                            {submit && !item.label && (
                                                <div className='error'>*{i18next.t('app_error_item_mandatory_label')}</div>
                                            )}
                                        </div>
                                        <div className=''>
                                            <InputMultiSelect
                                                name='artists'
                                                label={i18next.t('app_artists')}
                                                options={artists}
                                                values={itemArtists}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className=''>
                                            <InputNumber
                                                name='value'
                                                label={i18next.t('app_shop_value')}
                                                value={item.value}
                                                onChange={this.handleChange}
                                                min={0}
                                                step={1}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='p-d-flex p-jc-center p-mt-4'>
                                    <SubmitButton
                                        label={i18next.t('app_submit')}
                                        onClick={() => this.handleSubmit()}
                                        disabled={loading || !dirty}
                                    />
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div >
            </Page >
        );
    }
}

const mapStateToProps = ({ app, shop }) => {
    return { store: { app, shop } };
};

const mapDispatchToProps = {
    fetchGetArtists,
    fetchPostItem,
    resetItem,
    setItem,
    setPicture,
};

const ItemCreatePage = connect(mapStateToProps, mapDispatchToProps)(ConnectedItemCreatePage);

export default ItemCreatePage;
