/* eslint-disable no-this-before-super */
import React, { Component } from 'react';
import i18next from 'i18next';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { fetchVerify } from '../store/actions/user.action';

import './email-validation-page.component.css';

class ConnectedEmailValidationPage extends Component {
    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        this.props.fetchVerify(query.token);
    }

    render() {
        const { user } = this.props.store;

        return (
            <div className='o-email-validation'>
                <div className='o-email-validation-logo'>
                    <img
                        src='templates/default/images/logo-200.png'
                        alt='logo'
                        style={{ width: '100%' }}
                        srcSet='templates/default/images/logo-100.png 100w,
                                templates/default/images/logo-200.png 200w,
                                templates/default/images/logo-500.png 500w'
                    />
                </div>
                <div className='o-email-validation-container'>
                    {user.loading && <i className='pi pi-spin pi-spinner'></i>}
                    {!user.loading && user.account.checked && (
                        <span>{i18next.t('app_user_email_valid')}</span>
                    )}
                    {!user.loading && !user.account.checked && (
                        <span>
                            {i18next.t('app_user_email_not_valid')}
                        </span>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return { store: { user } };
};

const mapDispatchToProps = {
    fetchVerify,
};

const EmailValidationPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedEmailValidationPage);

export default EmailValidationPage;
