import React, { Component } from 'react';
import i18next from 'i18next';
import InputText from '../shared/components/form/input-text.component';
import InputCompletionText from '../shared/components/form/input-completion-text.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
// import InputDatetime from '../shared/components/form/input-datetime2.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { fetchGetArtist, fetchGetArtists } from '../store/actions/artist.action';
import {
    fetchPostPost,
    getTypes,
    resetPost,
    setPost,
} from '../store/actions/post.action';
import Config from '../config';
import './post-create-page.component.css';

class ConnectedPostCreatePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dirty: false,
            submit: false,
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAddArtist = this.handleAddArtist.bind(this);
        this.handleRemoveArtist = this.handleRemoveArtist.bind(this);
        this.handleSearchArtists = this.handleSearchArtists.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        const { post } = this.props.store.post;

        post.type = 'video';

        await this.props.fetchGetArtists({}, artists => {
            const artistList = artists.map(artist => {
                if (id && id === artist.id) {
                    post.artists.push(artist);
                }

                return { label: artist.name, value: artist.id };
            });

            this.setState({ artists: artistList });
        });

        this.props.setPost(post);
    }

    componentWillUnmount() {
        this.props.resetPost();
    }

    handleAction = (action, data) => {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        const post = { ...this.props.store.post.post };

        post[name] = value;
        this.props.setPost(post);
        this.setState({ dirty: true });
    };

    handleAddArtist = (artist) => {
        const post = { ...this.props.store.post.post };
        const artists = post.artists;
        artists.push(artist);
        post.artists = artists;
        this.props.setPost(post);
        this.setState({ showArtists: false, dirty: true });
    };

    handleRemoveArtist = (index) => {
        const { post } = this.props.store.post;
        const artists = post.artists;
        artists.splice(index, 1);
        post.artists = artists;
        this.props.setPost(post);
        this.setState({ dirty: true });
    };

    handleSearchArtists = async (query) => {
        const post = { ...this.props.store.post.post };
        const included = id => {
            return post.artists.filter(artist => artist.id === id).length !== 0;
        };

        await this.props.fetchGetArtists({ find: query }, artists => {
            if (artists === null) {
                this.setState({ showArtists: false });
                return;
            }
            this.setState({ showArtists: true, artists: artists.filter(artist => !included(artist.id)) });
        });
    };

    handleSubmit = () => {
        const { post } = this.props.store.post;
        if (post.title.trim().replace(/  +/g, ' ').length > 0 &&
            post.artists.length > 0) {
            this.props.fetchPostPost(post);
        }
        this.setState({ submit: true });
    };

    render() {
        const { dirty } = this.state;
        const { store: { post: { loading, post } } } = this.props;

        const types = this.props.getTypes().map(type => { return { ...type, label: i18next.t(type.label) }; });

        return (
            <Page
                title={i18next.t('app_page_post_create')}
                onClick={() => this.handleAction(Config.action.BACK, null)}
                loading={loading}
            >
                <Panel title={i18next.t('app_general_information')} toggleable={false}>
                    <div className='o-artist-create'>
                        <div className='p-grid'>
                            <div className='p-col-8'>
                                <InputMultiSelectInline
                                    name='type'
                                    label={i18next.t('app_post_type')}
                                    onChange={this.handleChange}
                                    value={post.type}
                                    options={types}
                                    multiple={false}
                                />
                                {this.state.submit && !post.type && (
                                    <div className='error'>
                                        *
                                        {i18next.t('app_error_post_mandatory_type')}
                                    </div>
                                )}
                                <InputText
                                    name='title'
                                    label={i18next.t('app_post_title')}
                                    value={post.title}
                                    onChange={this.handleChange}
                                    onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                                />
                                {(this.state.submit && post.title.trim().replace(/  +/g, ' ').length < 8) && (
                                    <div className='error'>
                                        *
                                        {i18next.t('app_error_post_mandatory_title')}
                                    </div>
                                )}
                                <InputCompletionText
                                    name='artists'
                                    label={i18next.t('app_post_artists')}
                                    onSearch={this.handleSearchArtists}
                                    onCancel={() => this.setState({ showArtists: false })}
                                    onAdd={this.handleAddArtist}
                                    onRemove={this.handleRemoveArtist}
                                    options={this.state.showArtists ? this.state.artists : []}
                                    values={post.artists}
                                    editable={false}
                                />
                                {this.state.submit && post.artists.length === 0 && (
                                    <div className='error'>
                                        *
                                        {i18next.t('app_error_post_mandatory_artist')}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='p-grid'>
                            <div className='p-col-8'></div>
                            <div className='p-col-4'>
                                <SubmitButton
                                    label={i18next.t('app_submit')}
                                    onClick={() => this.handleSubmit()}
                                    disabled={loading || !this.state.dirty}
                                />
                            </div>
                        </div>
                    </div>
                </Panel>
            </Page >
        );
    }
}

const mapStateToProps = ({ app, post }) => {
    return { store: { app, post } };
};

const mapDispatchToProps = {
    fetchGetArtist,
    fetchGetArtists,
    fetchPostPost,
    getTypes,
    resetPost,
    setPost,
};

const PostCreatePage = connect(mapStateToProps, mapDispatchToProps)(ConnectedPostCreatePage);

export default PostCreatePage;
