import React from 'react';
import displayDate from '../../shared/components/display-date';
import i18next from 'i18next';
import moment from 'moment';
import Config from '../../config';
import ActionIcon from '../../shared/components/action-icon.component';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const PostTable = ({ locale, onRemove, posts, filter: { deleted } }) => {
    moment.locale(locale || Config.app.DEFAULT_LOCALE);

    const typeBodyTemplate = (rowData) => {
        return rowData.type ? i18next.t(`app_post_type_${rowData.type}`) : '';
    };

    const titleBodyTemplate = (rowData) => {
        let artists = '';
        if (rowData.artists) {
            for (let i = 0; i < rowData.artists.length; i++) {
                if (i !== 0) {
                    artists += ', ';
                };

                artists += rowData.artists[i].name;
            }
        }

        return <>
            <div style={{ fontWeight: '600' }}>{rowData.title}</div>
            <div style={{ color: 'var(--disabled-color)' }}>{artists}</div>
        </>;
    };

    const purchaseBodyTemplate = (rowData) => {
        return <>
            <span className="p-column-title">{i18next.t('app_post_purchase')}</span>
            {rowData.purchase ? <i className="fas fa-check-circle" /> : ''}
        </>;
    };

    const onAirBodyTemplate = (rowData) => {
        return <>
            <span className="p-column-title">{i18next.t('app_post_on_air')}</span>
            {rowData.media && rowData.media.live && rowData.media.live.onAir ? <i className="fas fa-check-circle" /> : ''}
        </>;
    };

    const availableBodyTemplate = (rowData) => {
        return <>
            <span className="p-column-title">{i18next.t('app_post_available')}</span>
            {rowData.available ? <i className="fas fa-check-circle" /> : ''}
        </>;
    };

    const beginsBodyTemplate = (rowData) => {
        return <>
            <span className="p-column-title">{i18next.t('app_post_begins_at')}</span>
            {displayDate(rowData.beginsAt)}
        </>;
    };

    const finishedBodyTemplate = (rowData) => {
        return <>
            <span className="p-column-title">{i18next.t('app_post_finished_at')}</span>
            {displayDate(rowData.finishedAt)}
        </>;
    };

    const commentBodyTemplate = (rowData) => {
        return <>
            <span className="p-column-title">{i18next.t('app_post_active_comments')}</span>
            {rowData.activeComments ? <i className="fas fa-check-circle" /> : ''}
        </>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                {deleted !== 'true' && (
                    <div className="p-d-flex p-jc-end p-jc-xl-between">
                        <ActionIcon
                            icon="fas fa-edit"
                            label={i18next.t('app_tip_edit')}
                            onClick={() => history.push(`/posts/modify/${rowData.id}`)}>
                        </ActionIcon>
                        <ActionIcon
                            icon="fas fa-archive"
                            label={i18next.t('app_tip_archive')}
                            onClick={() => onRemove(rowData)}
                            style={{ marginLeft: '.5rem' }}>
                        </ActionIcon>
                    </div>
                )}
                {deleted === 'true' && (
                    <div className="p-d-flex p-jc-center">
                        <ActionIcon
                            icon="fas fa-trash-restore-alt"
                            label={i18next.t('app_tip_restore')}
                            onClick={() => onRemove(rowData)}>
                        </ActionIcon>
                    </div>
                )}
            </>
        );
    };

    return (
        <div className='o-table-responsive'>
            <DataTable value={posts} className='p-datatable-responsive'>
                <Column
                    body={typeBodyTemplate}
                    field="type"
                    header={i18next.t('app_post_type')}
                    style={{ width: '5rem', textAlign: 'center' }}>
                </Column>
                <Column
                    body={titleBodyTemplate}
                    field="title"
                    header={i18next.t('app_post_title')}
                    style={{ height: '3.8rem' }}>
                </Column>
                {deleted !== 'true' && (<Column
                    body={purchaseBodyTemplate}
                    field="purchase"
                    header={i18next.t('app_post_purchase')}
                    style={{ width: '5rem', textAlign: 'center' }}>
                </Column>)}
                {deleted !== 'true' && (<Column
                    body={onAirBodyTemplate}
                    field="purchase"
                    header={i18next.t('app_post_on_air')}
                    style={{ width: '5rem', textAlign: 'center' }}>
                </Column>)}
                {deleted !== 'true' && (<Column
                    body={availableBodyTemplate}
                    field="available"
                    header={i18next.t('app_post_available')}
                    style={{ width: '8rem', textAlign: 'center' }}>
                </Column>)}
                {deleted !== 'true' && (<Column
                    body={beginsBodyTemplate}
                    field="beginsAt"
                    header={i18next.t('app_post_begins_at')}
                    style={{ width: '8rem', textAlign: 'center' }}>
                </Column>)}
                {deleted !== 'true' && (<Column
                    body={finishedBodyTemplate}
                    field="finishedAt"
                    header={i18next.t('app_post_finished_at')}
                    style={{ width: '8rem', textAlign: 'center' }}>
                </Column>)}
                <Column
                    body={commentBodyTemplate}
                    field="activeComments"
                    header={i18next.t('app_post_active_comments')}
                    style={{ width: '8.5rem', textAlign: 'center' }}
                    className="p-d-none p-d-lg-table-cell">
                </Column>
                <Column
                    body={actionBodyTemplate}
                    style={{ width: '4.4rem' }}>
                </Column>
            </DataTable>
        </div>
    );
};

export default PostTable;
