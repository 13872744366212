import React from 'react';
import displayDate from '../shared/components/display-date';
import i18next from 'i18next';
import Paginator from '../shared/components/paginator.component';
import Panel from '../shared/components/panel.component';
import PropTypes from 'prop-types';
import SubmitButton from '../shared/components/form/submit-button.component';
import { historyHelper as history } from '../core/helpers/history.helper';
import './artist-post-list.component.css';

const ArtistPostList = ({ artist, countAll, filter, onPageChange, posts }) => {
    return (
        <Panel title={i18next.t('app_artist_posts')} toggleable={true} collapsed={false}>
            <div className='o-artist-post-list'>
                <div className='p-grid'>
                    <div className='p-col-8'></div>
                    <div className='p-col-4'>
                        <SubmitButton
                            label={i18next.t('app_new_post')}
                            onClick={() => history.push(`/artists/${artist.id}/posts/new`)}
                        />
                    </div>
                </div>
                <div className='p-grid'>
                    <div className='o-table p-col-12'>
                        <table>
                            <thead>
                                <tr>
                                    <th>{i18next.t('app_artist_post_type')}</th>
                                    <th className='o-artist-post-title'>{i18next.t('app_artist_post_title')}</th>
                                    <th>{i18next.t('app_artist_post_purchase')}</th>
                                    <th>{i18next.t('app_artist_post_on_air')}</th>
                                    <th>{i18next.t('app_artist_post_available')}</th>
                                    <th>{i18next.t('app_artist_post_begins_at')}</th>
                                    <th>{i18next.t('app_artist_post_finished_at')}</th>
                                    <th>{i18next.t('app_artist_post_active_comments')}</th>
                                    <th>{i18next.t('app_actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {posts.length === 0 && <tr><td colSpan="7">{i18next.t('app_artist_no_post')}</td></tr>}
                                {posts.map(post => {
                                    return <tr key={post.id}>
                                        <td>{post.type}</td>
                                        <td className='o-artist-post-title'>{post.title}</td>
                                        <td>{post.purchase ? <i className="fas fa-check-circle" /> : ''}</td>
                                        <td>{(post.media && post.media.live && post.media.live.onAir) ? <i className="fas fa-check-circle"></i> : ''}</td>
                                        <td>{post.available ? <i className="fas fa-check-circle" /> : ''}</td>
                                        <td>{displayDate(post.beginsAt)}</td>
                                        <td>{displayDate(post.finishedAt)}</td>
                                        <td>{post.activeComments ? <i className="fas fa-check-circle" /> : ''}</td>
                                        <td>
                                            <i className="fas fa-edit"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => history.push(`/posts/modify/${post.id}`)}></i>
                                        </td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='p-grid o-artist-post-list-footer'>
                    <Paginator
                        countAll={countAll}
                        offset={filter.offset}
                        rows={filter.limit}
                        onPageChange={onPageChange}
                    ></Paginator>
                </div>
            </div>
        </Panel>
    );
};

ArtistPostList.propTypes = {
    artist: PropTypes.object,
    posts: PropTypes.array,
};

export default ArtistPostList;
