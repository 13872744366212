import React from 'react';
import i18next from 'i18next';
import ActionIcon from '../../shared/components/action-icon.component';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const StoreCoinsTable = ({ items, rows }) => {
    const skuBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{i18next.t('app_storecoins_sku')}</span>
                {rowData.id}
            </>
        );
    };

    const labelBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{i18next.t('app_storecoins_label')}</span>
                {rowData.label}
            </>
        );
    };

    const quantityBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{i18next.t('app_storecoins_quantity')}</span>
                {rowData.quantity}
            </>
        );
    };

    const bonusQuantityBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{i18next.t('app_storecoins_bonus_quantity')}</span>
                {rowData.bonusQuantity}
            </>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{i18next.t('app_shop_type')}</span>
                {rowData.type ? i18next.t(`app_storecoins_type_${rowData.type}`) : ''}
            </>
        );
    };

    const onStoreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{i18next.t('app_storecoins_available')}</span>
                {rowData.onStore ? <i className="fas fa-check-circle" /> : ''}
            </>
        );
    };

    const oneshotBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{i18next.t('app_storecoins_oneshot')}</span>
                {rowData.oneshot ? <i className="fas fa-check-circle" /> : ''}
            </>
        );
    };

    const bestBuyBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{i18next.t('app_storecoins_best_buy')}</span>
                {rowData.bestBuy ? <i className="fas fa-check-circle" /> : ''}
            </>
        );
    };

    const promoteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{i18next.t('app_storecoins_promote')}</span>
                {rowData.promote ? <i className="fas fa-check-circle" /> : ''}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="p-d-flex p-jc-end p-jc-xl-center">
                <ActionIcon
                    icon="fas fa-edit"
                    label={i18next.t('app_tip_edit')}
                    onClick={() => history.push(`/storecoins/modify/${rowData.id}`)}>
                </ActionIcon>
            </div>
        );
    };

    return (
        <div className='o-table-responsive'>
            <DataTable value={items} className="p-datatable-responsive" paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate={i18next.t('app_table_current_page_report')}
                rows={rows || 30}>
                <Column
                    body={skuBodyTemplate}
                    field="id"
                    header={i18next.t('app_storecoins_sku')}
                    style={{ width: '20rem', textAlign: 'center' }}>
                </Column>
                <Column
                    body={labelBodyTemplate}
                    field="label"
                    header={i18next.t('app_storecoins_label')}
                    style={{ textAlign: 'center' }}>
                </Column>
                <Column
                    body={quantityBodyTemplate}
                    field="quantity"
                    header={i18next.t('app_storecoins_quantity')}
                    style={{ textAlign: 'center' }}>
                </Column>
                <Column
                    body={bonusQuantityBodyTemplate}
                    field="bonusQuantity"
                    header={i18next.t('app_storecoins_bonus_quantity')}
                    style={{ textAlign: 'center' }}>
                </Column>
                <Column
                    body={typeBodyTemplate}
                    field="type"
                    header={i18next.t('app_shop_type')}
                    style={{ textAlign: 'center' }}>
                </Column>
                <Column
                    body={onStoreBodyTemplate}
                    field="onStore"
                    header={i18next.t('app_storecoins_available')}
                    style={{ textAlign: 'center' }}>
                </Column>
                <Column
                    body={oneshotBodyTemplate}
                    field="oneshot"
                    header={i18next.t('app_storecoins_oneshot')}
                    style={{ textAlign: 'center' }}>
                </Column>
                <Column
                    body={bestBuyBodyTemplate}
                    field="bestBuy"
                    header={i18next.t('app_storecoins_best_buy')}
                    style={{ textAlign: 'center' }}>
                </Column>
                <Column
                    body={promoteBodyTemplate}
                    field="promote"
                    header={i18next.t('app_storecoins_promote')}
                    style={{ textAlign: 'center' }}>
                </Column>
                <Column
                    body={actionBodyTemplate}
                    style={{ textAlign: 'center', width: '4.4rem' }}>
                </Column>
            </DataTable>
        </div>
    );
};

export default StoreCoinsTable;
