import Config from '../../config';

export const httpService = {
    auth,
    del,
    get,
    getCSV,
    patch,
    post,
    postFile,
    put,
    putFile,
};

async function handleResponse(response) {
    if (!response.ok) {
        const data = await response.text().then(text => {
            const responseText = text && JSON.parse(text);
            return responseText.data;
        });

        let error = [];

        switch (response.status) {
            case 401:
                // auto logout if 401 response returned from api
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                window.location.reload(true);
                break;
            case 403: {
                error = { error: { summary: Config.http.CODE_403 } };

                if (data.errors) {
                    error = { ...error, error: { ...error.error, detail: data.errors } };
                }
                break;
            }
            case 404: {
                error = { error: { summary: Config.http.CODE_404 } };

                if (data.errors) {
                    error = { ...error, error: { ...error.error, detail: data.errors } };
                }
                break;
            }
            case 405:
                error = { error: { summary: Config.http.CODE_405 } };
                break;
            case 422: {
                error = {
                    error: { summary: Config.http.CODE_422, detail: data.errors },
                    data: data,
                };
                break;
            }
            case 500:
                error = { error: { summary: Config.http.CODE_500 } };
                break;
            case 503:
                error = { error: { summary: Config.http.CODE_503 } };
                break;
            default:
                error = { error: { summary: Config.http.CODE_400 } };

                if (data.errors) {
                    error = { ...error, error: { ...error.error, detail: data.errors } };
                }
        }

        error = { ...error, ok: false };
        return error;
    } else {
        return response.text().then(text => {
            let data = text && JSON.parse(text);
            data = { ...data, ok: true };
            return data;
        });
    }
}

async function auth(address, data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    };

    const response = await getFetch(address, requestOptions);

    return response;
}

async function del(address, data) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(data),
    };

    const response = await getFetch(address, requestOptions);
    return response;
}

async function getFetch(address, requestOptions) {
    let response = [];
    const options = {
        ...requestOptions,
        headers: {
            ...requestOptions.headers,
            'X-API-KEY': Config.app.APIKEY,
            'X-APP-VERSION': Config.app.version.NUMBER,
        },
    };

    try {
        response = await fetch(process.env.REACT_APP_API_HOST + address, options);
    } catch (error) {
        response = { ok: false, status: 503 };
    }
    return handleResponse(response);
}

async function get(address) {
    let requestOptions = {};
    if (localStorage.getItem('token')) {
        requestOptions = {
            method: 'GET',
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        };
    } else {
        requestOptions = {
            method: 'GET',
        };
    }

    const response = await getFetch(address, requestOptions);
    return response;
}

async function getCSV(address) {
    const options = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'X-API-KEY': Config.app.APIKEY,
            'X-APP-VERSION': Config.app.version.NUMBER,
        },
    };

    const response = await fetch(process.env.REACT_APP_API_HOST + address, options);
    return response.text().then(text => {
        return text;
    });
}

async function patch(address, data) {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(data),
    };

    const response = await getFetch(address, requestOptions);
    return response;
}

async function post(address, data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(data),
    };

    const response = await getFetch(address, requestOptions);
    return response;
}

async function postFile(address, data) {
    const requestOptions = {
        method: 'POST',
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        body: data,
    };

    const response = await getFetch(address, requestOptions);
    return response;
}

async function put(address, data) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(data),
    };

    const response = await getFetch(address, requestOptions);
    return response;
}

async function putFile(address, data) {
    const requestOptions = {
        method: 'PUT',
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        body: data,
    };

    const response = await getFetch(address, requestOptions);
    return response;
}
