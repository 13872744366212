/* eslint-disable no-this-before-super */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import {
    fetchLogin,
    fetchPostRecovery,
    logout,
    setNewPassword,
} from '../store/actions/user.action';

import ForgotForm from './components/forgot-form.component';
import LoginForm from './components/login-form.component';
import NewForm from './components/new-form.component';

import './login-page.component.css';

class ConnectedLoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dirty: false,
            dirtyNew: false,
            forgot: false,
            login: {
                password: '',
                username: '',
                newPassword: '',
                newConfirmation: '',
            },
            submitted: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleForgot = this.handleForgot.bind(this);
        this.handleForgotSubmit = this.handleForgotSubmit.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        this.handleNew = this.handleNew.bind(this);
    }

    handleChange(event) {
        const login = this.state.login;
        const { name, value } = event.target;
        login[name] = value;
        this.setState({ login: login });
    }

    handleForgot(event) {
        event.preventDefault();
        this.setState({ forgot: !this.state.forgot });
    }

    handleForgotSubmit(event) {
        event.preventDefault();
        this.setState({ dirty: true, submitted: true });
        const { username } = this.state.login;
        if (username) {
            this.props.fetchPostRecovery(username);
        }
        this.setState({ submitted: false });
    }

    handleLoginSubmit(event) {
        event.preventDefault();
        const { user } = this.props.store;
        this.setState({ dirty: true, dirtyNew: user.newPassword, submitted: true });
        const { username, password, newPassword, newConfirmation } = this.state.login;
        if (
            username &&
            password &&
            (!newPassword || (newPassword && newPassword === newConfirmation))
        ) {
            this.props.fetchLogin(username, password, newPassword);
        }
        this.setState({ submitted: false });
    }

    handleNew(event) {
        event.preventDefault();
        this.props.setNewPassword(false);
        const login = this.state.login;
        login.newPassword = '';
        login.newConfirmation = '';
        this.setState({ login });
    }

    render() {
        const { dirtyNew, forgot, login, submitted } = this.state;
        const {
            store: {
                user,
            },
        } = this.props;

        let form = 'login';
        if (forgot) {
            form = 'forgot';
        }

        if (user.newPassword) {
            form = 'new';
        }

        return (
            <div className='o-login'>
                <div className='o-login-wrapper'>
                    <div className='o-login-loader'>
                        {user.loading && (
                            <i className='pi pi-spin pi-spinner' style={{ fontSize: '10em' }}></i>
                        )}
                    </div>
                    <div className='o-login-logo'>
                        <img
                            alt='logo'
                            style={{ width: '100%' }}
                            src='/templates/default/images/logo.svg'
                        />
                    </div>
                    {form === 'login' && (
                        <div className='o-login-form o-loginform'>
                            <LoginForm
                                value={login}
                                onChange={this.handleChange}
                                onSubmit={this.handleLoginSubmit}
                                onKeyPress={this.handleOnKeyPress}
                                submitted={user.loading}
                            />

                            <div className='o-login-link o-loginlink'>
                                <Link to='/' onClick={this.handleForgot}>
                                    {i18next.t('app_forgot_password', 'Mot de passe oublié ?')}
                                </Link>
                            </div>
                        </div>
                    )}
                    {form === 'forgot' && (
                        <div className='o-login-form o-loginform'>
                            <ForgotForm
                                value={login}
                                onChange={this.handleChange}
                                onSubmit={this.handleForgotSubmit}
                                submitted={user.loading}
                            />
                            <div className='o-login-link o-loginlink'>
                                <Link to='/' onClick={this.handleForgot}>
                                    {i18next.t('app_login', 'Se connecter')}
                                </Link>
                            </div>
                        </div>
                    )}
                    {form === 'new' && (
                        <div className='o-login-form o-loginform'>
                            <NewForm
                                dirty={dirtyNew}
                                onChange={this.handleChange}
                                onSubmit={this.handleLoginSubmit}
                                submitted={submitted}
                                value={login}
                            />
                            <div className='o-login-link o-loginlink'>
                                <Link to='/' onClick={this.handleNew}>
                                    {i18next.t('app_login', 'Se connecter')}
                                </Link>
                            </div>
                        </div>
                    )}
                    <div className='o-login-copyright'>© Copyright ØPP Startup Studio SA 2020</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return { store: { user } };
};

const mapDispatchToProps = {
    fetchLogin,
    fetchPostRecovery,
    logout,
    setNewPassword,
};

const LoginPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedLoginPage);

export default LoginPage;
