import Config from '../../config';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { httpService as http } from '../../core/services/http.service';
import { buildURLQuery, fetchPostPicture, fetchPutPicture } from './app.action';
import { fetchUploadFile } from './amazon.action';
import { showError, showSuccess } from './message.action';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export const getTypes = () => {
    return dispatch => {
        return [
            { value: 'video', label: 'app_post_type_video' },
            { value: 'live', label: 'app_post_type_live' },
            { value: 'news', label: 'app_post_type_news' },
        ];
    };
};

export const getUploadSteps = () => {
    return dispatch => {
        return [
            { value: '0', label: 'app_video_upload_step_upload' },
            { value: '1', label: 'app_video_upload_step_process' },
            { value: '2', label: 'app_video_upload_step_ready' },
        ];
    };
};

export const type = {
    POST_FETCH_PENDING: 'POST_FETCH_PENDING',
    POST_FETCH_PENDING_ON_AIR: 'POST_FETCH_PENDING_ON_AIR',
    POST_FETCH_GET_SUCCESS: 'POST_FETCH_GET_SUCCESS',
    POST_FETCH_GET_LIVE_LINK_SUCCESS: 'POST_FETCH_GET_LIVE_LINK_SUCCESS',
    POST_FETCH_GET_LIVE_SETUP_SUCCESS: 'POST_FETCH_GET_LIVE_SETUP_SUCCESS',
    POST_FETCH_GET_POST_SUCCESS: 'POST_FETCH_GET_POST_SUCCESS',
    POST_FETCH_GET_SECTIONS_SUCCESS: 'POST_FETCH_GET_SECTIONS_SUCCESS',
    POST_FETCH_GET_STAKEHOLDER_SUCCESS: 'POST_FETCH_GET_STAKEHOLDER_SUCCESS',
    POST_FETCH_GET_STAKEHOLDERS_SUCCESS: 'POST_FETCH_GET_STAKEHOLDERS_SUCCESS',
    POST_FETCH_POST_SUCCESS: 'POST_FETCH_POST_SUCCESS',
    POST_FETCH_POST_STAKEHOLDERS_SUCCESS: 'POST_FETCH_POST_STAKEHOLDERS_SUCCESS',
    POST_FETCH_PUT_SUCCESS: 'POST_FETCH_PUT_SUCCESS',
    POST_RESET: 'POST_RESET',
    POST_RESET_STAKEHOLDER: 'POST_RESET_STAKEHOLDER',
    POST_SET: 'POST_SET',
    POST_SET_FILE_READED: 'POST_SET_FILE_READED',
    POST_SET_FILTER: 'POST_SET_FILTER',
    POST_SET_LIVE_PICTURE: 'POST_SET_LIVE_PICTURE',
    POST_SET_PARTICIPATION_RATE: 'POST_SET_PARTICIPATION_RATE',
    POST_SET_PICTURE: 'POST_SET_PICTURE',
    POST_SET_POSTS: 'POST_SET_POSTS',
    POST_SET_POST_STAKEHOLDERS: 'POST_SET_POST_STAKEHOLDERS',
    POST_SET_STAKEHOLDER: 'POST_SET_STAKEHOLDER',
    POST_SET_STAKEHOLDERS_FILTER: 'POST_SET_STAKEHOLDERS_FILTER',
};

export const initialState = {
    countAll: 0,
    filter: {
        deleted: 'false',
        limit: Config.paginator.DEFAULT_ROWS,
        offset: Config.paginator.DEFAULT_FIRST,
    },
    loading: false,
    loadingOnAir: false,
    link: '',
    livePicture: null,
    livePictureFile: null,
    participationRate: 0,
    picture: null,
    pictureFile: null,
    post: {
        id: null,
        artists: [],
        available: false,
        title: '',
        about: {},
        type: '',
        media: null,
        purchase: false,
        beginsAt: null,
        finishedAt: null,
        activeComments: false,
        tags: [],
        promote: false,
        picture: null,
        sections: [],
    },
    posts: [],
    postStakeholders: [],
    stakeholder: {
        share: 0,
    },
    stakeholders: [],
    stakeholdersFilter: {},
    fileReaded: 0,
};

export const fetchPending = loading => {
    return { type: type.POST_FETCH_PENDING, loading };
};

export const fetchPendingOnAir = loadingOnAir => {
    return { type: type.POST_FETCH_PENDING_ON_AIR, loadingOnAir };
};

export const fetchGetLiveLinkSuccess = (link) => {
    return {
        type: type.POST_FETCH_GET_LIVE_LINK_SUCCESS,
        link,
    };
};

export const fetchGetLiveLink = (id) => {
    return dispatch => {
        http.get(`/v1/bo/posts/${id}/livesetup`).then(
            response => {
                if (response.ok) {
                    const { link } = response.data.setup;
                    dispatch(fetchGetLiveLinkSuccess(link));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchGetLiveSetupSuccess = () => {
    return {
        type: type.POST_FETCH_GET_LIVE_SETUP_SUCCESS,
        loading: false,
    };
};

export const fetchGetLiveSetup = (id) => {
    return dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/posts/${id}/livesetup`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    const { AppId, AppToken, AppCertificate, UID, ChannelName } = response.data.setup;
                    let text = '[BaseInfo]\n';
                    text += `AppId=${AppId}\n`;
                    text += `AppToken=${AppToken}\n`;
                    text += `AppCertificate=${AppCertificate}\n`;
                    text += `UID=${UID}\n`;
                    text += `ChannelName=${ChannelName}\n`;

                    const downloadLink = document.createElement('a');
                    downloadLink.href = `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`;
                    downloadLink.download = 'AgoraObs.ini';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    dispatch(fetchGetLiveSetupSuccess());
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchGetPostSuccess = ({ post, picture, livePicture }) => {
    const beginsAt = post.beginsAt ? moment(post.beginsAt).toDate() : null;
    const finishedAt = post.finishedAt ? moment(post.finishedAt).toDate() : null;
    const media = post.media;

    let onAirAt = null;
    switch (post.type) {
        case 'live':
            onAirAt = media.live.onAirAt ? moment(media.live.onAirAt).toDate() : null;
            media.live = { ...media.live, onAirAt };
            break;
        case 'video':
            onAirAt = media.video.onAirAt ? moment(media.video.onAirAt).toDate() : null;
            media.video = { ...media.video, onAirAt };
            break;
        default:
    }

    return {
        type: type.POST_FETCH_GET_POST_SUCCESS,
        livePicture,
        post: {
            ...post,
            tags: post.tags.map(tag => { return { id: tag, name: tag }; }),
            beginsAt,
            finishedAt,
            media,
        },
        picture,
        loading: false,
    };
};

export const fetchGetPost = (id, options = {}, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));
        const query = buildURLQuery(options);

        http.get(`/v1/bo/posts/${id}${query}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetPostSuccess(response.data));
                    if (callback) {
                        callback(response.data.post);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchGetPostsSuccess = ({ posts, countAll }) => {
    return {
        type: type.POST_FETCH_GET_SUCCESS,
        posts,
        countAll,
        loading: false,
    };
};

export const fetchGetPosts = (options = {}, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));
        const query = buildURLQuery(options);

        http.get(`/v1/bo/posts${query}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetPostsSuccess(response.data));
                    if (callback) {
                        callback(response.data.posts);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchGetSectionsSuccess = ({ sections }) => {
    const sectionOptions = sections.map(section => {
        return { label: section.title, value: section.id };
    });

    return {
        type: type.POST_FETCH_GET_SECTIONS_SUCCESS,
        sectionOptions,
        loading: false,
    };
};

export const fetchGetSections = () => {
    return dispatch => {
        http.get('/v1/bo/sections?type=section').then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetSectionsSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

const fetchGetStakeholderSuccess = ({ stakeholder }) => {
    return {
        type: type.POST_FETCH_GET_STAKEHOLDER_SUCCESS,
        loading: false,
        stakeholder,
    };
};

export const fetchGetStakeholder = (postId, id) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/posts/${postId}/stakeholders/${id}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetStakeholderSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

const fetchGetStakeholdersSuccess = ({ stakeholders }) => {
    let participationRate = 0;

    stakeholders.forEach(element => {
        participationRate += element.share;
    });

    return {
        type: type.POST_FETCH_GET_STAKEHOLDERS_SUCCESS,
        loading: false,
        participationRate,
        stakeholders,
    };
};

export const fetchGetStakeholders = (id, options = {}, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));
        const query = buildURLQuery(options);

        http.get(`/v1/bo/posts/${id}/stakeholders${query}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetStakeholdersSuccess(response.data));
                    if (callback) {
                        callback(response.data.stakeholders);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

export const fetchPatchRemovePost = id => {
    return async dispatch => {
        dispatch(fetchPending(true));
        const data = {};

        http.patch(`/v1/bo/posts/${id}/remove`, data).then(
            response => {
                dispatch(fetchPending(false));
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    dispatch(showSuccess({ summary: 'app_post_removed' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPatchRestorePost = id => {
    return async dispatch => {
        dispatch(fetchPending(true));
        const data = {};

        http.patch(`/v1/bo/posts/${id}/restore`, data).then(
            response => {
                dispatch(fetchPending(false));
                if (!response.ok) {
                    dispatch(showError(response.error));
                } else {
                    dispatch(showSuccess({ summary: 'app_post_restored' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPostAddSection = (id, sectionId) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        const data = { sectionId };

        http.post(`/v1/bo/posts/${id}/addSection`, data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostPostSuccess(response.data));
                    dispatch(showSuccess({ summary: 'app_post_section_added' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

export const fetchPostLive2VOD = (id) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.post(`/v1/bo/posts/${id}/live2vod`, {}).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostPostSuccess(response.data));
                    dispatch(showSuccess({ summary: 'app_post_vod_created' }));
                    history.push(`/posts/modify/${response.data.post.id}`);
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

export const fetchPostOnAir = (post, notify, orientation, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPendingOnAir(true));

        const data = {
            channel: post.media.live.channel,
            notify,
            onAir: !post.media.live.onAir,
            orientation,
        };

        http.post(`/v1/bo/posts/${post.id}/onair`, data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPendingOnAir(false));
                    if (callback) {
                        callback(response.error);
                    }
                } else {
                    dispatch(fetchPostPostSuccess(response.data));
                    if (callback) {
                        callback(null);
                    }
                }
            },
            error => {
                dispatch(fetchPendingOnAir(false));
                dispatch(showError(error.error));
            });
    };
};

const fetchPostPostSuccess = ({ post }) => {
    return {
        type: type.POST_FETCH_POST_SUCCESS,
        post: {
            ...post,
            tags: post.tags.map(tag => { return { id: tag, name: tag }; }),
            beginsAt: post.beginsAt ? moment(post.beginsAt).toDate() : null,
            finishedAt: post.finishedAt ? moment(post.finishedAt).toDate() : null,
        },
        loading: false,
        loadingOnAir: false,
    };
};

export const fetchPostPost = (post) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        const artists = post.artists.map(artist => {
            return artist.id;
        });

        const data = {
            type: post.type,
            artists,
            title: post.title,
        };

        http.post('/v1/bo/posts', data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostPostSuccess(response.data));
                    history.push(`/posts/modify/${response.data.post.id}`);
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

export const fetchPostRemoveSection = (id, sectionId) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        const data = { sectionId };

        http.post(`/v1/bo/posts/${id}/removeSection`, data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostPostSuccess(response.data));
                    dispatch(showSuccess({ summary: 'app_post_section_removed' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

const fetchPostStakeholdersSuccess = ({ stakeholders }) => {
    let participationRate = 0;

    stakeholders.forEach(element => {
        participationRate += element.share;
    });

    return {
        type: type.POST_FETCH_POST_STAKEHOLDERS_SUCCESS,
        loading: false,
        participationRate,
        stakeholders,
    };
};

export const fetchPostStakeholders = (id, stakeholders, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.post(`/v1/bo/posts/${id}/stakeholders`, { stakeholders }).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchPostStakeholdersSuccess(response.data));
                    if (callback) {
                        callback(response.data.stakeholders);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

export const fetchPostVideo = (post, video) => {
    return async dispatch => {
        const getMetadata = (file, callback = undefined) => {
            const metadata = {
                duration: 0,
                orientation: 'landscape',
            };

            const video = document.createElement('video');
            video.addEventListener('loadedmetadata', event => {
                metadata.duration = video.duration;
                metadata.orientation = video.videoWidth >= video.videoHeight ? 'landscape' : 'portrait';
                if (callback) {
                    callback(metadata);
                }
            });

            const reader = new FileReader();

            reader.onload = (e) => {
                // The file reader gives us an ArrayBuffer:
                const buffer = e.target.result;

                // We have to convert the buffer to a blob:
                const videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });

                // The blob gives us a URL to the video file:
                const url = window.URL.createObjectURL(videoBlob);

                video.src = url;
            };

            reader.onprogress = (e) => {
                if (e.lengthComputable) {
                    var percentLoaded = Math.round((e.loaded / e.total) * 100);
                    // Increase the progress bar length.
                    if (percentLoaded < 100) {
                        dispatch(setFileReaded(percentLoaded));
                    }
                }
            };

            reader.readAsArrayBuffer(file);
        };

        dispatch(fetchPending(true));
        if (!video.type || video.type !== 'video/mp4') {
            dispatch(fetchPending(false));
            dispatch(showError({ summary: 'app_video_bad_format' }));
            return false;
        };

        // Build metadata
        getMetadata(video, async metadata => {
            // Get credential
            http.get(`/v1/bo/posts/${post.id}/credential?orientation=${metadata.orientation || 'landscape'}`).then(
                async response => {
                    if (!response.ok) {
                        dispatch(fetchPending(false));
                        dispatch(showError(response.error));
                    } else {
                        const { data: { config } } = response;
                        const reference = uuidv4();
                        const upload = await fetchUploadFile(config, video, reference);
                        if (!upload.ok) {
                            dispatch(fetchPending(false));
                            dispatch(showError({ summary: 'app_video_not_uploaded' }));
                        } else {
                            const data = {
                                media: { video: { ...metadata, reference } },
                            };
                            http.post(`/v1/bo/posts/${post.id}/media`, data).then(
                                response => {
                                    if (!response.ok) {
                                        dispatch(fetchPending(false));
                                        dispatch(showError(response.error));
                                    } else {
                                        dispatch(fetchPutPostSuccess(response.data));
                                    }
                                },
                                error => {
                                    dispatch(fetchPending(false));
                                    dispatch(showError(error.error));
                                });
                        }
                    }
                },
                error => {
                    dispatch(fetchPending(false));
                    dispatch(showError(error.error));
                }
            );
        });
    };
};

const fetchPutPostSuccess = ({ post, picture, livePicture }) => {
    return {
        type: type.POST_FETCH_PUT_SUCCESS,
        post: {
            ...post,
            tags: post.tags.map(tag => { return { id: tag, name: tag }; }),
            beginsAt: post.beginsAt ? moment(post.beginsAt).toDate() : null,
            finishedAt: post.finishedAt ? moment(post.finishedAt).toDate() : null,
        },
        picture,
        livePicture,
        loading: false,
    };
};

export const fetchPutPost = (post, pictureFile, livePictureFile, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        let pictureName = post.picture;
        // let picture = '';

        if (pictureFile) {
            let result = {};

            if (post.picture && post.picture !== '') {
                result = await fetchPutPicture(post.picture, pictureFile);
            } else {
                result = await fetchPostPicture(pictureFile);
            }

            pictureName = result.picture;
            // picture = result.pictureURL;
        }

        let livePictureName = '';
        // let livePicture = '';

        if (post.type === 'live' && post.media.live) {
            livePictureName = post.media.live.picture || '';

            if (livePictureFile) {
                let result = {};

                if (post.media.live.picture && post.media.live.picture !== '') {
                    result = await fetchPutPicture(post.media.live.picture, livePictureFile, 'live');
                } else {
                    result = await fetchPostPicture(livePictureFile, 'live');
                }

                livePictureName = result.picture;
                // livePicture = result.pictureURL;
            }
        }

        if ((post.type !== 'live' && pictureName) ||
            (post.type === 'live' && pictureName && livePictureName)) {
            const artists = post.artists.map(artist => {
                return artist.id;
            });

            let data = {
                artists,
                title: post.title,
                tags: post.tags.map(tag => tag.name),
                activeComments: post.activeComments,
                purchase: post.purchase,
                purchaseCoins: post.purchaseCoins,
                purchaseFreeTime: post.purchaseFreeTime,
                available: post.available,
                beginsAt: post.beginsAt ? moment(post.beginsAt).toISOString() : null,
                finishedAt: post.finishedAt ? moment(post.finishedAt).toISOString() : null,
                promote: post.promote,
                picture: pictureName,
                about: post.about,
                media: post.media,
            };

            if (post.type === 'live') {
                data = {
                    ...data,
                    media: {
                        ...post.media,
                        live: {
                            ...post.media.live,
                            picture: livePictureName,
                        },
                    },
                };
            }

            http.put(`/v1/bo/posts/${post.id}`, data).then(
                response => {
                    if (!response.ok) {
                        dispatch(fetchPending(false));
                        dispatch(showError(response.error));
                    } else {
                        dispatch(fetchPutPostSuccess(response.data));
                        if (callback) {
                            callback(response.data);
                        }
                        dispatch(showSuccess({ summary: 'app_post_updated' }));
                    }
                },
                error => {
                    dispatch(fetchPending(false));
                    dispatch(showError(error.error));
                });
        } else {
            dispatch(fetchPending(false));
            dispatch(showError({ summary: 'app_picture_not_uploaded' }));
        }
    };
};

export const fetchPutStakeholder = (postId, stakeholder) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        const data = {
            share: stakeholder.share,
        };

        http.put(`/v1/bo/posts/${postId}/stakeholders/${stakeholder.id}`, data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetStakeholderSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

export const fetchRemoveStakeholder = (id, stakeholder, stakeholders) => {
    const newStakeholders = stakeholders.filter(element => element.id !== stakeholder.id);
    return async dispatch => {
        dispatch(fetchPending(true));

        http.patch(`/v1/bo/posts/${id}/stakeholders/${stakeholder.id}/remove`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetStakeholdersSuccess({ stakeholders: newStakeholders }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

export const resetPost = () => {
    return {
        type: type.POST_RESET,
        post: { ...initialState.post, artists: [] },
    };
};

export const resetStakeholder = () => {
    return {
        type: type.POST_RESET_STAKEHOLDER,
        stakeholder: initialState.stakeholder,
    };
};

export const setFileReaded = fileReaded => {
    return { type: type.POST_SET_FILE_READED, fileReaded };
};

export const setFilter = filter => {
    return { type: type.POST_SET_FILTER, filter };
};

export const setLivePicture = livePicture => {
    return { type: type.POST_SET_LIVE_PICTURE, livePicture };
};

export const setParticipationrate = participationRate => {
    return { type: type.POST_SET_PARTICIPATION_RATE, participationRate };
};

export const setPicture = picture => {
    return { type: type.POST_SET_PICTURE, picture };
};

export const setPost = post => {
    return { type: type.POST_SET, post };
};

export const setPosts = posts => {
    return { type: type.POST_SET_POSTS, posts };
};

export const setPostStakeholders = postStakeholders => {
    return { type: type.POST_SET_POST_STAKEHOLDERS, postStakeholders };
};

export const setStakeholder = stakeholder => {
    return { type: type.POST_SET_STAKEHOLDER, stakeholder };
};

export const setStakeholdersFilter = stakeholdersFilter => {
    return { type: type.POST_SET_STAKEHOLDERS_FILTER, stakeholdersFilter };
};
