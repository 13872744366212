import React from 'react';
import './input-text.component.css';
import './input-completion-text.component.css';

const InputCompletionText = ({ editable = true, disabled, label, name, placeholder, onSearch, values, options, onAdd, onRemove, onCancel, bottom }) => {
    let input = null;

    const onLeave = () => {
        input.value = '';
        options && options.length > 0 && onCancel();
    };

    const onKeyPress = (event) => {
        const { value } = event.currentTarget;

        if (event.key === 'Enter' && editable) {
            input.value = '';
            onAdd({ name: value });
        } else if (event.key === 'Escape') {
            onLeave();
        } else {
            onSearch(value);
        }
    };

    return (
        <div className='o-input-text relative'>
            <label className="o-input-text-label">{label || ''}</label>
            <div className="o-input-text-container">
                {(!bottom && values && values.length > 0) && <ul className="o-input-text-tags">
                    {values.map((tag, index) => {
                        return <li key={index}>{tag.name} <i className="far fa-times-circle" onClick={() => onRemove(index)}></i></li>;
                    })}
                </ul>}
                <input
                    disabled={disabled === true ? 'disabled' : false}
                    name={name}
                    onKeyUp={onKeyPress}
                    placeholder={placeholder || ''}
                    type='text'
                    autoComplete='off'
                    ref={el => { input = el; }}
                />
            </div>
            <div>
                {options && options.length > 0 && <ul className="o-input-text-completion">
                    {options.map(tag => {
                        return <li key={tag.id} onClick={() => {
                            input.value = '';
                            onAdd(tag);
                        }}>{tag.name}</li>;
                    })}
                </ul>}
            </div>
            {(bottom && values && values.length > 0) && <ul className="o-input-text-tags bottom">
                {values.map((tag, index) => {
                    return <li key={index}>{tag.name} <i className="far fa-times-circle" onClick={() => onRemove(index)}></i></li>;
                })}
            </ul>}
        </div>
    );
};

export default InputCompletionText;
