import { httpService as http } from '../../core/services/http.service';
import { showError } from './message.action';

export const type = {
    TAG_FETCH_PENDING: 'TAG_FETCH_PENDING',
    TAG_FETCH_GET_SUCCESS: 'TAG_FETCH_GET_SUCCESS',
};

export const initialState = {
    countAll: 0,
    loading: false,
    tags: [],
};

const fetchPending = loading => {
    return { type: type.TAG_FETCH_PENDING, loading };
};

const fetchGetTagsSuccess = ({ countAll, tags }) => {
    return {
        type: type.TAG_FETCH_GET_SUCCESS,
        countAll,
        tags,
        loading: false,
    };
};

export const fetchGetTags = query => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/tags?find=${query}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetTagsSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};
