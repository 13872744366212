import React from 'react';
import './artist-box.component.css';

const ArtistBox = ({ artist, onClick }) => {
    return (
        <div className='o-artist-box' onClick={onClick}>
            <div
                className='o-artist-box-picture'
                style={{ backgroundImage: `url(${artist.banner}?method=resize&w=250)` }}
            >
            </div>
            <div className='o-artist-box-name'>{artist.name}</div>
            <div className='o-artist-box-info'>{artist.tags ? artist.tags.map(tag => `#${tag}`).join(' ') : ''}</div>
        </div>
    );
};

export default ArtistBox;
