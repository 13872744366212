import { initialState, type } from '../actions/section.action';

const section = (state = initialState, action) => {
    switch (action.type) {
        case type.SECTION_FETCH_PENDING:
            return { ...state, loading: action.loading };

        case type.SECTION_FETCH_GET_SUCCESS:
            return {
                ...state,
                countAll: action.countAll,
                sections: action.sections,
                loading: action.loading,
            };

        case type.SECTION_FETCH_GET_ONE_SUCCESS:
            return {
                ...state,
                section: action.section,
                loading: action.loading,
            };

        case type.SECTION_FETCH_POST_SUCCESS:
        case type.SECTION_FETCH_PUT_SUCCESS:
            return {
                ...state,
                section: action.section,
                loading: action.loading,
            };

        case type.SECTION_FETCH_POST_REORDER_SUCCESS:
            return {
                ...state,
                sections: action.sections,
                loading: action.loading,
            };

        case type.SECTION_RESET:
            return {
                ...state,
                section: { ...initialState.section },
            };

        case type.SECTION_SET:
            return {
                ...state,
                section: action.section,
            };

        case type.SECTION_SET_BANNERS:
            return {
                ...state,
                sections: action.sections,
            };

        default:
            return state;
    }
};

export default section;
