import React, { Component } from 'react';
import i18next from 'i18next';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { fetchPutRecovery } from '../store/actions/user.action';

import './recovery-page.component.css';

class ConnectedRecoveryPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            recovery: false,
        };
    }

    async componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        if (query.token) {
            this.props.fetchPutRecovery(query.token, user => {
                let recovery = false;
                if (user) recovery = true;
                this.setState({ loading: false, recovery });
            });
        } else {
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, recovery } = this.state;
        return (
            <div className='o-recovery'>
                <div className='o-recovery-logo'>
                    <img
                        alt='logo'
                        style={{ width: '100%' }}
                        src='/templates/default/images/logo.svg'
                    />
                </div>
                <div className='o-recovery-message'>
                    {loading && <i className='pi pi-spin pi-spinner'></i>}
                    {!loading && recovery && (
                        <span>
                            {i18next.t('app_user_recovery_valid')}
                        </span>
                    )}
                    {!loading && !recovery && (
                        <span>
                            {i18next.t('app_user_recovery_not_valid')}
                        </span>
                    )}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    fetchPutRecovery,
};

const RecoveryPage = connect(null, mapDispatchToProps)(ConnectedRecoveryPage);

export default RecoveryPage;
