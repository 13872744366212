import React, { useState } from 'react';
import i18next from 'i18next';

import InputText from '../../shared/components/form/input-text.component';
import Textarea from '../../shared/components/form/textarea.component';
import Panel from '../../shared/components/panel.component';
import Config from '../../config';

const BannerDetail = ({ lang, banner, onChange, onKeyDown, submit }) => {
    const [collapsed] = useState(false);

    const handleChangeAbout = event => {
        banner.about = { ...banner.about, [lang]: event.target.value };
        onChange(banner);
    };

    const handleChangeTitle = event => {
        banner.title = { ...banner.title, [lang]: event.target.value };
        onChange(banner);
    };

    const about = banner.about ? banner.about[lang] : null;
    const title = banner.title ? banner.title[lang] : null;

    return (
        <Panel
            title={i18next.t(Config.locales.LABELS[lang])}
            kind='detail'
            toggleable={true}
            collapsed={collapsed}
        >
            <InputText
                name='title'
                label={i18next.t('app_banner_title')}
                value={title}
                onChange={e => handleChangeTitle(e)}
                onKeyDown={onKeyDown}
            />
            {submit && !title && (
                <div className='error'>* {i18next.t('app_error_banner_mandatory_title')}</div>
            )}
            <Textarea
                name='about'
                label={i18next.t('app_banner_about')}
                value={about}
                onChange={e => handleChangeAbout(e)}
            />
        </Panel>
    );
};

export default BannerDetail;
