import React from 'react';
import Radium from 'radium';
import './button-icon.component.css';

let ButtonIcon = ({ icon, kind, onClick }) => {
    return (
        <div
            className={`o-button-icon ${kind === 'danger' ? 'danger' : ''} ${
                kind === 'disabled' ? 'disabled' : ''
            } ${kind === 'secondary' ? 'secondary' : ''}`}
            onClick={onClick}
        >
            <i className={`${icon} ${kind === 'secondary' ? 'secondary' : ''}`} />
        </div>
    );
};

export default ButtonIcon = Radium(ButtonIcon);
