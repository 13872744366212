import React from 'react';
import i18next from 'i18next';
import ButtonIcon from '../../shared/components/form/button-icon.component';
import InputCompletionText from '../../shared/components/form/input-completion-text.component';
import InputNumber from '../../shared/components/form/input-number.component';

import './add-artist.component.css';

const AddArtist = ({ artists, onAdd, onCancel, onChange, onSearch, options, values }) => {
    const onChangeShare = ({ target: { value } }, artist) => {
        const newArtists = artists;
        const index = newArtists.findIndex(e => e.id === artist.id);
        newArtists[index].share = value;
        onChange(newArtists);
    };

    const onRemoveArtist = (artist) => {
        const newArtists = artists;
        const index = newArtists.findIndex(e => e.id === artist.id);
        newArtists.splice(index, 1);
        onChange(newArtists);
    };

    const dynamicArtists = artists.map((artist, i) => {
        return (
            <div className='o-add-artist-item' key={i}>
                <div className='o-add-artist-item-name'>{artist.name}</div>
                <div className='o-add-artist-item-share'>
                    <InputNumber name='share'
                        min={0}
                        max={100}
                        onChange={(e) => onChangeShare(e, artist)}
                        value={artist.share} />
                </div>
                <div className='o-add-artist-item-pc'>
                    <span>%</span>
                </div>
                <div className='o-add-artist-item-action'>
                    <ButtonIcon icon='fas fa-times' onClick={() => onRemoveArtist(artist)} />
                </div>
            </div>
        );
    });

    return (
        <div className='o-add-artist'>
            <div className='o-add-artist-title'>{i18next.t('app_add_artist_title')}</div>
            <InputCompletionText
                editable={false}
                name='find'
                onAdd={onAdd}
                onCancel={onCancel}
                onSearch={onSearch}
                options={options}
                placeholder={i18next.t('app_add_artist_find')}
                values={values}
            />
            {dynamicArtists}
            {artists.length > 0 && <div className='o-add-artist-share-info'>{i18next.t('app_add_artist_share_info')}</div>}
        </div>
    );
};

export default AddArtist;
