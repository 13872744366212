import React from 'react';
import Tippy from '@tippyjs/react';

const ActionIcon = ({ icon, label, onClick, style }) => {
    return (
        <Tippy content={label}>
            <div style={style}>
                <i className={icon}
                    style={{ cursor: 'pointer' }}
                    onClick={onClick}></i>
            </div>
        </Tippy>
    );
};

export default ActionIcon;
