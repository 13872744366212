import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { usePubNub } from 'pubnub-react';
import './artist-chat.component.css';

const ArtistChat = ({ artist, picture, lastMessage, postChat }) => {
    const pubnub = usePubNub();

    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [channels, setChannels] = useState([]);

    useEffect(() => {
        const handleMessage = async event => {
            let message = JSON.parse(event.message);

            const { data } = await pubnub.objects.getUUIDMetadata({ uuid: message.uuid });
            message = {
                ...message,
                name: data.name ? data.name.split(' ')[0] : message.name,
                profileUrl: data.profileUrl || 'no_picture',
            };

            const chat = [...messages];
            chat.push(message);
            setMessages(chat);
        };

        const channel = `${artist.id}/${process.env.REACT_APP_PUBNUB_VERSION}`;

        pubnub.objects.setUUIDMetadata({
            uuid: artist.id,
            data: {
                name: artist.name,
                profileUrl: picture || 'no_picture',
            },
        });

        pubnub.addListener({ message: handleMessage });
        pubnub.subscribe({ channels: [channel] });

        setChannels([channel]);
    }, [artist, picture, pubnub, messages]);

    const sendMessage = text => {
        if (text) {
            const message = JSON.stringify({
                body: text,
                timestamp: Date.now(),
                uuid: artist.id,
                firstname: artist.name,
                artist: true,
            });

            pubnub
                .publish({
                    channel: channels[0],
                    message,
                })
                .then(() => setMessage(''));

            postChat(artist.id, message);
        }
    };

    const styleArtistPicture = {
        backgroundImage: (picture && picture !== 'no_picture')
            ? `url('${picture}?h=200&w=200')`
            : `url(//unavatar.now.sh/${artist.name}?fallback=//ui-avatars.com/api/?name=${encodeURIComponent(artist.name)})`,
    };

    return (
        <div className="o-artist-chat">
            <div className="o-artist-chat-container">
                <div className="o-artist-chat-header">{`${i18next.t('app_page_artist_chat')} ${artist.name}`}</div>
                <div className="o-artist-chat-list">
                    {messages.map((message, index) => {
                        const stylePicture = {
                            backgroundImage: (message.pictureUrl && message.pictureUrl !== 'no_picture')
                                ? `url('${message.pictureUrl}?h=200&w=200')`
                                : `url(//unavatar.now.sh/${message.name}?fallback=//ui-avatars.com/api/?name=${encodeURIComponent(message.name)})`,
                        };

                        const lastArtistMessage = (message.artist && lastMessage.timestamp && lastMessage.timestamp === message.timestamp);

                        return (
                            <div key={`message-${index}`} className="o-artist-chat-message" style={lastArtistMessage ? { display: 'none' } : {}}>
                                <div className="o-artist-chat-avatar">
                                    <div className="o-artist-chat-picture" style={stylePicture}></div>
                                </div>
                                <div className="o-artist-chat-text">
                                    <div className='header'>
                                        <span className='name'>{message.name}</span>
                                    </div>
                                    <div className='body'>{message.body}</div>
                                    <div className='footer'>
                                        <span className='timestamp'>{moment(message.timestamp).fromNow()}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {Object.keys(lastMessage).length > 0 && (
                        <div className="o-artist-chat-message">
                            <div className="o-artist-chat-avatar">
                                <div className="o-artist-chat-picture" style={styleArtistPicture}></div>
                            </div>
                            <div className="o-artist-chat-text">
                                <div className='header'>
                                    <span className='name'>{artist.name}</span>
                                </div>
                                <div className='body'>{lastMessage.body}</div>
                                <div className='footer'>
                                    <span className='timestamp'>{moment(lastMessage.timestamp).fromNow()}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="o-artist-chat-footer">
                    <input
                        type="text"
                        placeholder={i18next.t('app_page_artist_chat_placeholder')}
                        value={message}
                        onKeyPress={e => {
                            if (e.key !== 'Enter') return;
                            sendMessage(message);
                        }}
                        onChange={e => setMessage(e.target.value)}
                    />
                    {/*
                    <button
                        className="o-artist-chat-button"
                        onClick={e => {
                            e.preventDefault();
                            sendMessage(message);
                        }}
                    >
                        Send Message
                    </button>
                    */}
                </div>
            </div>
        </div>
    );
};

export default ArtistChat;
