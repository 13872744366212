import Config from '../../config';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { httpService as http } from '../../core/services/http.service';
import { showError, showSuccess } from './message.action';
import moment from 'moment';

export const type = {
    STAKEHOLDER_FETCH_PENDING: 'STAKEHOLDER_FETCH_PENDING',
    STAKEHOLDER_FETCH_GET_SUCCESS: 'STAKEHOLDER_FETCH_GET_SUCCESS',
    STAKEHOLDER_FETCH_GET_ONE_SUCCESS: 'STAKEHOLDER_FETCH_GET_ONE_SUCCESS',
    STAKEHOLDER_FETCH_PATCH_REMOVE_SUCCESS: 'STAKEHOLDER_FETCH_PATCH_REMOVE_SUCCESS',
    STAKEHOLDER_FETCH_POST_SUCCESS: 'STAKEHOLDER_FETCH_POST_SUCCESS',
    STAKEHOLDER_RESET: 'STAKEHOLDER_RESET',
    STAKEHOLDER_SET: 'STAKEHOLDER_SET',
    STAKEHOLDER_SET_FILTER: 'STAKEHOLDER_SET_FILTER',
    STAKEHOLDER_SET_STAKEHOLDERS: 'STAKEHOLDER_SET_STAKEHOLDERS',
};

export const initialState = {
    artists: [],
    countAll: 0,
    filter: {
        limit: Config.paginator.DEFAULT_ROWS,
        offset: Config.paginator.DEFAULT_FIRST,
    },
    findArtists: [],
    loading: false,
    stakeholder: {
        id: null,
        artistId: null,
        userId: null,
        name: '',
        type: 'standard',
        share: 0,
        beginsAt: null,
        finishAt: null,
        artists: [],
    },
    stakeholders: [],
};

const fetchGetStakeholdersSuccess = ({ stakeholders, countAll }) => {
    return {
        type: type.STAKEHOLDER_FETCH_GET_SUCCESS,
        stakeholders,
        countAll,
        loading: false,
    };
};

export const fetchGetStakeholders = (options = {}, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        let query = '?all=true';
        if (Object.keys(options).length > 0) {
            query += options.find ? `&find=${options.find}` : '';
            query += options.limit ? `&limit=${options.limit}` : '';
            query += options.offset ? `&offset=${options.offset}` : '';
        }

        http.get(`/v1/bo/stakeholders${query}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetStakeholdersSuccess(response.data));
                    if (callback) {
                        callback(response.data.stakeholders);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchPatchRemoveStakeholderSuccess = () => {
    return {
        type: type.STAKEHOLDER_FETCH_PATCH_REMOVE_SUCCESS,
        loading: false,
    };
};

export const fetchPatchRemoveStakeholder = id => {
    return async dispatch => {
        dispatch(fetchPending(true));
        const data = {};

        http.patch(`/v1/bo/stakeholders/${id}/remove`, data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPatchRemoveStakeholderSuccess());
                    dispatch(showSuccess({ summary: 'app_stakeholder_removed' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchPostStakeholderSuccess = ({ stakeholder }) => {
    return {
        type: type.STAKEHOLDER_FETCH_POST_SUCCESS,
        stakeholder,
        loading: false,
    };
};

export const fetchPostStakeholder = stakeholder => {
    return async dispatch => {
        dispatch(fetchPending(true));

        const data = {
            ...stakeholder,
        };

        http.post('/v1/bo/stakeholders', data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostStakeholderSuccess(response.data));
                    dispatch(showSuccess({ summary: 'app_stakeholder_created' }));
                    history.push(`/stakeholders/modify/${response.data.stakeholder.id}`);
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchGetStakeholderSuccess = ({ stakeholder }) => {
    return {
        type: type.STAKEHOLDER_FETCH_GET_ONE_SUCCESS,
        stakeholder: {
            ...stakeholder,
            beginsAt: stakeholder.beginsAt ? moment(stakeholder.beginsAt).toDate() : null,
            finishAt: stakeholder.finishAt ? moment(stakeholder.finishAt).toDate() : null,
        },
        loading: false,
    };
};

export const fetchGetStakeholder = id => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/stakeholders/${id}`).then(
            response => {
                dispatch(fetchGetStakeholderSuccess(response.data));
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPutStakeholder = (stakeholder, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        const data = {
            ...stakeholder,
        };

        http.put(`/v1/bo/stakeholders/${stakeholder.id}`, data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostStakeholderSuccess(response.data));
                    if (callback) {
                        callback(response.data);
                    }
                    dispatch(showSuccess({ summary: 'app_stakeholder_updated' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPending = loading => {
    return { type: type.STAKEHOLDER_FETCH_PENDING, loading };
};

export const resetStakeholder = () => {
    return {
        type: type.STAKEHOLDER_RESET,
        stakeholder: initialState.stakeholder,
    };
};

export const setFilter = filter => {
    return { type: type.STAKEHOLDER_SET_FILTER, filter };
};

export const setStakeholder = stakeholder => {
    return { type: type.STAKEHOLDER_SET, stakeholder };
};

export const setStakeholders = stakeholders => {
    return { type: type.STAKEHOLDER_SET_STAKEHOLDERS, stakeholders };
};
