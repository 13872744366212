import { type } from '../actions/message.action';

const showMessages = (state = { messages: [] }, action) => {
    const messages = [];
    switch (action.type) {
        case type.MESSAGE_SHOW_MESSAGE:
            messages.push(action.message);
            return { messages: messages };
        case type.MESSAGE_RESET_MESSAGE:
            return { messages: messages };
        default:
            return state;
    }
};

export default showMessages;
