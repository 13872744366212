import { historyHelper as history } from '../../core/helpers/history.helper';
import { httpService as http } from '../../core/services/http.service';
import { fetchPostPicture, fetchPutPicture } from './app.action';
import { showError, showSuccess } from './message.action';

export const getTypes = () => {
    return dispatch => {
        return [
            { value: 'artist', label: 'app_banner_type_artist' },
            { value: 'post', label: 'app_banner_type_post' },
            { value: 'storeCoin', label: 'app_banner_type_store_coin' },
        ];
    };
};

export const type = {
    BANNER_FETCH_PENDING: 'BANNER_FETCH_PENDING',
    BANNER_FETCH_GET_SUCCESS: 'BANNER_FETCH_GET_SUCCESS',
    BANNER_FETCH_GET_CONTENT_SUCCESS: 'BANNER_FETCH_GET_CONTENT_SUCCESS',
    BANNER_FETCH_GET_ONE_SUCCESS: 'BANNER_FETCH_GET_ONE_SUCCESS',
    BANNER_FETCH_POST_SUCCESS: 'BANNER_FETCH_POST_SUCCESS',
    BANNER_FETCH_POST_REORDER_SUCCESS: 'BANNER_FETCH_POST_REORDER_SUCCESS',
    BANNER_FETCH_PUT_SUCCESS: 'BANNER_FETCH_PUT_SUCCESS',
    BANNER_RESET: 'BANNER_RESET',
    BANNER_SET: 'BANNER_SET',
    BANNER_SET_BANNERS: 'BANNER_SET_BANNERS',
    BANNER_SET_CONTENT: 'BANNER_SET_CONTENT',
    BANNER_SET_PICTURE: 'BANNER_SET_PICTURE',
};

export const initialState = {
    countAll: 0,
    banner: {
        title: '',
    },
    banners: [],
    content: {},
    filter: {
        limit: 30,
    },
    loading: false,
    picture: null,
    pictureFile: null,
};

const fetchGetBannerSuccess = ({ banner, picture }) => {
    return {
        type: type.BANNER_FETCH_GET_ONE_SUCCESS,
        banner,
        picture,
        loading: false,
    };
};

export const fetchGetBanner = (id, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/banners/${id}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetBannerSuccess(response.data));
                    if (callback) {
                        callback(response.data.banner);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchGetBannersSuccess = ({ banners, countAll }) => {
    return {
        type: type.BANNER_FETCH_GET_SUCCESS,
        banners,
        countAll,
        loading: false,
    };
};

export const fetchGetBanners = (options = {}, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        let query = '';
        if (Object.keys(options).length > 0) {
            query += options.find ? `&find=${options.find}` : '';
            query += options.limit ? `&limit=${options.limit}` : '';
            query += options.offset ? `&offset=${options.offset}` : '';
        }

        http.get(`/v1/bo/banners${query}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetBannersSuccess(response.data));
                    if (callback) {
                        callback(response.data.banners);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchGetContentSuccess = (contentType, content) => {
    let id = null;
    if (contentType === 'artist') id = content.artist.id;
    if (contentType === 'post') id = content.post.id;
    if (contentType === 'storeCoin') id = content.storeCoin.id;

    let title = 'no name';
    if (contentType === 'artist') title = content.artist.name;
    if (contentType === 'post') title = content.post.title;
    if (contentType === 'storeCoin') title = content.storeCoin.name;

    return {
        type: type.BANNER_FETCH_GET_CONTENT_SUCCESS,
        content: {
            type: contentType,
            id,
            title,
            picture: content.picture,
        },
        loading: false,
    };
};

export const fetchGetContent = (type, id, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        let path = '';
        switch (type) {
            case 'artist':
                path = 'artists';
                break;

            case 'post':
                path = 'posts';
                break;

            case 'storeCoin':
                path = 'storecoins';
                break;

            default:
                dispatch(fetchPending(false));
                dispatch(showError({ summary: 'app_banner_type_not_exist' }));
                return false;
        }

        http.get(`/v1/bo/${path}/${id}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetContentSuccess(type, response.data));
                    if (callback) {
                        callback(response.data.content);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPending = loading => {
    return { type: type.BANNER_FETCH_PENDING, loading };
};

export const fetchPatchRemoveBanner = id => {
    return async dispatch => {
        dispatch(fetchPending(true));
        const data = {};

        http.patch(`/v1/bo/banners/${id}/remove`, data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPending(false));
                    dispatch(fetchPostReorderSuccess(response.data));
                    dispatch(showSuccess({ summary: 'app_banner_removed' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchPostBannerSuccess = ({ banner }) => {
    return {
        type: type.BANNER_FETCH_POST_SUCCESS,
        banner,
        loading: false,
    };
};

export const fetchPostBanner = (banner, content, pictureFile) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        const { picture } = await fetchPostPicture(pictureFile);

        if (picture) {
            const data = {
                ...banner,
                picture: picture,
                entityId: content.id,
            };

            http.post('/v1/bo/banners', data).then(
                response => {
                    if (!response.ok) {
                        dispatch(fetchPending(false));
                        dispatch(showError(response.error));
                    } else {
                        dispatch(fetchPostBannerSuccess(response.data));
                        dispatch(showSuccess({ summary: 'app_banner_created' }));
                        history.push('/banners');
                    }
                },
                error => {
                    dispatch(fetchPending(false));
                    dispatch(showError(error.error));
                }
            );
        } else {
            dispatch(fetchPending(false));
            dispatch(showError({ error: { summary: 'app_picture_not_uploaded' } }));
        }
    };
};

const fetchPostReorderSuccess = ({ banners }) => {
    return {
        type: type.BANNER_FETCH_POST_REORDER_SUCCESS,
        banners,
        loading: false,
    };
};

export const fetchPostReorder = (banners) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.post('/v1/bo/banners/reorder', { banners }).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostReorderSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchPutBannerSuccess = ({ banner, picture }) => {
    return {
        type: type.BANNER_FETCH_PUT_SUCCESS,
        banner,
        picture,
        loading: false,
    };
};

export const fetchPutBanner = (banner, pictureFile) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        let pictureName = banner.picture;
        let picture = '';

        if (pictureFile) {
            let result = {};

            if (banner.picture && banner.picture !== '') {
                result = await fetchPutPicture(banner.picture, pictureFile);
            } else {
                result = await fetchPostPicture(pictureFile);
            }

            pictureName = result.picture;
            picture = result.pictureURL;
        }

        if (pictureName) {
            const data = {
                ...banner,
                picture: pictureName,
            };

            http.put(`/v1/bo/banners/${banner.id}`, data).then(
                response => {
                    if (!response.ok) {
                        dispatch(fetchPending(false));
                        dispatch(showError(response.error));
                    } else {
                        dispatch(fetchPutBannerSuccess(response.data, picture));
                        dispatch(showSuccess({ summary: 'app_banner_updated' }));
                    }
                },
                error => {
                    dispatch(fetchPending(false));
                    dispatch(showError(error.error));
                }
            );
        } else {
            dispatch(fetchPending(false));
            dispatch(showError({ error: { summary: 'app_picture_not_uploaded' } }));
        }
    };
};

export const resetBanner = () => {
    return {
        type: type.BANNER_RESET,
    };
};

export const setBanner = banner => {
    return { type: type.BANNER_SET, banner };
};

export const setBanners = banners => {
    return { type: type.BANNER_SET_BANNERS, banners };
};

export const setContent = content => {
    return { type: type.BANNER_SET_CONTENT, content };
};

export const setPicture = picture => {
    return { type: type.BANNER_SET_PICTURE, picture };
};
