import { initialState, type } from '../actions/app.action';

const app = (state = initialState, action) => {
    switch (action.type) {
        case type.APP_INIT_PAGE:
            return { ...state, lastPage: action.page, page: action.page };
        case type.APP_SET_LOCALE:
            return { ...state, locale: action.locale };
        case type.APP_SET_PAGE:
            return { ...state, lastPage: state.page, page: action.page };
        case type.APP_SET_SIDE_MENU:
            return { ...state, sideMenu: action.sideMenu };
        default:
            return state;
    }
};

export default app;
