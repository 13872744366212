import React from 'react';
import PageTitle from './page-title.component';
import './page.component.css';

const Page = ({ children, loading, onClick, title }) => {
    return (
        <div className='o-page'>
            {loading && (
                <div className='o-page-loader'>
                    <i className='pi pi-spin pi-spinner' style={{ fontSize: '10em' }}></i>
                </div>
            )}
            <PageTitle title={title} onClick={onClick} />
            <div className='o-page-children'>{children}</div>
        </div>
    );
};

export default Page;
