import React from 'react';
import './input-text.component.css';

const InputNumber = ({ disabled, label, name, onChange, onKeyPress, pattern, placeholder = '', value, min, max, step }) => {
    return (
        <div className='o-input-text'>
            {label && <label>{label}</label>}
            <input
                disabled={disabled === true ? 'disabled' : false}
                name={name}
                onChange={onChange}
                onKeyPress={onKeyPress}
                pattern={pattern}
                placeholder={placeholder}
                type='number'
                value={value}
                autoComplete='off'
                min={min}
                max={max}
                step={step}
            />
        </div>
    );
};

export default InputNumber;
