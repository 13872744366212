import { historyHelper as history } from '../../core/helpers/history.helper';
import { httpService as http } from '../../core/services/http.service';
import { showError, showSuccess } from './message.action';

export const type = {
    SECTION_FETCH_PENDING: 'SECTION_FETCH_PENDING',
    SECTION_FETCH_GET_SUCCESS: 'SECTION_FETCH_GET_SUCCESS',
    SECTION_FETCH_GET_ONE_SUCCESS: 'SECTION_FETCH_GET_ONE_SUCCESS',
    SECTION_FETCH_POST_SUCCESS: 'SECTION_FETCH_POST_SUCCESS',
    SECTION_FETCH_POST_REORDER_SUCCESS: 'SECTION_FETCH_POST_REORDER_SUCCESS',
    SECTION_FETCH_PUT_SUCCESS: 'SECTION_FETCH_PUT_SUCCESS',
    SECTION_RESET: 'SECTION_RESET',
    SECTION_SET: 'SECTION_SET',
    SECTION_SET_BANNERS: 'SECTION_SET_BANNERS',
};

export const initialState = {
    countAll: 0,
    section: {
        title: '',
    },
    sections: [],
    filter: {
        limit: 30,
    },
    loading: false,
};

const fetchGetSectionSuccess = ({ section }) => {
    return {
        type: type.SECTION_FETCH_GET_ONE_SUCCESS,
        section,
        loading: false,
    };
};

export const fetchGetSection = (id, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/sections/${id}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetSectionSuccess(response.data));
                    if (callback) {
                        callback(response.data.section);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchGetSectionsSuccess = ({ sections, countAll }) => {
    return {
        type: type.SECTION_FETCH_GET_SUCCESS,
        sections,
        countAll,
        loading: false,
    };
};

export const fetchGetSections = (options = {}, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        let query = '';
        if (Object.keys(options).length > 0) {
            query += options.find ? `&find=${options.find}` : '';
            query += options.limit ? `&limit=${options.limit}` : '';
            query += options.offset ? `&offset=${options.offset}` : '';
        }

        http.get(`/v1/bo/sections${query}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetSectionsSuccess(response.data));
                    if (callback) {
                        callback(response.data.sections);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const fetchPending = loading => {
    return { type: type.SECTION_FETCH_PENDING, loading };
};

export const fetchPatchRemoveSection = id => {
    return async dispatch => {
        dispatch(fetchPending(true));
        const data = {};

        http.patch(`/v1/bo/sections/${id}/remove`, data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPending(false));
                    dispatch(fetchPostReorderSuccess(response.data));
                    dispatch(showSuccess({ summary: 'app_section_removed' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchPostSectionSuccess = ({ section }) => {
    return {
        type: type.SECTION_FETCH_POST_SUCCESS,
        section,
        loading: false,
    };
};

export const fetchPostSection = (section) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.post('/v1/bo/sections', section).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostSectionSuccess(response.data));
                    dispatch(showSuccess({ summary: 'app_section_created' }));
                    history.push('/sections');
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchPostReorderSuccess = ({ sections }) => {
    return {
        type: type.SECTION_FETCH_POST_REORDER_SUCCESS,
        sections,
        loading: false,
    };
};

export const fetchPostReorder = (sections) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.post('/v1/bo/sections/reorder', { sections }).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostReorderSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

const fetchPutSectionSuccess = ({ section }) => {
    return {
        type: type.SECTION_FETCH_PUT_SUCCESS,
        section,
        loading: false,
    };
};

export const fetchPutSection = (section, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.put(`/v1/bo/sections/${section.id}`, section).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPutSectionSuccess(response.data));
                    if (callback) {
                        callback(response.data);
                    }
                    dispatch(showSuccess({ summary: 'app_section_updated' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};

export const resetSection = () => {
    return {
        type: type.SECTION_RESET,
    };
};

export const setSection = section => {
    return { type: type.SECTION_SET, section };
};

export const setSections = sections => {
    return { type: type.SECTION_SET_SECTIONS, sections };
};
