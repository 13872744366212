import React, { Component } from 'react';
import i18next from 'i18next';
import slugify from 'slugify';
import ArtistDetail from './components/artist-detail.component';
import Config from '../config';
import Dialog from '../shared/components/dialog.component';
import ImageUpload from '../shared/components/form/image-upload.component';
import InputSwitch from '../shared/components/form/input-switch.component';
import InputText from '../shared/components/form/input-text.component';
import InputCompletionText from '../shared/components/form/input-completion-text.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import Page from '../shared/components/page.component';
import Panel from '../shared/components/panel.component';
import SubmitButton from '../shared/components/form/submit-button.component';
import { connect } from 'react-redux';
import { historyHelper as history } from '../core/helpers/history.helper';
import { fetchPostArtist, resetArtist, setArtist, setPicture, setBanner } from '../store/actions/artist.action';
import { fetchGetTags } from '../store/actions/tag.action';
import './artist-create-page.component.css';

class ConnectedArtistCreatePage extends Component {
    constructor(props) {
        super(props);

        this.locales = Config.locales.LIST.map(item => {
            return {
                label: i18next.t(item.label),
                value: item.value,
            };
        });

        this.state = {
            dirty: false,
            localeValues: ['fr'],
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeAbout = this.handleChangeAbout.bind(this);
        this.handlePictureOnChange = this.handlePictureOnChange.bind(this);
        this.handlePictureOnClick = this.handlePictureOnClick.bind(this);
        this.handleBannerOnChange = this.handleBannerOnChange.bind(this);
        this.handleBannerOnClick = this.handleBannerOnClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSearchTags = this.handleSearchTags.bind(this);
        this.handleAddTag = this.handleAddTag.bind(this);
        this.handleRemoveTag = this.handleRemoveTag.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleDialogSubmit = this.handleDialogSubmit.bind(this);
        this.handleDialogCancel = this.handleDialogCancel.bind(this);
    }

    componentWillUnmount() {
        this.props.resetArtist();
    }

    handleChangeLocales(event) {
        let localeValues = this.state.localeValues;
        const value = event.value;
        const eventLength = value.length;

        if (localeValues.length > eventLength && eventLength > 0) {
            const removedLocale = localeValues.filter(l => !value.includes(l))[0];
            this.setState({
                isDialogVisible: true,
                dialogLocaleToRemove: removedLocale,
                dialogNextLocaleValues: value,
            });
        }

        if (eventLength > 0 && localeValues.length <= eventLength) {
            localeValues = value;
            this.setState({ localeValues });
        }
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        const artist = { ...this.props.store.artist.artist };
        artist[name] = value;
        this.props.setArtist(artist);
        this.setState({ dirty: true });
    }

    handleChangeAbout(about) {
        const artist = { ...this.props.store.artist.artist };
        artist.about = about;
        this.props.setArtist(artist);
        this.setState({ dirty: true });
    }

    handleDialogCancel() {
        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            dialogNextLocaleValues: [],
        });
    }

    handleDialogSubmit() {
        const about = { ...this.props.store.artist.artist.about };
        delete about[this.state.dialogLocaleToRemove];
        this.handleChangeAbout(about);

        this.setState({
            isDialogVisible: false,
            dialogLocaleToRemove: '',
            localeValues: this.state.dialogNextLocaleValues,
            dialogNextLocaleValues: [],
        });
    }

    handleSearchTags(query) {
        this.setState({ showTags: true });
        this.props.fetchGetTags(query);
    }

    handleAddTag(tag) {
        const artist = { ...this.props.store.artist.artist };
        const tags = artist.tags;
        if (tag.id === null) {
            const slugged = slugify(tag.name, {
                lower: true,
                strict: true,
                remove: /[*+~.()'"!:@]/g,
            });
            tags.push({ name: slugged });
        } else {
            tags.push(tag);
        }
        artist.tags = tags;
        this.props.setArtist(artist);
        this.setState({ showTags: false, dirty: true });
    }

    handleRemoveTag(index) {
        const artist = { ...this.props.store.artist.artist };
        const tags = artist.tags;
        tags.splice(index, 1);
        artist.tags = tags;
        this.props.setArtist(artist);
        this.setState({ dirty: true });
    }

    handlePictureOnChange(files) {
        this.setState({ dirty: true });
        const file = files[0];
        if (file) {
            this.props.setPicture(file);
        }
    }

    handlePictureOnClick() {
        this.setState({ dirty: true });
        this.props.setPicture(null);
    }

    handleBannerOnChange(files) {
        this.setState({ dirty: true });
        const file = files[0];
        if (file) {
            this.props.setBanner(file);
        }
    }

    handleBannerOnClick() {
        this.setState({ dirty: true });
        this.props.setBanner(null);
    }

    handleSubmit() {
        const { artist, bannerFile, pictureFile } = this.props.store.artist;
        if (artist.name.trim().replace(/  +/g, ' ').length > 0 &&
            pictureFile && pictureFile.name &&
            bannerFile && bannerFile.name) {
            this.props.fetchPostArtist(artist, pictureFile, bannerFile);
        }
        this.setState({ dirty: true });
    }

    render() {
        const { dirty } = this.state;

        const {
            store: {
                artist: { artist, loading, banner, bannerFile, picture, pictureFile },
                tag: { tags },
            },
        } = this.props;

        const dynamicDetails = this.state.localeValues.map(locale => {
            const id = `artist_about_${locale}`;
            return (
                <ArtistDetail
                    key={id}
                    lang={locale}
                    about={artist.about}
                    onChange={this.handleChangeAbout}
                />
            );
        });

        return (
            <Page
                title={i18next.t('app_page_artist_create')}
                onClick={() => this.handleAction(Config.action.BACK, null)}
                loading={loading}
            >
                <Panel title={i18next.t('app_general_information')} toggleable={false}>
                    <div className='o-artist-create'>
                        <div className='p-grid'>
                            <div className='p-col-8'>
                                <InputText
                                    name='name'
                                    label={i18next.t('app_artist_name')}
                                    value={artist.name}
                                    onChange={this.handleChange}
                                    onKeyDown={!dirty ? () => this.setState({ dirty: true }) : null}
                                />
                                {dirty && !artist.name && (
                                    <div className='error'>*{i18next.t('app_error_artist_mandatory_name')}</div>
                                )}
                                <InputCompletionText
                                    name='tags'
                                    label={i18next.t('app_artist_tags')}
                                    onSearch={this.handleSearchTags}
                                    onCancel={() => this.setState({ showTags: false })}
                                    onAdd={this.handleAddTag}
                                    onRemove={this.handleRemoveTag}
                                    options={this.state.showTags ? tags.map(tag => { return { id: tag, name: tag }; }) : []}
                                    values={artist.tags}
                                />
                                <InputSwitch
                                    name='promote'
                                    label={i18next.t('app_artist_promote')}
                                    checked={artist.promote}
                                    onChange={this.handleChange}
                                />
                                <InputMultiSelectInline
                                    label={i18next.t('app_artist_about')}
                                    name='locales'
                                    value={this.state.localeValues}
                                    options={this.locales}
                                    onChange={this.handleChangeLocales}
                                />
                                {dynamicDetails}
                            </div>
                            <div className='p-col-4'>
                                <ImageUpload
                                    name='artist-picture'
                                    label={i18next.t('app_artist_image_picture')}
                                    onClick={this.handlePictureOnClick}
                                    onChange={this.handlePictureOnChange}
                                    image={picture}
                                />
                                {dirty && !pictureFile && (
                                    <div className='error'>*{i18next.t('app_error_artist_mandatory_picture')}</div>
                                )}
                                <ImageUpload
                                    name='artist-banner'
                                    label={i18next.t('app_artist_image_banner')}
                                    onClick={this.handleBannerOnClick}
                                    onChange={this.handleBannerOnChange}
                                    image={banner}
                                />
                                {dirty && !bannerFile && (
                                    <div className='error'>*{i18next.t('app_error_artist_mandatory_banner')}</div>
                                )}
                            </div>
                        </div>
                        <div className='p-grid'>
                            <div className='p-col-8'></div>
                            <div className='p-col-4'>
                                <SubmitButton
                                    label={i18next.t('app_submit')}
                                    onClick={() => this.handleSubmit()}
                                    disabled={loading || !dirty}
                                />
                            </div>
                        </div>
                    </div>
                    <Dialog
                        visible={this.state.isDialogVisible}
                        onHide={() => this.handleDialogCancel()}
                        onCancel={() => this.handleDialogCancel()}
                        onSubmit={() => this.handleDialogSubmit()}
                        text={i18next.t('app_dialog_delete_locale')}
                    />
                </Panel>
            </Page>
        );
    }
}

const mapStateToProps = ({ app, artist, tag }) => {
    return { store: { app, artist, tag } };
};

const mapDispatchToProps = {
    fetchPostArtist,
    fetchGetTags,
    resetArtist,
    setArtist,
    setPicture,
    setBanner,
};

const ArtistCreatePage = connect(mapStateToProps, mapDispatchToProps)(ConnectedArtistCreatePage);

export default ArtistCreatePage;
