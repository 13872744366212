import Config from '../../config';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { httpService as http } from '../../core/services/http.service';
import { showError, showSuccess } from './message.action';

export const getTypes = () => {
    return dispatch => {
        return [
            { value: 'default', label: 'app_storecoins_type_default' },
            { value: 'welcome', label: 'app_storecoins_type_welcome' },
        ];
    };
};

export const type = {
    COIN_FETCH_PENDING: 'COIN_FETCH_PENDING',
    COIN_FETCH_GET_STORECOIN_SUCCESS: 'COIN_FETCH_GET_STORECOIN_SUCCESS',
    COIN_FETCH_GET_STORECOINS_SUCCESS: 'COIN_FETCH_GET_STORECOINS_SUCCESS',
    COIN_FETCH_POST_SUCCESS: 'COIN_FETCH_POST_SUCCESS',
    COIN_FETCH_PUT_SUCCESS: 'COIN_FETCH_PUT_SUCCESS',
    COIN_RESET_STORECOIN: 'COIN_RESET_STORECOIN',
    COIN_SET_FILTER: 'COIN_SET_FILTER',
    COIN_SET_STORECOIN: 'COIN_SET_STORECOIN',
};

export const initialState = {
    countAll: 0,
    filter: {
        limit: Config.paginator.DEFAULT_ROWS,
        offset: Config.paginator.DEFAULT_FIRST,
    },
    loading: false,
    storeCoin: {
        id: null,
        label: {},
        quantity: 0,
        bonusQuantity: 0,
        bestBuy: false,
        promote: false,
        onStore: false,
        type: 'default',
        oneshot: false,
    },
    storeCoins: [],
    table: {
        rows: 30,
    },
};

export const fetchPending = loading => {
    return { type: type.COIN_FETCH_PENDING, loading };
};

const fetchGetStoreCoinSuccess = ({ storeCoin }) => {
    return { type: type.COIN_FETCH_GET_STORECOIN_SUCCESS, storeCoin, loading: false };
};

export const fetchGetStoreCoin = id => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.get(`/v1/bo/storecoins/${id}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchGetStoreCoinSuccess(response.data));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

const fetchGetStoreCoinsSuccess = ({ storeCoins, countAll }) => {
    return {
        type: type.COIN_FETCH_GET_STORECOINS_SUCCESS,
        storeCoins,
        countAll,
        loading: false,
    };
};

export const fetchGetStoreCoins = (options = {}, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        let query = '?all=true';
        if (Object.keys(options).length > 0) {
            query += options.find ? `&find=${options.find}` : '';
            query += options.limit ? `&limit=${options.limit}` : '';
            query += options.offset ? `&offset=${options.offset}` : '';
        }

        http.get(`/v1/bo/storecoins${query}`).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                    if (callback) {
                        callback(null);
                    }
                } else {
                    dispatch(fetchGetStoreCoinsSuccess(response.data));
                    if (callback) {
                        callback(response.data.storeCoins);
                    }
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

const fetchPostStoreCoinSuccess = ({ storeCoin }) => {
    return { type: type.COIN_FETCH_POST_SUCCESS, storeCoin, loading: false };
};

export const fetchPostStoreCoin = data => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.post('/v1/bo/storecoins', data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPostStoreCoinSuccess(response.data));
                    dispatch(showSuccess({ summary: 'app_storecoins_created' }));
                    history.push('/storecoins');
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

const fetchPutStoreCoinSuccess = ({ storeCoin }) => {
    return { type: type.COIN_FETCH_PUT_SUCCESS, storeCoin, loading: false };
};

export const fetchPutStoreCoin = (data, callback = undefined) => {
    return async dispatch => {
        dispatch(fetchPending(true));

        http.put(`/v1/bo/storecoins/${data.id}`, data).then(
            response => {
                if (!response.ok) {
                    dispatch(fetchPending(false));
                    dispatch(showError(response.error));
                } else {
                    dispatch(fetchPutStoreCoinSuccess(response.data));
                    if (callback) {
                        callback(response.data);
                    }
                    dispatch(showSuccess({ summary: 'app_storecoins_updated' }));
                }
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            });
    };
};

export const resetStoreCoin = () => {
    return {
        type: type.COIN_RESET_STORECOIN,
        storeCoin: initialState.storeCoin,
    };
};

export const setFilter = filter => {
    return { type: type.COIN_SET_FILTER, filter };
};

export const setStoreCoin = storeCoin => {
    return { type: type.COIN_SET_STORECOIN, storeCoin };
};
