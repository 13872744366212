import React from 'react';
import i18next from 'i18next';
import Radium from 'radium';
import InputEmail from '../../shared/components/form/input-email.component';
import InputPassword from '../../core/components/form/input-password.component';
import Submit from '../../core/components/form/submit.component';

const LoginForm = ({ onChange, onSubmit, submitted, value: { password, username } }) => {
    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            onSubmit(event);
        }
    };
    return (
        <div>
            <InputEmail
                name='username'
                placeholder='app_username'
                value={username}
                onChange={onChange}
                onKeyPress={onKeyPress}
            />
            <InputPassword
                name='password'
                placeholder='app_password'
                value={password}
                onChange={onChange}
                onKeyPress={onKeyPress}
            />
            <Submit label={i18next.t('app_login', 'Se connecter')} disabled={submitted} onClick={onSubmit} />
        </div>
    );
};

export default Radium(LoginForm);
