import React, { Component } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import Account from './account.component';
import SideMenuBloc from './sidemenu-bloc.component';
import SideMenuItem from './sidemenu-item.component';
import { connect } from 'react-redux';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { setSideMenu, menuItem } from '../../store/actions/app.action';
import { showWarning } from '../../store/actions/message.action';
import './sidemenu.component.css';

require('moment/min/locales.min');

export class ConnectedSideMenu extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Change activated item
     *
     * @param {*} item
     */
    handleClick(action) {
        this.props.setSideMenu(action);
        switch (action) {
            case menuItem.ARTISTS:
                history.push('/');
                break;

            default:
                history.push(`/${action}`);
                break;
        }
    }

    render() {
        const { store: { app, user } } = this.props;

        moment.locale(user.account.locale);

        return (
            <div className='o-sidemenu'>
                <div className='o-sidemenu-logo'>
                    <img
                        alt='logo'
                        style={{ width: '100%', fill: 'white' }}
                        src='/templates/default/images/logo.svg'
                    />
                </div>
                <Account />
                <ul>
                    <SideMenuItem
                        name={menuItem.POSTS}
                        id='app_posts'
                        to='/'
                        icon='fas fa-microphone-alt'
                        active={app.sideMenu === menuItem.POSTS}
                        onClick={this.handleClick}
                    />
                    <SideMenuItem
                        name={menuItem.ARTISTS}
                        id='app_artists'
                        to='/'
                        icon='fas fa-microphone-alt'
                        active={app.sideMenu === menuItem.ARTISTS}
                        onClick={this.handleClick}
                    />
                    <SideMenuBloc />
                    <SideMenuItem
                        name={menuItem.BANNERS}
                        id='app_banners'
                        to='/'
                        icon='fas fa-thumbs-up'
                        active={app.sideMenu === menuItem.BANNERS}
                        onClick={this.handleClick}
                    />
                    <SideMenuItem
                        name={menuItem.SECTIONS}
                        id='app_sections'
                        to='/'
                        icon='fas fa-bars'
                        active={app.sideMenu === menuItem.SECTIONS}
                        onClick={this.handleClick}
                    />
                    <SideMenuItem
                        name={menuItem.SHOP}
                        id='app_shop'
                        to='/'
                        icon='fas fa-store'
                        active={app.sideMenu === menuItem.SHOP}
                        onClick={this.handleClick}
                    />

                    <SideMenuItem
                        name={menuItem.STORECOINS}
                        id='app_storecoins'
                        to='/'
                        icon='fas fa-coins'
                        active={app.sideMenu === menuItem.STORECOINS}
                        onClick={this.handleClick}
                    />
                    <SideMenuItem
                        name={menuItem.STAKEHOLDERS}
                        id='app_stakeholders'
                        to='/'
                        icon='fas fa-handshake'
                        active={app.sideMenu === menuItem.STAKEHOLDERS}
                        onClick={this.handleClick}
                    />
                    <SideMenuItem
                        name={menuItem.USERS}
                        id='app_users'
                        to='/'
                        icon='fas fa-user-friends'
                        active={app.sideMenu === menuItem.USERS}
                        onClick={this.handleClick}
                    />
                    <SideMenuItem
                        name={menuItem.ADMIN}
                        id='app_administration'
                        to='/'
                        icon='fas fa-cog'
                        active={app.sideMenu === menuItem.ADMIN}
                        onClick={this.handleClick}
                    />
                    <SideMenuBloc />
                </ul>
                <div className='o-sidemenu-lastlogin'>
                    <div>
                        {i18next.t('app_last_connection')}&nbsp;
                        {user.account.lastLogin
                            ? user.account.lastLogin.result
                                ? i18next.t('app_last_connection_valid')
                                : i18next.t('app_last_connection_invalid').toLowerCase()
                            : ''}
                    </div>
                    <div>
                        {user.account.lastLogin
                            ? moment(user.account.lastLogin.date).format('LLL')
                            : ''}
                    </div>
                    <div>IP {user.account.lastLogin ? user.account.lastLogin.ip : ''}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ app, user }) => {
    return { store: { app, user } };
};

const mapDispatchToProps = {
    setSideMenu,
    showWarning,
};

const SideMenu = connect(mapStateToProps, mapDispatchToProps)(ConnectedSideMenu);

export default SideMenu;
