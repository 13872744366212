import { httpService as http } from '../../core/services/http.service';
import { showError } from './message.action';
import moment from 'moment';

export const type = {
    STATISTIC_FETCH_PENDING: 'STATISTIC_FETCH_PENDING',
    STATISTIC_FETCH_GET_USERS_CSV_SUCCESS: 'STATISTIC_FETCH_GET_USERS_CSV_SUCCESS',
};

export const initialState = {
    loading: false,
};

export const fetchPending = loading => {
    return { type: type.STATISTIC_FETCH_PENDING, loading };
};

export const fetchGetUsersCSVSuccess = () => {
    return {
        type: type.STATISTIC_FETCH_GET_USERS_CSV_SUCCESS,
        loading: false,
    };
};

export const fetchGetUsersCSV = (options = {}) => {
    return dispatch => {
        dispatch(fetchPending(true));

        let query = '';
        if (Object.keys(options).length > 0) {
            query += options.locale ? `&locale=${options.locale}` : '';
            query += options.find ? `&find=${options.find}` : '';
            query +=
                options.status && options.status !== 'all' && options.status !== 'deleted'
                    ? `&status=${options.status}`
                    : '';
            query += options.status && options.status === 'deleted' ? '&deleted=true' : '';
            query += options.connection ? `&connection=${options.connection.getTime()}` : '';
            query = query.replace('&', '?');
        }

        http.getCSV(`/v1/bo/users/statistics${query}`).then(
            response => {
                const downloadLink = document.createElement('a');
                downloadLink.href = `data:text/csv;charset=utf-8,${response}`;
                downloadLink.download = `users_${moment().format('YYYYMMDDHHmmss')}.csv`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                dispatch(fetchGetUsersCSVSuccess());
            },
            error => {
                dispatch(fetchPending(false));
                dispatch(showError(error.error));
            }
        );
    };
};
