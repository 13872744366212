import React, { Component } from 'react';
import SideMenu from './sidemenu.component';
import './page-container.component.css';

class PageContainer extends Component {
    render() {
        return (
            <div className='o-page-container p-d-flex p-grid p-nogutter p-flex-nowrap'>
                <div className='o-page-container-side p-col-fixed p-d-flex p-flex-column p-jc-start' style={{ width: '18rem' }}>
                    <SideMenu />
                </div>
                <div className='o-page-container-body p-col-fixed' style={{ width: 'calc(100% - 18rem)' }}>{this.props.children}</div>
            </div>
        );
    }
}

export default PageContainer;
