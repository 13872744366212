import React from 'react';
import i18next from 'i18next';
import './artist-new-box.component.css';

const ArtistNewBox = ({ onClick }) => {
    return (
        <div className='o-artist-box o-artist-new-box' onClick={onClick}>
            <div className='o-artist-new-box-picture'>
                <i className='fas fa-plus' />
            </div>
            <div className='o-artist-new-box-name'>
                {i18next.t('app_add_artist')}
            </div>
        </div>
    );
};

export default ArtistNewBox;
