import { initialState, type } from '../actions/post.action';

const post = (state = initialState, action) => {
    switch (action.type) {
        case type.POST_FETCH_PENDING:
            return { ...state, loading: action.loading };

        case type.POST_FETCH_PENDING_ON_AIR:
            return { ...state, loadingOnAir: action.loadingOnAir };

        case type.POST_FETCH_GET_SUCCESS:
            return {
                ...state,
                posts: action.posts,
                countAll: action.countAll,
                loading: action.loading,
            };

        case type.POST_FETCH_GET_LIVE_LINK_SUCCESS:
            return { ...state, link: action.link };

        case type.POST_FETCH_GET_LIVE_SETUP_SUCCESS:
            return { ...state, loading: action.loading };

        case type.POST_FETCH_GET_POST_SUCCESS:
            return {
                ...state,
                livePicture: action.livePicture,
                post: action.post,
                picture: action.picture,
                loading: action.loading,
            };

        case type.POST_FETCH_GET_SECTIONS_SUCCESS:
            return {
                ...state,
                sectionOptions: action.sectionOptions,
                loading: action.loading,
            };

        case type.POST_FETCH_GET_STAKEHOLDER_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                stakeholder: action.stakeholder,
            };

        case type.POST_FETCH_GET_STAKEHOLDERS_SUCCESS:
        case type.POST_FETCH_POST_STAKEHOLDERS_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                participationRate: action.participationRate,
                postStakeholders: [],
                stakeholders: action.stakeholders,
            };

        case type.POST_FETCH_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingOnAir: false,
                post: action.post,
            };

        case type.POST_FETCH_PUT_SUCCESS:
            return {
                ...state,
                loading: false,
                post: action.post,
                picture: action.picture,
                livePicture: action.livePicture,
                pictureFile: null,
                livePictureFile: null,
                loadingOnAir: false,
            };

        case type.POST_SET:
            return {
                ...state,
                post: action.post,
            };

        case type.POST_RESET:
            return {
                ...state,
                post: action.post,
                picture: null,
                pictureFile: null,
                link: '',
            };

        case type.POST_RESET_STAKEHOLDER:
            return {
                ...state,
                stakeholder: action.stakeholder,
            };

        case type.POST_SET_FILE_READED:
            return { ...state, fileReaded: action.fileReaded };

        case type.POST_SET_FILTER:
            return { ...state, filter: action.filter };

        case type.POST_SET_POSTS:
            return {
                ...state,
                posts: action.posts,
            };

        case type.POST_SET_LIVE_PICTURE:
            return {
                ...state,
                livePicture: action.livePicture ? URL.createObjectURL(action.livePicture) : null,
                livePictureFile: action.livePicture,
            };

        case type.POST_SET_PARTICIPATION_RATE:
            return {
                ...state,
                participationRate: action.participationRate,
            };

        case type.POST_SET_PICTURE:
            return {
                ...state,
                picture: action.picture ? URL.createObjectURL(action.picture) : null,
                pictureFile: action.picture,
            };

        case type.POST_SET_STAKEHOLDER:
            return {
                ...state,
                stakeholder: action.stakeholder,
            };

        case type.POST_SET_POST_STAKEHOLDERS:
            return {
                ...state,
                postStakeholders: action.postStakeholders,
            };

        case type.POST_SET_STAKEHOLDERS_FILTER:
            return { ...state, stakeholdersFilter: action.stakeholdersFilter };

        default:
            return state;
    }
};

export default post;
