import React from 'react';
import i18next from 'i18next';
import ActionIcon from '../../shared/components/action-icon.component';
import { historyHelper as history } from '../../core/helpers/history.helper';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import './artist-table.component.css';

const ArtistTable = ({ artists, onRemove, filter: { deleted } }) => {
    const tagsBodyTemplate = (rowData) => {
        let tags = '';
        if (rowData.tags) {
            for (let i = 0; i < rowData.tags.length; i++) {
                if (i !== 0) {
                    tags += ', ';
                };

                tags += rowData.tags[i];
            }
        }

        return tags;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                {deleted !== 'true' && (
                    <div className="p-d-flex p-jc-end p-jc-xl-between">
                        <ActionIcon
                            icon="fas fa-edit"
                            label={i18next.t('app_tip_edit')}
                            onClick={() => history.push(`/artists/modify/${rowData.id}`)}>
                        </ActionIcon>
                        <ActionIcon
                            icon="fas fa-archive"
                            label={i18next.t('app_tip_archive')}
                            onClick={() => onRemove(rowData)}
                            style={{ marginLeft: '.5rem' }}>
                        </ActionIcon>
                    </div>
                )}
                {deleted === 'true' && (
                    <div className="p-d-flex p-jc-end p-jc-xl-center">
                        <ActionIcon
                            icon="fas fa-trash-restore-alt"
                            label={i18next.t('app_tip_restore')}
                            onClick={() => onRemove(rowData)}>
                        </ActionIcon>
                    </div>
                )}
            </>
        );
    };

    return (
        <div className='o-table-responsive'>
            <DataTable value={artists} className='p-datatable-responsive'>
                <Column
                    field="name"
                    header={i18next.t('app_artist_name')}>
                </Column>
                <Column
                    body={tagsBodyTemplate}
                    field="tags"
                    header={i18next.t('app_artist_tags')}>
                </Column>
                <Column style={{ textAlign: 'center', width: '4.4rem' }} body={actionBodyTemplate}></Column>
            </DataTable>
        </div>
    );
};

export default ArtistTable;
