import Config from '../../config';

export const type = {
    MESSAGE_SHOW_MESSAGE: 'MESSAGE_SHOW_MESSAGE',
    MESSAGE_RESET_MESSAGE: 'MESSAGE_RESET_MESSAGE',
};

export function resetMessage() {
    return { type: type.MESSAGE_RESET_MESSAGE };
}

export function showMessage(message) {
    const lifeMessage = { ...message, life: Config.showMessage.LIFE };
    return { type: type.MESSAGE_SHOW_MESSAGE, message: lifeMessage };
}

export function showError(message, detail = '') {
    let details = [];
    if (message && 'detail' in message) {
        details = message.detail;
    }
    if (detail) {
        details = [...details, detail];
    }
    const errorMessage = {
        severity: 'error',
        summary:
            message && 'summary' in message ? message.summary : 'app_error_internal_server_error',
        detail: details,
    };

    return showMessage(errorMessage);
}

export function showSuccess(message) {
    const successMessage = {
        severity: 'success',
        summary: message.summary,
        detail: message.detail,
    };

    return showMessage(successMessage);
}

export function showWarning(message) {
    const successMessage = {
        severity: 'warn',
        summary: message.summary,
        detail: message.detail,
    };

    return showMessage(successMessage);
}
