import React from 'react';
import moment from 'moment';

const displayDate = (date) => {
    if (!date) {
        return '';
    }
    const datetime = moment(date).format('DD/MM/YYYY HH:mm');
    const display = moment(date).format('DD/MM/YYYY HH:mm');

    return <time dateTime={datetime}>{display}</time>;
};

export default displayDate;
